import { BattleCommentsNames, BattleCommentsTypes } from 'interfaces/BattleComment';
import { SelectOption } from 'interfaces/SelectOption';

export const judgingSystemList: SelectOption[] = [
  { label: BattleCommentsNames[BattleCommentsTypes.TRIVIUM], value: BattleCommentsTypes.TRIVIUM },
  { label: BattleCommentsNames[BattleCommentsTypes.THREEFOLD], value: BattleCommentsTypes.THREEFOLD },
  { label: BattleCommentsNames[BattleCommentsTypes.CLASSIC], value: BattleCommentsTypes.CLASSIC },
  { label: BattleCommentsNames[BattleCommentsTypes.TYPE_A], value: BattleCommentsTypes.TYPE_A },
  { label: BattleCommentsNames[BattleCommentsTypes.TYPE_B], value: BattleCommentsTypes.TYPE_B },
];
