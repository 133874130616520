import { useMenuCollapsedProvider } from 'providers/MenuCollapsed';

export const useCardsViewConfig = () => {
  const { sidebarCollapsed } = useMenuCollapsedProvider();

  return {
    gutter: 40,
    column: 4,
    xs: 1,
    sm: sidebarCollapsed ? 2 : 1,
    md: sidebarCollapsed ? 3 : 2,
    lg: sidebarCollapsed ? 4 : 3,
    xl: 4,
    xxl: 6,
  };
};
