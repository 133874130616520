import { FC } from 'react';
import classNames from 'classnames';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';

type EllipsisDropdownProps = {
  className?: string;
  menu?: MenuProps;
};

export const EllipsisDropdown: FC<EllipsisDropdownProps> = ({ className = '', menu = {} }) => {
  return (
    <Dropdown className={classNames('cursor-pointer', className)} menu={menu}>
      <MoreOutlined className='p-8 text-white' />
    </Dropdown>
  );
};
