import { BaseQueryFn, FetchArgs, FetchBaseQueryError, retry } from '@reduxjs/toolkit/dist/query/react';
import { Urls } from 'config/Urls';
import { logoutUser } from 'features/auth/authSlice';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from 'utils';
import { baseQuery } from './api';

/**
 * It's Redux Toolkit requestInterceptor!
 * For React-Query requestInterceptor, use useRequestInterceptor hook!
 *
 * @param args - The arguments passed to the query function.
 * @param api - The API object provided by the query function.
 * @param extraOptions - Extra options provided by the query function.
 * @returns The result of the query function.
 */
const requestInterceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  const errorStatus = result?.error?.status as number;

  if (result.error && [400, 401, 403].includes(errorStatus)) {
    const errors = result?.error?.data as any;
    const errorsList = errors.error.details.errors || [{ message: errors.error?.message, status: errors.error?.status }];

    errorsList.map(({ message, status }: { message: string; status: number }) => {
      toast.error(`${status ? `${status}: ` : ''}${capitalizeFirstLetter(message)}`);
    });

    if (errorStatus === 401) {
      api.dispatch(logoutUser());
    }
  }

  if ([404].includes(errorStatus)) {
    window.location.replace(Urls.PAGE_NOT_FOUND);
  }

  return result;
};

export const baseQueryWithRetry = retry(requestInterceptor, { maxRetries: 0 });
