import { SelectOption } from 'interfaces/SelectOption';

export type numberOfCompetitorsInBattleLive = 2 | 4 | 8 | 16 | 32;

export const numberOfCompetitorsList: (Omit<SelectOption, 'value'> | { value: numberOfCompetitorsInBattleLive })[] = [
  { label: '32', value: 32 },
  { label: '16', value: 16 },
  { label: '8', value: 8 },
  { label: '4', value: 4 },
  { label: '2', value: 2 },
];
