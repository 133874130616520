import { useCategorySettings } from 'hooks/useCategorySettings';
import { BattleLiveVerdict } from 'interfaces/BattleLiveVerdict';
import { getBattleWinner, getWinner } from 'utils';
import { useBattleLive } from './useBattleLive';
import { getRoundsResult } from 'utils/getRoundsResult';
import { getBattleReveal } from 'utils/getBattleReveal';

export interface BattleLiveRoundResult {
  winner: string;
  blue: number;
  red: number;
  reveal: boolean;
  isRevealActive: boolean;
}

export const useBattleLiveWinner = () => {
  const { rounds, winner } = useBattleLive();
  const { settings } = useCategorySettings();

  const numberOfJudges = (settings?.judges?.data ?? []).length;

  const roundsResult = getRoundsResult(rounds, numberOfJudges);

  const allRoundsClosed = Object.values(roundsResult).every(({ isRevealActive }) => isRevealActive);
  const areAnyClosedRounds = !!Object.values(roundsResult).filter(({ isRevealActive, reveal }) => isRevealActive && !reveal)?.length;
  const areAnyRevealedRounds = Object.values(roundsResult).some(({ reveal }) => reveal);
  const allRoundsRevealed = Object.values(roundsResult).every(({ reveal }) => reveal);

  const battle = Object.values(roundsResult).reduce(
    (prev, curr) => ({
      ...prev,
      ...(curr?.winner && { [curr?.winner]: prev[curr?.winner] + 1 }),
    }),
    { red: 0, blue: 0, tie: 0 },
  );
  const battleWinner = allRoundsClosed ? getBattleWinner(battle) : null;

  const battleReveal = getBattleReveal(roundsResult);

  const getBattleLiveRoundWinner = (verdicts: BattleLiveVerdict[] = []) => {
    const isRoundWinner = numberOfJudges === verdicts.length;
    return isRoundWinner ? getWinner(verdicts) : null;
  };

  return {
    roundsResult,
    battleResult: { ...battle, winner: battleWinner, allRoundsClosed, areAnyClosedRounds },
    battleRevealResult: { ...battleReveal, winner, areAnyRevealedRounds, allRoundsRevealed },
    getBattleLiveRoundWinner,
  };
};
