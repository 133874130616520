import { Radio, Space } from 'antd';
import classNames from 'classnames';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';
import { Field, FieldProps } from 'formik';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { SelectOption } from 'interfaces/SelectOption';
import { ChangeEvent, FC, ReactNode } from 'react';

interface RadioButtonOptions extends Omit<SelectOption, 'label'> {
  label: string | ReactNode;
}

type RadioGroupFieldProps = FormFieldDefaultProps & {
  direction?: 'horizontal' | 'vertical';
  options: RadioButtonOptions[];
};

export const RadioGroupField: FC<RadioGroupFieldProps> = ({
  id,
  className = '',
  name,
  label = '',
  disabled = false,
  options = [],
  direction = 'horizontal',
  onChange,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <Radio.Group
            id={id}
            name={name}
            value={field.value}
            disabled={disabled}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
            }}
          >
            <Space direction={direction}>
              {options.map(({ value, label, disabled }) => (
                <Radio key={value} value={value} disabled={disabled}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>

          {/* <Space direction={direction}>
            {options.map(({ value, label }) => (
              <Radio
                key={value}
                id={id}
                {...field}
                value={value}
                checked={field.value === value}
                disabled={disabled}
                onChange={(e) => {
                  field.onChange(e);
                  onChange?.(e as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
                }}
              >
                {label}
              </Radio>
            ))}
          </Space> */}
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
        </div>
      )}
    </Field>
  );
};
