import { useEditEventMutation, useGetEventQuery } from 'app/services/events';
import { STATUSES_TYPES } from 'config/statuses';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ERROR_MESSAGE = 'Problem occurred during process of updating status event';

/**
 * Custom hook that provides event status and a function to update the event status.
 * @param specifiedEventId - Optional specified event ID.
 * @returns An object containing the current event status and a function to update the event status.
 */
export function useEventStatus(specifiedEventId?: string) {
  const { eventId: eventIdFromParams } = useParams();
  const eventId = specifiedEventId || eventIdFromParams;
  const { data } = useGetEventQuery(eventId, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const [editEvent] = useEditEventMutation();

  const { attributes } = data || {};
  const { status, name: eventName } = attributes || {};

  const setStatus = async (status: STATUSES_TYPES, certainEvent?: number) => {
    if (certainEvent || eventId != null) {
      const id = certainEvent || Number(eventId);

      if (isNaN(id)) throw new Error('Id of event is not a number!');

      await toast.promise(editEvent({ id, body: { status } }), {
        error: ERROR_MESSAGE,
        success: `Status of ${eventName} was changed to ${status.toLowerCase()}.`,
      });
    } else Promise.reject(`Id of event is ${eventId}.`);
  };

  return { status, setStatus };
}
