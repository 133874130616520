import styled from 'styled-components';
import { rotate } from 'assets/styles/animations';

export const SpinnerButtonStyles = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;

  .spinner {
    &:before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: -8px;
      background: ${({ theme }) => theme.yellow100};
      color: ${({ theme }) => theme.black300};
      border-radius: 5px;
    }

    &:after {
      content: '';
      border-radius: 50%;
      animation: ${rotate} 1s linear infinite;
      border: 4px solid ${({ theme }) => theme.black300 + '80'};
      border-top: 4px solid transparent;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      z-index: 5;
    }

    &.spinner-left {
      &:before {
        left: -42px;
      }

      &:after {
        left: -30px;
      }
    }

    &.spinner-right {
      &:before {
        right: -42px;
      }

      &:after {
        right: -30px;
      }
    }
  }
`;
