import styled from 'styled-components';

export const VerdictScoresStyles = styled.div`
  width: 23%; // 25% - double_margin_1%
  min-width: 240px;

  &:not(:first-child),
  &:not(:last-child) {
    margin: 1%;
  }

  .verdict-details {
    border-radius: 5px;

    .scale {
      .win {
        width: 35%;
        height: 10px;
        border-radius: 5px;
      }

      .score {
        > div {
          width: 50%;
          white-space: nowrap;
        }
      }

      .-red {
        background-color: ${({ theme }) => theme.red100};
      }

      .-blue {
        background-color: ${({ theme }) => theme.blue100};
      }
    }
  }
`;
