import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewNames, ViewVariantsTabs } from 'hooks';

export interface NavigationState {
  battles: ViewVariantsTabs;
  competitors: ViewVariantsTabs;
  competitorBattles: ViewVariantsTabs;
  collections: ViewVariantsTabs;
  singleCollection: ViewVariantsTabs;
}

const initialState: NavigationState = {
  battles: 'CARDS',
  competitors: 'LIST',
  competitorBattles: 'CARDS',
  collections: 'LIST',
  singleCollection: 'CARDS',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActiveViewVariant: (state, action: PayloadAction<{ viewName: ViewNames; viewVariant: ViewVariantsTabs }>) => {
      state[action.payload.viewName] = action.payload.viewVariant;
    },
  },
});

export const { setActiveViewVariant } = navigationSlice.actions;

export default navigationSlice.reducer;
