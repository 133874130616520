import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBattleQuery } from 'app/services/battles';
import { competitorSides } from 'config/competitorSides';
import { Urls } from 'config/Urls';
import { useConfirmModal } from 'hooks';
import { CompetitorSide } from 'interfaces/CompetitorSide';
import { useResultProvider } from 'providers/ResultProvider';
import { useRoundVerdictProvider } from 'providers/RoundVerdict';
import { AvatarWithDescription } from 'components';

type ScoreCompetitor = { side: CompetitorSide };

const ScoreCompetitor: FC<ScoreCompetitor> = ({ side }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { resetResult } = useResultProvider();
  const { openConfirmModal } = useConfirmModal();
  const { data: battleData } = useGetBattleQuery(id as string);
  const { roundVerdict, resetActiveButton, setRoundVerdict } = useRoundVerdictProvider();

  const userData = side === competitorSides.RED ? battleData?.RedSide?.data[0]?.attributes : battleData?.BlueSide?.data[0]?.attributes;
  const userId = side === competitorSides.RED ? battleData?.RedSide?.data[0]?.id : battleData?.BlueSide?.data[0]?.id;
  const description = userData ? `${userData?.Type} ${userData?.Name}` : undefined;

  const navigateToCompetitorsProfile = () => {
    const verdictExists = Object.values(roundVerdict).filter((rv) => rv !== null).length;

    if (verdictExists) {
      openConfirmModal({
        content: (
          <div>
            You haven&apos;t submitted your verdict yet. Switching to the <strong>{description}</strong> profile will clear your current assessment. Are you
            sure to proceed?
          </div>
        ),
        onOk: () => {
          setRoundVerdict({});
          resetActiveButton();
          resetResult();
          navigate(`${Urls.COMPETITORS_BATTLES}/${userId}`);
        },
      });
    } else {
      navigate(`${Urls.COMPETITORS_BATTLES}/${userId}`);
    }
  };

  return <AvatarWithDescription className='font-bold' description={description} side={side} onClick={navigateToCompetitorsProfile} />;
};

export default ScoreCompetitor;
