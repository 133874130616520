import { FC, ReactNode } from 'react';
import { Card } from 'antd';
import { FlexContainer } from 'components';
import { FormWrapperFooterContainer } from './FormWrapper.styles';

type FormWrapperProps = {
  content: ReactNode;
  footer?: ReactNode;
};

export const FormWrapper: FC<FormWrapperProps> = ({ content, footer }) => {
  return (
    <FlexContainer className='h-100' justifyContent='center' alignItems='center'>
      <Card style={{ maxWidth: 450 }} className='w-100 shadow' bodyStyle={{ padding: '32px 32px 24px' }}>
        {content}
        {footer && <FormWrapperFooterContainer className='pt-8 mt-16 text-center'>{footer}</FormWrapperFooterContainer>}
      </Card>
    </FlexContainer>
  );
};
