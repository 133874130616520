import styled from 'styled-components';
import { Button, IconButton } from 'components';
import { CompetitorSide } from 'interfaces/CompetitorSide';

export interface TriviumPanelProps {
  leftColor?: CompetitorSide;
}

export const TriviumPanelStyles = styled.div<TriviumPanelProps>`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: calc(19vh) calc(10vh + 70px) calc(12vh + 40px);
  grid-template-areas:
    'roundCounter roundCounter . slider1 slider1 slider1 . . .'
    'leftSideBtn leftSideBtn crashGroupBtn crashGroupBtn crashGroupBtn crashGroupBtn crashGroupBtn rightSideBtn rightSideBtn'
    'slider2 slider2 slider2 misbehaviorGroupBtn misbehaviorGroupBtn misbehaviorGroupBtn slider3 slider3 slider3';

  ${Button} {
    font-size: 1.8rem;
  }

  ${IconButton} {
    width: 4vh;
    height: 4vh;

    svg {
      width: 3vh;
      height: 3vh;
    }
  }

  .nested-grid-container {
    justify-self: stretch;
    margin: 0 10px;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
  }

  .slider-wrapper {
    width: 100%;
    max-width: 600px;
    user-select: none;

    &.slider1 {
      grid-area: slider1;
    }

    &.slider2 {
      grid-area: slider2;
    }

    &.slider3 {
      grid-area: slider3;
    }
  }

  .crashGroupBtn {
    grid-area: crashGroupBtn;
    grid-template-areas:
      'execution crash crash repeat'
      'form crash crash bite';
    grid-template-rows: calc(5vh + 25px) calc(5vh + 20px);

    .execution {
      grid-area: execution;
    }

    .repeat {
      grid-area: repeat;
    }

    .crash {
      grid-area: crash;
      align-self: center;
    }

    .form {
      grid-area: form;
      align-self: start;
      justify-self: start;
    }

    .bite {
      grid-area: bite;
      justify-self: end;
      align-self: start;
    }
  }

  .misbehaviorGroupBtn {
    grid-area: misbehaviorGroupBtn;
    grid-template-areas:
      '. misbehavior misbehavior .'
      'confidence confidence spontaneity spontaneity';

    .misbehavior {
      grid-area: misbehavior;
    }

    .confidence {
      grid-area: confidence;
    }

    .spontaneity {
      grid-area: spontaneity;
    }
  }

  .rightSideBtn {
    grid-area: rightSideBtn;
    justify-self: end;
  }

  .leftSideBtn {
    grid-area: leftSideBtn;
    justify-self: start;
  }
`;
