import { BattleLive, BattleLiveResponse } from 'interfaces/BattleLive';
import { BattleLiveRounds } from 'interfaces/BattleLiveRound';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { TimeAttributes } from 'interfaces/TimeAttributes';

export const transformBattleLiveResponse = (response: StrapiWrapper<BattleLiveResponse>): StrapiWrapper<TimeAttributes & BattleLive> => {
  const rounds = response.attributes?.rounds?.data || [];

  const transformedRounds = rounds.reduce(
    (acc, round) => ({
      ...acc,
      [round?.attributes?.number]: {
        id: round?.id,
        ...round?.attributes,
        ...(round?.attributes?.verdicts && {
          verdicts: round?.attributes?.verdicts?.data.reduce(
            (acc, verdict) => ({
              ...acc,
              [verdict?.attributes?.judge?.data?.id]: {
                ...verdict?.attributes?.verdict,
                id: verdict?.id,
              },
            }),
            {},
          ),
        }),
      },
    }),
    {},
  ) as BattleLiveRounds;

  return {
    id: response.id,
    attributes: {
      ...response.attributes,
      id: `${response.id}`,
      ...(rounds.length && { rounds: transformedRounds }),
    },
  } as StrapiWrapper<TimeAttributes & BattleLive>;
};
