import { useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { BattleComment } from 'interfaces/BattleComment';
import { TimeAttributes } from 'interfaces/TimeAttributes';

export const useFilteredBattleCommentsList = (battleCommentList: BattleComment[], initialCheckedList: CheckboxValueType[] = []) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(initialCheckedList);
  const [filteredBattleComments, setFilteredBattleComments] = useState<(BattleComment & TimeAttributes)[]>([]);

  useEffect(() => {
    const getGruppedBattleComments = () => {
      const filteredVerdicts: BattleComment[] = checkedList.length > 0 ? battleCommentList.filter(({ type }) => checkedList.includes(type)) : battleCommentList;

      if (JSON.stringify(filteredVerdicts) !== JSON.stringify(filteredBattleComments)) {
        setFilteredBattleComments(filteredVerdicts);
      }
    };

    getGruppedBattleComments();
  }, [battleCommentList, checkedList, filteredBattleComments]);

  return { checkedList, filteredBattleComments, setCheckedList };
};
