import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetBattleQuery } from 'app/services/battles';
import { competitorSides } from 'config/competitorSides';
import { CompetitorSide } from 'interfaces/CompetitorSide';

export const useCompetitorsQueue = (): CompetitorSide[] => {
  const { id: battleId } = useParams();
  const { data: battleData } = useGetBattleQuery(battleId as string, { skip: !battleId });

  return useMemo(
    () => (battleData?.FirstCompetitor === competitorSides.RED ? [competitorSides.RED, competitorSides.BLUE] : [competitorSides.BLUE, competitorSides.RED]),
    [battleData?.FirstCompetitor],
  );
};
