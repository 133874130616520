import styled from 'styled-components';

export const HeaderCollapseButtonStyles = styled.div`
  position: absolute;
  bottom: -16px;
  z-index: 10;
  left: 50%;
  transform: translate(-50%);
  flex: 0;
  transition: all 0.5s;

  &.collapsed {
    bottom: -23px;

    .header-collapse-icon {
      transform: rotate(-180deg);
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.gray300};
    border-radius: 10000px;
    width: 32px;
    height: 32px;

    svg {
      transform: rotate(-90deg);
    }
  }

  .header-collapse-icon {
    transition: all 0.5s;
    cursor: pointer;
  }
`;
