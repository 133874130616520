export const videoRegexp = /^(https:\/\/www\.youtube\.com\/watch\?v=[A-Za-z0-9_-]{11})+/;
export const videoRegexpForYup = /^https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]{11}(&.+)?$/;

export const prepareYoutubeUrl = (url: string) => {
  const result = url.match(videoRegexp);
  if (result?.length) {
    return result[0];
  }
  return url;
};

export const getYouTubeVideoIdFromURL = (url = '') => {
  const [, videoId] = url.split('v=');
  const ampersandPosition = (videoId ?? '').indexOf('&');

  if (ampersandPosition !== -1) {
    return videoId.substring(0, ampersandPosition);
  }

  return videoId;
};

export const getYouTubeVideoThumbnailURL = (videoURL = '') => `https://img.youtube.com/vi/${getYouTubeVideoIdFromURL(videoURL)}/mqdefault.jpg`;
