import styled from 'styled-components';

export const SilevisLogoContainer = styled.div`
  background: ${({ theme }) => theme.black300};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0;

  .powered-by {
    color: ${({ theme }) => theme.white100};
    margin-right: 10px;
  }
`;
