import { FC } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { SiderCollapseButtonStyles } from 'components/SiderCollapseButton/SiderCollapseButtonStyles';
import { useMenuCollapsedProvider } from 'providers/MenuCollapsed';

const SiderCollapseButton: FC<{ className?: string }> = ({ className }) => {
  const { toggleSiderCollapsed, sidebarCollapsed } = useMenuCollapsedProvider();

  return (
    <SiderCollapseButtonStyles
      className={classNames(className, {
        collapsed: sidebarCollapsed,
      })}
      onClick={toggleSiderCollapsed}
    >
      <div className='sider-collapse-icon'>
        <DoubleRightOutlined />
      </div>
    </SiderCollapseButtonStyles>
  );
};

export default SiderCollapseButton;
