import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import appLogo from 'assets/icons/logo/short_white.svg';
import { Urls } from 'config/Urls';
import { Card } from 'antd';
import { FlexContainer } from 'components';
import { InfoPageWrapperStyles } from './InfoPageWrapper.styles';

type InfoPageWrapperProps = {
  title?: string;
  children: ReactNode;
};

export const InfoPageWrapper: FC<InfoPageWrapperProps> = ({ title = '', children }) => {
  return (
    <InfoPageWrapperStyles className='h-100vp p-24' justifyContent='center' alignItems='center'>
      <Card className='card-container shadow'>
        <FlexContainer className='w-80 mh-a' direction='column'>
          <Link className='text-link mb-8 align-self-center' to={Urls.HOME}>
            <img className='app-logo-img' src={appLogo} alt='Juttle.app logo' />
          </Link>
          <h1 className='font-20 font-bold mb-24 text-center'>{title}</h1>
          {children}
        </FlexContainer>
      </Card>
    </InfoPageWrapperStyles>
  );
};
