import { css } from 'styled-components';

export const spacing = (dimDelta = 5) => {
  const numberOfUtl = 20;
  const unit = 'px';
  let styles = '';

  for (let i = 0; i <= numberOfUtl; i++) {
    styles += `
        .m-${i * dimDelta} {
            margin: ${i * dimDelta}${unit} !important;
        }

        .ml-${i * dimDelta} {
            margin-left: ${i * dimDelta}${unit} !important;
        }
        
        .mr-${i * dimDelta} {
            margin-right: ${i * dimDelta}${unit} !important;
        }

        .mt-${i * dimDelta} {
            margin-top: ${i * dimDelta}${unit} !important;
        }

        .mb-${i * dimDelta} {
            margin-bottom: ${i * dimDelta}${unit} !important;
        }

        .mv-${i * dimDelta} {
            margin-top: ${i * dimDelta}${unit} !important;
            margin-bottom: ${i * dimDelta}${unit} !important;
        }

        .mh-${i * dimDelta} {
            margin-left: ${i * dimDelta}${unit} !important;
            margin-right: ${i * dimDelta}${unit} !important;
        }

        .p-${i * dimDelta} {
            padding: ${i * dimDelta}${unit} !important;
        }

        .pl-${i * dimDelta} {
            padding-left: ${i * dimDelta}${unit} !important;
        }
        
        .pr-${i * dimDelta} {
            padding-right: ${i * dimDelta}${unit} !important;
        }

        .pt-${i * dimDelta} {
            padding-top: ${i * dimDelta}${unit} !important;
        }

        .pb-${i * dimDelta} {
            padding-bottom: ${i * dimDelta}${unit} !important;
        }

        .pv-${i * dimDelta} {
            padding-top: ${i * dimDelta}${unit} !important;
            padding-bottom: ${i * dimDelta}${unit} !important;
        }

        .ph-${i * dimDelta} {
            padding-left: ${i * dimDelta}${unit} !important;
            padding-right: ${i * dimDelta}${unit} !important;
        }
       `;
  }

  styles += `
    .ml-a {
      margin-left: auto !important;
    }

    .mr-a {
      margin-right: auto !important;
    }

    .mt-a {
      margin-top: auto !important;
    }

    .mb-a {
      margin-bottom: auto !important;
    }

    .mh-a {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .mv-a {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .m-a {
      margin: auto !important;
    }
  `;

  return css`
    ${styles}
  `;
};

type SizingMixinProps = {
  dimDelta?: number;
  numberOfUtl?: number;
};

export const sizing = (config: SizingMixinProps = {}) => {
  const { dimDelta = 5, numberOfUtl = 20 } = config;
  let styles = '';

  for (let i = 0; i <= numberOfUtl; i++) {
    styles += `
      .w-${i * dimDelta} {
        width: ${i * dimDelta}% !important;
      }

      .h-${i * dimDelta} {
        height: ${i * dimDelta}% !important;
      }

      .w-${i * dimDelta}px {
        width: ${i * dimDelta}px !important;
      }

      .h-${i * dimDelta}px {
        height: ${i * dimDelta}px !important;
      }

      .w-${i * dimDelta}vp {
        width: ${i * dimDelta}vw !important;
      }

      .h-${i * dimDelta}vp {
        height: ${i * dimDelta}vh !important;
      }
    `;
  }

  styles += `
    .w-auto {
      width: auto !important;
    }
    
    .h-auto {
      height: auto !important;
    }
  `;

  return css`
    ${styles}
  `;
};

export const fontUtils = () => {
  const fontSizes = [10, 12, 14, 16, 20, 24, 30, 38, 46, 56];
  const fontSizeDelta = 8;
  const unit = 'px';
  let styles = '';

  fontSizes.forEach((size) => {
    styles += `
      .font-${size} {
        font-size: ${size}${unit} !important;
        line-height: ${size + fontSizeDelta}${unit} !important;
      }`;
  });

  styles += `
    .font-bold {
      font-weight: 600 !important;
    }
  `;

  return css`
    ${styles}
  `;
};

export const generalUtils = () => css`
  .text {
    &-blue {
      color: ${({ theme }) => theme.blue100} !important;
    }

    &-red {
      color: ${({ theme }) => theme.red200} !important;
    }

    &-gray {
      color: ${({ theme }) => theme.gray100} !important;
    }

    &-white {
      color: ${({ theme }) => theme.white100} !important;
    }

    &-yellow {
      color: ${({ theme }) => theme.yellow100} !important;
    }

    &-error {
      color: ${({ theme }) => theme.red100} !important;
    }

    &-warning {
      color: ${({ theme }) => theme.orange100} !important;
    }

    &-uppercase {
      text-transform: uppercase !important;
    }

    &-start {
      text-align: left !important;
    }

    &-center {
      text-align: center !important;
    }

    &-end {
      text-align: right !important;
    }

    &-wrap {
      overflow-wrap: anywhere !important;
    }

    &-ellipsis {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    &-nowrap {
      white-space: nowrap !important;
    }

    &-break-word {
      word-wrap: break-word !important;
    }

    &-italic {
      font-style: italic !important;
    }

    &-underline {
      text-decoration: underline !important;
    }

    &-link {
      :hover {
        text-decoration: underline !important;
      }
    }
  }

  .border {
    border: 2px solid !important;

    &-red {
      border-color: ${({ theme }) => theme.red200} !important;
    }

    &-blue {
      border-color: ${({ theme }) => theme.blue100} !important;
    }

    &-yellow {
      border-color: ${({ theme }) => theme.yellow100} !important;
    }

    &-green {
      border-color: ${({ theme }) => theme.green300} !important;
    }
  }

  .scroll {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: ${({ theme }) => theme.gray100} transparent;
    scrollbar-width: thin;

    &.scroll-x {
      overflow-x: auto;
      overflow-y: hidden;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: ${({ theme }) => theme.gray100};
    }
  }

  .shadow {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .inset-shadow {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) inset, 0 -4px 12px rgba(0, 0, 0, 0.15) inset;
  }

  .app-header {
    border-bottom: 1px solid ${({ theme }) => theme.black300};
  }

  .card-ellipsis-dropdown.anticon {
    position: absolute;
    bottom: 10px;
    right: 4px;
  }

  .white-space {
    white-space: pre-wrap !important;
  }

  .mw-500 {
    max-width: 500px;
    width: 100%;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .hidden {
    visibility: hidden !important;
  }

  .align-self {
    &-start {
      align-self: flex-start !important;
    }

    &-center {
      align-self: center !important;
    }

    &-end {
      align-self: flex-end !important;
    }
  }

  .clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;

    &-1 {
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }

    &-2 {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    &-3 {
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }

    &-4 {
      -webkit-line-clamp: 4;
      line-clamp: 4;
    }

    &-5 {
      -webkit-line-clamp: 5;
      line-clamp: 5;
    }
  }

  .img {
    &-rounded {
      border-radius: 4px !important;
    }

    &-cover {
      object-fit: cover !important;
    }

    &-hide-alt {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .position {
    &-absolute {
      position: absolute !important;
    }

    &-relative {
      position: relative !important;
    }

    &-fixed {
      position: fixed !important;
    }

    &-sticky {
      position: sticky !important;
    }
  }

  .background {
    &-blue {
      background: ${({ theme }) => theme.blue400} !important;
    }

    &-red {
      background: ${({ theme }) => theme.red400} !important;
    }

    &-yellow {
      background: ${({ theme }) => theme.yellow100} !important;
    }

    &-gray-200 {
      background: ${({ theme }) => theme.gray200} !important;
    }
  }
`;

export const antdOverrideStyles = () => css`
  span.anticon {
    color: ${({ theme }) => theme.yellow100};
  }

  .ant {
    &-btn {
      &[disabled] {
        opacity: 0.4;
        border-color: transparent;
        color: ${({ theme }) => theme.black300};
        background-color: ${({ theme }) => theme.yellow100};

        :hover {
          border-color: transparent;
          color: ${({ theme }) => theme.black300};
          background-color: ${({ theme }) => theme.yellow100};
        }
      }

      &-link[disabled] {
        background-color: transparent;
      }

      > .anticon {
        line-height: 0;
      }
    }

    &-layout {
      &-content {
        height: calc(100vh - 60px);
        background-color: ${({ theme }) => theme.black100};
      }

      &-header {
        .ant-menu {
          line-height: 54px;

          .ant-menu-item {
            padding: 0 24px;
          }
        }
      }
    }

    &-menu {
      &-horizontal > &-item-selected {
        color: ${({ theme }) => theme.white100};
      }

      &-vertical &-item {
        color: ${({ theme }) => theme.white300};
        border-right: 2px solid transparent;

        :hover {
          border-color: ${({ theme }) => theme.yellow100};

          .anticon {
            color: ${({ theme }) => theme.yellow100};
          }
        }

        .anticon {
          color: ${({ theme }) => theme.white300};
        }
      }

      &:not(.ant-menu-horizontal) &-item-selected {
        border-color: ${({ theme }) => theme.yellow100};
        background: ${({ theme }) => theme.gray200};
      }

      &-horizontal &-item {
        border-bottom: 3px solid transparent;
        :hover {
          border-bottom: 3px solid transparent;
          border-color: ${({ theme }) => theme.yellow100};
          color: ${({ theme }) => theme.yellow100};
          background: ${({ theme }) => theme.black200};
        }
      }

      &:not(.ant-menu-vertical) &-item-selected {
        border-bottom: 3px solid transparent;
        border-color: ${({ theme }) => theme.yellow100};
      }
    }

    &-empty {
      & &-description {
        color: ${({ theme }) => theme.gray100};
      }
    }

    &-tabs {
      color: ${({ theme }) => theme.gray100};
    }

    &-dropdown-menu {
      border-radius: 0;

      &-item {
        color: ${({ theme }) => theme.white300};

        :hover {
          background-color: ${({ theme }) => theme.gray200};
        }
      }
    }

    &-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: normal;
      border: 2px solid ${({ theme }) => theme.gray100};

      &&-icon span.anticon {
        color: ${({ theme }) => theme.white100};
      }

      &-lg {
        min-width: 36px;
        min-height: 36px;
      }
    }

    &-tag {
      padding: 5px 10px;
      border-radius: 6px;
      border-color: ${({ theme }) => theme.white300};
      color: ${({ theme }) => theme.white300};
    }

    &-modal-confirm {
      &-confirm {
        width: 450px !important;
      }
    }

    &-modal-confirm-btns {
      .ant-btn {
        &-default,
        &-primary {
          padding: 0;
          border-color: transparent;
          text-transform: uppercase;
        }

        &-primary {
          margin-left: 24px !important;
          color: ${({ theme }) => theme.yellow100};
          background-color: transparent;

          @media (max-width: 767px) {
            margin-left: 8px !important;
          }
        }
      }
    }

    &-input {
      :hover {
        border-bottom-color: ${({ theme }) => theme.gray100};
      }

      :focus {
        border-color: transparent;
        border-bottom-color: ${({ theme }) => theme.gray100};
        background-color: ${({ theme }) => theme.gray300} !important;
        box-shadow: none !important;
      }

      &&-status-error {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
      }

      &-affix-wrapper {
        &&-focused {
          border-color: transparent;
          border-bottom-color: ${({ theme }) => theme.gray100};
          background-color: ${({ theme }) => theme.gray300} !important;
          box-shadow: none !important;
        }

        :not(.ant-input-affix-wrapper-disabled):hover {
          border-color: transparent;
          border-bottom-color: ${({ theme }) => theme.gray100};
        }

        &&-status-error {
          border-top-color: transparent !important;
          border-left-color: transparent !important;
          border-right-color: transparent !important;

          .ant-input-password-icon.anticon {
            color: ${({ theme }) => theme.red100};

            :hover {
              color: ${({ theme }) => theme.red100};
            }
          }
        }
      }

      &-password-icon.anticon {
        color: ${({ theme }) => theme.gray100};

        :hover {
          color: ${({ theme }) => theme.gray100};
        }
      }

      &-group-addon {
        .ant-btn-icon-only {
          border-color: ${({ theme }) => theme.gray400};
        }
      }
    }

    &-input-number {
      &-input {
        padding: 8px 20px;
      }

      &-handler-wrap &-handler &-handler-up-inner,
      &-handler-wrap &-handler &-handler-down-inner {
        font-size: 10px;
      }

      &-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        box-shadow: none;
      }
    }

    &-radio-inner::after {
      background-color: ${({ theme }) => theme.white100};
    }

    &-select {
      &-item-option-disabled,
      &-disabled.ant-select:not(.ant-select-customize-input) &-selector {
        color: ${({ theme }) => theme.gray100};
        background-color: ${({ theme }) => theme.gray400};
      }

      &-disabled {
        .ant-select-arrow {
          span.anticon {
            opacity: 0.4;
          }
        }

        .ant-select-selection-placeholder {
          color: ${({ theme }) => theme.gray100};
        }
      }

      &-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) &-selector {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
      }

      &-dropdown {
        .rc-virtual-list-scrollbar-thumb {
          background-color: ${({ theme }) => theme.gray100} !important;
        }
      }

      &-multiple {
        .ant-select-selection-placeholder {
          left: 20px;
          right: 20px;
        }

        .ant-select-selector {
          padding: 8px 20px;
        }

        &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: ${({ theme }) => theme.gray400} !important;
        }
      }
    }

    &-checkbox {
      &-inner {
        border-color: ${({ theme }) => theme.yellow100};
      }
    }

    &-picker {
      padding: 8px 20px;

      &-focused {
        background-color: ${({ theme }) => theme.gray300};
      }

      &-cell {
        color: ${({ theme }) => theme.gray100};

        &-in-view {
          color: ${({ theme }) => theme.white100};
        }
      }

      &-header > button {
        color: ${({ theme }) => theme.yellow100};

        :hover {
          color: ${({ theme }) => theme.yellow100};
        }
      }

      &.ant-picker-disabled {
        span.anticon {
          opacity: 0.4;
        }
      }

      &-status-error {
        &.ant-picker,
        &.ant-picker:not([disabled]):hover {
          border-top-color: transparent !important;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          box-shadow: none;
        }
      }
    }

    &-table {
      & table {
        border-spacing: 0 16px;

        td,
        .ant-checkbox-inner {
          border-spacing: 0;
        }
      }

      &-row {
        background-color: ${({ theme }) => theme.black300};
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }

      &-thead > tr > th {
        padding-top: 0;
        padding-bottom: 0;
        color: ${({ theme }) => theme.white300};
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        ::before {
          display: none;
        }
      }

      &-tbody > tr {
        > td {
          border: none;
        }

        &.ant-table-row-selected > td {
          background-color: ${({ theme }) => theme.gray300};
        }

        &.ant-table-row:hover > td,
        & > td.ant-table-cell-row-hover {
          background: ${({ theme }) => theme.black300};
        }

        &.ant-table-placeholder:hover > td {
          background-color: transparent;
        }
      }

      &-sticky-holder {
        background-color: transparent;
      }

      &-pagination.ant-pagination {
        align-items: center;
      }
    }

    &-pagination {
      &-prev &-item-link,
      &-next &-item-link {
        border: none;
      }
    }

    &-drawer-header {
      border-bottom: 2px solid ${({ theme }) => theme.gray100};
    }

    &-tooltip-disabled-compatible-wrapper {
      .ant-btn {
        text-transform: inherit;
      }
    }

    &-upload.ant-upload-select-picture-card {
      background-color: transparent;
    }
  }
`;
