import { ScoreBoard } from 'interfaces/ScoreBoard';

export const scoreBoardsConfig: ScoreBoard = {
  trivium: {
    technique: 10,
    variety: 6.666,
    performance: 10,
    musicality: 6.666,
    creativity: 10,
    personality: 6.666,
  },
  threefold: {
    physicalQuality: 16.666,
    artisticQuality: 16.666,
    interpretiveQuality: 16.666,
  },
  classic: {
    winner: 100,
  },
  typeA: {
    technique: 10,
    vocabulary: 10,
    originality: 10,
    execution: 10,
    musicality: 10,
  },
  typeB: {
    physical: 16.666,
    interpretative: 16.666,
    artistic: 16.666,
  },
};
