import { FC, ReactNode } from 'react';
import { useUser } from 'hooks/useUser';
import ErrorPage from 'components/ErrorPages/ErrorPage';

type ProtectedRouteProps = {
  userRole: string | string[];
  children: ReactNode;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ userRole, children }) => {
  const { user } = useUser();

  const isAllowed = () => {
    return userRole.includes(user?.role?.name ?? '');
  };

  if (!isAllowed() && user) {
    return <ErrorPage code={403} />;
  }

  return <>{children}</>;
};
