import { Breadcrumb, Tag } from 'antd';
import { useGetBattleLiveQuery } from 'app/services/battlesLive';
import { useGetEventCategoryQuery } from 'app/services/categories';
import { useGetEventQuery } from 'app/services/events';
import { FlexContainer } from 'components/FlexContainer';
import { battleLiveTreeNames } from 'config/battleLiveTreeNames';
import { STATUSES, STATUSES_LIST } from 'config/statuses';
import { Urls } from 'config/Urls';
import { FC, ReactNode } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EventBreadcrumbsStyles } from 'components/EventBreadcrumbs/EventBreadcrumbs.styles';
import { TextWithTooltip } from 'components/TextWithTooltip/TextWithTooltip';
import classNames from 'classnames';

type EventBreadcrumbsProps = {
  statusSlot?: ReactNode;
  viewMode?: 'row' | 'column';
  showStatusTag?: boolean;
  className?: string;
  textSmall?: boolean;
};

export const EventBreadcrumbs: FC<EventBreadcrumbsProps> = ({ statusSlot = null, viewMode = 'column', showStatusTag = true, className, textSmall = true }) => {
  const { pathname } = useLocation();
  const { categoryId, eventId, battleId } = useParams();
  const { data: eventData, isLoading: isEventLoading } = useGetEventQuery(eventId, {
    skip: !eventId,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  const { data: eventCategoryData, isLoading: isEventCategoryLoading } = useGetEventCategoryQuery(categoryId, {
    skip: !categoryId,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  const { data, isLoading: isBattleLiveLoading } = useGetBattleLiveQuery(battleId, {
    skip: !battleId,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  const battleLiveData = data?.attributes;

  const pathSnippets = pathname.split('/').filter((i) => i);

  const breadcrumbNameMap: Record<string, string> = {
    [`${Urls.LIVE_EVENTS}/${eventId}`]: eventData?.attributes?.name ?? '',
    [`${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.KNOCKOUT}/${categoryId}`]: eventCategoryData?.attributes?.name ?? '',
    [`${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${categoryId}/${Urls.BATTLE_LIVE}/${battleId}`]:
      battleLiveTreeNames[battleLiveData?.tree_position ?? ''],
  };

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    if (Object.keys(breadcrumbNameMap).includes(url)) {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>
            <TextWithTooltip
              className={classNames(
                'font-bold clamp clamp-3',
                { 'font-38': viewMode === 'row' && !textSmall },
                { 'font-24': viewMode === 'column' && !textSmall },
                { 'font-30': textSmall },
              )}
              text={breadcrumbNameMap[url]}
            />
          </Link>
        </Breadcrumb.Item>
      );
    } else {
      return null;
    }
  });

  const getStatus = () => {
    switch (pathname) {
      case `${Urls.LIVE_EVENTS}/${eventId}`:
        return eventData?.attributes?.status;
      case `${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.KNOCKOUT}/${categoryId}`:
      case `${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.KNOCKOUT}/${categoryId}/${Urls.EVENT_CATEGORY_CONFIGURATION}`:
      case `${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.KNOCKOUT}/${categoryId}/${Urls.EVENT_CATEGORY_BREAKDOWN_SUMMARY}`:
        return eventCategoryData?.attributes?.status;
      case `${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${categoryId}/${Urls.BATTLE_LIVE}/${battleId}`:
        return battleLiveData?.status;
      default:
        return STATUSES_LIST.AWAITING_START;
    }
  };

  return (
    <>
      {(!isEventLoading || !isEventCategoryLoading || !isBattleLiveLoading) && (
        <EventBreadcrumbsStyles viewMode={viewMode} className={classNames(className)}>
          {showStatusTag && (
            <FlexContainer className='mb-24' alignItems='center'>
              <Tag className='text-uppercase'>{STATUSES[getStatus() ?? STATUSES_LIST.AWAITING_START]}</Tag>
              {statusSlot}
            </FlexContainer>
          )}
          <Breadcrumb className='event-breadcrumb' separator='>'>
            {breadcrumbItems}
          </Breadcrumb>
        </EventBreadcrumbsStyles>
      )}
    </>
  );
};
