import { useGetBattleLiveListQuery } from 'app/services/battlesLive';
import { useGetRaportQuery } from 'app/services/raports';
import { BattleLiveResponse } from 'interfaces/BattleLive';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils';

export const useBattleLivePlaces = () => {
  const { categoryId, raportId } = useParams();
  const { data: treeBattlesList } = useGetBattleLiveListQuery(categoryId as string, {
    skip: !categoryId || !!raportId,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  const { data: sharedRaportData } = useGetRaportQuery(raportId, { skip: !raportId });

  const sharedBattleLiveList: StrapiWrapper<BattleLiveResponse>[] | undefined = sharedRaportData?.attributes.battleLiveList;

  const finalBattle = (raportId ? sharedBattleLiveList : treeBattlesList)?.filter((e) => e.attributes.tree_position === '0/1');
  const thirdPlaceBattle = (raportId ? sharedBattleLiveList : treeBattlesList)?.filter((e) => e.attributes.tree_position === '1/1');

  const first = capitalizeFirstLetter(finalBattle?.[0]?.attributes?.winner === 'red' ? 'red' : 'blue') + 'Side';
  const second = capitalizeFirstLetter(finalBattle?.[0]?.attributes?.winner === 'blue' ? 'red' : 'blue') + 'Side';
  const third = capitalizeFirstLetter(thirdPlaceBattle?.[0]?.attributes?.winner === 'red' ? 'red' : 'blue') + 'Side';

  const winner = finalBattle?.length && finalBattle?.[0]?.attributes?.winner ? finalBattle?.[0]?.attributes?.[first]?.data[0]?.attributes?.Name : '';

  const secondPlace = finalBattle?.length && finalBattle?.[0]?.attributes?.winner ? finalBattle?.[0]?.attributes?.[second]?.data[0]?.attributes?.Name : '';

  const thirdPlace =
    thirdPlaceBattle?.length && thirdPlaceBattle?.[0]?.attributes?.winner ? thirdPlaceBattle?.[0]?.attributes?.[third]?.data[0]?.attributes?.Name : '';

  return { winner, secondPlace, thirdPlace };
};
