import { createGlobalStyle } from 'styled-components';
import '../antd-theme/antd-theme-customized.css';
import '../antd-theme/antd-theme-override.css';
import { antdOverrideStyles, fontUtils, generalUtils, sizing, spacing } from './mixins';

export const GlobalStyle = createGlobalStyle`
    :root {
        --toastify-color-light: ${({ theme }) => theme.gray100};
        --toastify-color-info: ${({ theme }) => theme.blue300};
        --toastify-color-error: ${({ theme }) => theme.red400};
        --toastify-color-success: ${({ theme }) => theme.green200};
        --toastify-toast-min-height: 40px;
    }
    
    html {
        box-sizing: border-box;
        font-size: 62.5%;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        font-size: 1.6rem;
        color: ${({ theme }) => theme.white100};
        background-color: ${({ theme }) => theme.black100};
    }

    .bg {
      background-image: linear-gradient(rgba(42, 40, 45, 0.5), rgba(42, 40, 45, 0.5)), url(${require('../background-funkowo.png')});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px ${({ theme }) => theme.gray400} inset;
        -webkit-text-fill-color: ${({ theme }) => theme.white100};
        transition: background-color 5000s ease-in-out 0s;
    }

    .competitor {
        &-avatar {
            width: 100%;
            font-size: 90px !important;

            &.card-avatar {
                height: 180px;
            }

            &.sider-avatar {
                height: 220px;
            }
        }

        &-notes {
            &-header {
                padding: 20px 170px 20px 16px;
            }

            &-collapse {
                &.ant-collapse-borderless > .ant-collapse-item {
                    background-color: ${({ theme }) => theme.black300};
                }
                &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header,
                .ant-collapse-content > .ant-collapse-content-box {
                    padding-right: 170px;
                }
            }
        }

        &-note {
            &-comment-input.text-field {
                padding: 0 !important;
                margin: 0 8px 0 0 !important;
            }

            &-user-avatar {
                > .font-16 {
                    height: 20px !important;
                    font-size: 12px !important;
                    line-height: 20px !important;
                }
            }

            &-modal {
                max-width: 680px;
            }
        }
    }

    .add-competitor-from-battle-modal {
        max-width: 700px;
    }

    .battle-rounds-modal {
        max-width: 900px;

        .ant-modal {
            &-body {
                padding-top: 130px;
                padding-bottom: 130px;
            }

            &-close {
                &-x {
                    width: 80px;
                }

                :hover {
                    color: ${({ theme }) => theme.yellow100};
                }
            }
        }
    }

    .battle-live-reset-btn {
        &.ant-btn[disabled] {
            .spinner {
                :before {
                    background-color: transparent;
                }

                :after {
                    border-color: ${({ theme }) => theme.yellow100};
                    border-top-color: transparent;
                }
            }

            :hover {
                background-color: transparent;
            }
        }
    }
    
    /* scrollbar styles start */
    * {
        scrollbar-width: thin;
        scrollbar-color: ${({ theme }) => theme.gray100} transparent;
    }

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.gray200};
        border-radius: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background: ${({ theme }) => theme.gray200};

        &:hover {
            background: ${({ theme }) => theme.gray100};
        }
    }
    /* scrollbar styles end */

    .Toastify__toast-theme--colored {
        border: 1px solid;
        border-radius: 0;

        &.Toastify__toast {
            &--success {
                border-color: ${({ theme }) => theme.green100};

                .Toastify__toast-icon {
                    color: ${({ theme }) => theme.green100};
                }
            }

            &--info {
                border-color: ${({ theme }) => theme.blue100};

                .Toastify__toast-icon {
                    color: ${({ theme }) => theme.blue100};
                }
            }

            &--error {
                border-color: ${({ theme }) => theme.red100};

                .Toastify__toast-icon {
                    color: ${({ theme }) => theme.red100};
                }
            }
        }
    }
    
    .theme {
        &-blue {
            .theme-btn-bg {
                background: ${({ theme }) => theme.blueSide};
            }
        }

        &-red {
            .theme-btn-bg {
                background: ${({ theme }) => theme.redSide};
            }
        }
    }

    .gradient-text {
        background: linear-gradient(
            to right,
            ${({ theme }) => theme.red100} 0%,
            ${({ theme }) => theme.red100} 50%,
            ${({ theme }) => theme.blue300} 50%,
            ${({ theme }) => theme.blue300} 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }

    //.dashboard-sider {
    //    li.ant-menu-item {
    //        margin-left: -24px !important;
    //        margin-right: -24px !important;
    //    }
    //    
    //    &.ant-menu-inline-collapsed {
    //        li.ant-menu-item {
    //            margin-left: -16px !important;
    //            margin-right: -16px !important;
    //            
    //            .anticon {
    //                min-width: 45px;
    //            }
    //        }
    //    }
    //}

    .list-view-table {
        max-width: 1600px;
        margin: 0 auto;
    }

    .drawer-mobile-menu {
        .ant-drawer-header {
            border-bottom: none;
        }

        .page-menu {
            &.ant-menu {
                font-weight: 500;
                
                &-vertical {
                    .ant-menu-item {
                        color: ${({ theme }) => theme.white100};
                        border-color: transparent;

                        :hover {
                            color: ${({ theme }) => theme.yellow100};
                        }
                    }

                    :not(.ant-menu-horizontal) .ant-menu-item-selected {
                        color: ${({ theme }) => theme.yellow100};
                    }
                }
            }
        }
    }

    ::selection {
        background: ${({ theme }) => theme.black100 + '80'};
    }

    ${antdOverrideStyles()}
    ${sizing()}
    ${sizing({ dimDelta: 8 })}
    ${spacing(5)} // 5px
    ${spacing(8)} // 8px
    ${fontUtils()}
    ${generalUtils()}
`;
