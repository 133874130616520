import { toast } from 'react-toastify';
import get from 'lodash/get';
import { competitorSides } from 'config/competitorSides';
import {
  SliderRangeClassicTypes,
  SliderRangeThreefoldTypes,
  SliderRangeTriviumTypes,
  SliderRangeTypeATypes,
  SliderRangeTypeBTypes,
} from 'interfaces/SliderRangeTypes';
import { useAppContext } from 'providers/AppProvider';
import { useResultProvider } from 'providers/ResultProvider';

type ButtonNames = 'repeat' | 'bite' | 'crash' | 'misbehavior' | 'misbehaviorTypeA' | 'misbehaviorTypeB' | 'execution' | 'form' | 'confidence' | 'spontaneity';

type Grade = number | { single: number; double: number; triple: number };

type Action = 'add' | 'subtract';

type ClickDetail = 'single' | 'double' | 'triple';

type RepeatButton = {
  buttonName: 'repeat';
  action: 'subtract';
  clickDetail?: never;
  side?: never;
};

type BiteButton = {
  buttonName: 'bite';
  action: 'subtract';
  clickDetail?: never;
  side?: never;
};

type CrashButton = {
  buttonName: 'crash';
  action: 'subtract';
  clickDetail: ClickDetail;
  side?: never;
};

type MisbehaviorButton = {
  buttonName: 'misbehavior';
  action: 'subtract';
  clickDetail: ClickDetail;
  side?: never;
};

type MisbehaviorTypeAButton = {
  buttonName: 'misbehaviorTypeA';
  action: 'subtract';
  clickDetail: ClickDetail;
  side: 'redSide' | 'blueSide';
};

type MisbehaviorTypeBButton = {
  buttonName: 'misbehaviorTypeB';
  action: 'subtract';
  clickDetail: ClickDetail;
  side: 'redSide' | 'blueSide';
};

type ExecutionButton = {
  buttonName: 'execution';
  action: Action;
  clickDetail?: never;
  side?: never;
};

type FormButton = {
  buttonName: 'form';
  action: Action;
  clickDetail?: never;
  side?: never;
};

type ConfidenceButton = {
  buttonName: 'confidence';
  action: Action;
  clickDetail?: never;
  side?: never;
};

type SpontaneityButton = {
  buttonName: 'spontaneity';
  action: Action;
  clickDetail?: never;
  side?: never;
};

type ButtonHandlerConfig =
  | RepeatButton
  | BiteButton
  | CrashButton
  | MisbehaviorButton
  | MisbehaviorTypeAButton
  | MisbehaviorTypeBButton
  | ExecutionButton
  | FormButton
  | ConfidenceButton
  | SpontaneityButton;

const buttonFaders: Record<
  ButtonNames,
  Array<SliderRangeTriviumTypes | SliderRangeThreefoldTypes | SliderRangeClassicTypes | SliderRangeTypeATypes | SliderRangeTypeBTypes>
> = {
  repeat: ['variety', 'creativity'],
  bite: ['variety', 'creativity', 'personality'],
  crash: ['performance', 'creativity', 'technique', 'personality', 'musicality', 'variety'],
  misbehavior: ['performance', 'creativity', 'personality'],
  misbehaviorTypeA: ['technique', 'vocabulary', 'originality', 'execution', 'musicality'],
  misbehaviorTypeB: ['physical', 'interpretative', 'artistic'],
  execution: ['technique', 'performance'],
  form: ['technique', 'performance'],
  confidence: ['performance', 'personality'],
  spontaneity: ['performance', 'creativity'],
};

const buttonGrades: Record<ButtonNames, Grade> = {
  repeat: 1.66,
  bite: 2.34,
  crash: { single: 2.5, double: 5, triple: 20 },
  misbehavior: { single: 2.66, double: 5.34, triple: 8 },
  misbehaviorTypeA: { single: 0.6, double: 1.2, triple: 2 },
  misbehaviorTypeB: { single: 1, double: 2, triple: 3.33 },
  execution: 2,
  form: 2,
  confidence: 1.66,
  spontaneity: 2,
};

export const useButtonHandler = () => {
  const { themeMode } = useAppContext();
  const { setResult } = useResultProvider();

  const buttonHandler = (config: ButtonHandlerConfig) => {
    if (themeMode === 'neutral') {
      return;
    }

    const { action, buttonName, clickDetail, side } = config;
    const toastInfoText = buttonName.toUpperCase();

    setResult({
      type: action + 'Many',
      payload: {
        types: buttonFaders[buttonName],
        team: side ?? themeMode,
        value: get(buttonGrades, `${buttonName}${clickDetail ? `.${clickDetail}` : ''}`) as unknown as number,
      },
    });

    if (!['crash', 'misbehavior', 'misbehaviorTypeA', 'misbehaviorTypeB'].includes(buttonName)) {
      toast[themeMode === 'blueSide' ? 'info' : 'error'](toastInfoText, {
        bodyClassName: `font-bold text-${
          themeMode === 'redSide' ? (action === 'add' ? competitorSides.RED : competitorSides.BLUE) : action === 'add' ? competitorSides.BLUE : 'red'
        }`,
      });
    }
  };

  return { buttonHandler };
};
