export const Urls = {
  HOME: '/',
  BLOG: '/blog',
  PRICING: '/pricing',
  APP_HISTORY: '/app-history',
  COOKIE_POLICY: '/cookie-policy',
  PRIVACY_POLICY: '/privacy-policy',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password-confirmation',
  RESET_PASSWORD: '/reset-password',
  ACCOUNT_VERIFICATION: '/account-verification',
  ACCOUNT_VERIFICATION_EMAIL: '/account-verification-email',
  ADD_NEW_BATTLE: '/add-new-battle',
  ADD_NEW_BATTLE_STEP_ONE: '/add-new-battle/step-one',
  ADD_NEW_BATTLE_STEP_TWO: '/add-new-battle/step-two',
  ADD_NEW_BATTLE_STEP_THREE: '/add-new-battle/step-three',
  ADD_NEW_COMPETITOR: '/add-new-competitor',
  ADMINISTRATION: '/administration',
  BATTLES: '/battles',
  BATTLES_JUDGE_PANEL: '/battles/judge-panel',
  BATTLES_VERDICTS: '/battles/verdicts',
  BATTLES_HIGHLIGHTS: '/battles/highlights',
  COMPETITORS: '/competitors',
  COMPETITORS_BATTLES: '/competitors/battles',
  COMPETITORS_NOTES: '/competitors/notes',
  COLLECTIONS: '/collections',
  EDIT_COMPETITOR: '/edit-competitor',
  MY_ACCOUNT: '/my-account',
  PAGE_NOT_FOUND: '/page-not-found',
  LIVE_EVENTS: '/live-events',
  EVENT_CATEGORY: 'category',
  ELIMINATIONS: 'eliminations',
  KNOCKOUT: 'knockout',
  EVENT_CATEGORY_TREE: 'tree',
  EVENT_CATEGORY_CONFIGURATION: 'configuration',
  EVENT_CATEGORY_BREAKDOWN_SUMMARY: 'breakdown-summary',
  SHARED: 'shared',
  ADD_NEW_EVENT_CATEGORY: 'add-new-event-category',
  ADD_NEW_EVENT_CATEGORY_STEP_ONE: 'add-new-event-category/step-one',
  ADD_NEW_EVENT_CATEGORY_STEP_TWO: 'add-new-event-category/step-two',
  BATTLE_LIVE: 'battle',
  LIVE_EVENT_REPORT: 'report',
  ADD_NEW_EVENT: '/add-new-event',
  ADD_NEW_EVENT_STEP_ONE: '/add-new-event/step-one',
  ADD_NEW_EVENT_STEP_TWO: '/add-new-event/step-two',
  SINGLE_BATTLE: '/single-battle',
  SINGLE_BATTLE_DEFINE: '/single-battle/define',
  COMPONENT_TEST: '/componentTest',
};

export const Endpoints = {
  EVENTS: '/events',
  CATEGORY_SETTINGS: '/category-settings',
  CATEGORIES: '/categories',
  BATTLE: '/battle',
  BATTLES: '/battles',
  SINGLE_BATTLES: '/single-battles',
  SINGLE_BATTLE_COMMENTS: '/single-battle-comments',
  ELIMINATION_TOOL: '/elimination-tools',
  BATTLES_LIVE: '/battles-live',
  ROUNDS: '/rounds',
  VERDICTS: '/verdicts',
  COLLECTIONS: '/collections',
  BATTLE_COMMENTS: '/battle-comments',
  BATTLE_NOTES: '/battle-notes',
  BATTLE_NOTE_COMMENTS: '/battle-note-comments',
  HIGHLIGHTS: '/highlights',
  COMPETITOR_NOTES: '/competitor-notes',
  COMPETITOR_NOTE_COMMENTS: '/competitor-note-comments',
  COMPETITORS: '/competitors',
  ME: '/users/me',
  LOGIN_USER: '/auth/local',
  USERS: '/users',
  RAPORTS: '/raports',
  USERS_ROLES: '/users-permissions/roles',
  REGISTER_USER: '/auth/local/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  UPLOAD: '/upload',
  WP_POSTS: 'posts',
  WP_PRICING: 'pages/36',
  WP_PRIVACY_POLICY: 'pages/3',
  WP_APP_HISTORY: 'pages/24',
  WP_COOKIE_POLICY: 'pages/24',
  ELIMINATIONS: '/eliminations',
  ELIMINATION_SETTINGS: '/elimination-settings',
  ELIMINATION_VERDICTS: '/elimination-verdicts',
};
