import { Button } from 'components';
import { Size } from 'interfaces/Size';
import styled, { css } from 'styled-components';

type IconButtonProps = {
  color?: string;
  rounded?: boolean;
  size?: Size;
};

export const IconButton = styled(Button).attrs({
  className: 'theme-btn-bg',
  type: 'button',
})<IconButtonProps>`
  width: 45px;
  height: 45px;
  border: 2px solid transparent;
  border-radius: ${({ rounded }) => (rounded ? '50%' : '5px')};
  padding: 0;
  background-color: ${({ theme, color }) => color ?? theme.gray200};
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }

  ${({ size }) =>
    size === 'm' &&
    css`
      width: 30px;
      height: 30px;

      svg {
        width: 20px;
        height: 20px;
      }
    `}

  ${({ size }) =>
    size === 's' &&
    css`
      width: 25px;
      height: 25px;

      svg {
        width: 16px;
        height: 16px;
      }
    `}
`;
