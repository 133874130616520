import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'config/Consts';
import { Endpoints } from 'config/Urls';
import { Battle, CreateBattle, UpdateBattle } from 'interfaces/Battle';
import { BattlesListItem } from 'interfaces/BattlesListItem';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { TimeAttributes } from 'interfaces/TimeAttributes';
import { PaginationParams } from 'providers/PaginationProvider';
import { SortByOptions } from 'types/SortTypes';
import { prepareYoutubeUrl } from 'utils/youtubeVideoUtils';
import { api } from '../api';

export const battleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBattle: build.query<TimeAttributes & Battle, string | number>({
      query: (id) => `${Endpoints.BATTLES}/${id}?&populate=BlueSide&populate=RedSide&populate=users_permissions_user`,
      providesTags: (result, error, id) => [{ type: 'Battle', id }],
      transformResponse(response: { data: StrapiWrapper<Battle> }) {
        return response?.data?.attributes;
      },
    }),

    getBattlesUrl: build.query<BattlesListItem[], string>({
      query: (id) => {
        return `${Endpoints.BATTLES}?filters[URL][$containsi]=${encodeURIComponent(prepareYoutubeUrl(id))}`;
      },
      transformResponse(response: { data: BattlesListItem[] }) {
        return response?.data;
      },
    }),

    getBattleList: build.query<
      { battlesList: BattlesListItem[]; meta: StrapiMeta },
      PaginationParams & { isAdmin?: boolean; userId?: number; sortBy: SortByOptions; sortOrder: "asc" | "desc" }
    >({
      query: ({ searchQuery = '', page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, userId, isAdmin = false, sortBy, sortOrder }) =>
        isAdmin
          ? `${Endpoints.BATTLES}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[RedSide][fields]=id,type,name&populate[BlueSide][fields]=id,type,name&populate[battle_comments][publicationState]=preview&filters[Name][$containsi]=${searchQuery}&[sort]=${sortBy}:${sortOrder}`
          : `${Endpoints.BATTLES}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[RedSide][fields]=id,type,name&populate[BlueSide][fields]=id,type,name&populate[battle_comments][filters][$or][0][public]=true&populate[battle_comments][filters][$or][1][users_permissions_user]=${userId}&filters[Name][$containsi]=${searchQuery}&[sort]=${sortBy}:${sortOrder}`,
      providesTags: () => [{ type: 'Battles', id: 'LIST' }],
      transformResponse(response: { data: BattlesListItem[]; meta: StrapiMeta }) {
        return { battlesList: response?.data, meta: response?.meta };
      },
    }),

    addBattle: build.mutation<{ data: StrapiWrapper<Battle> }, CreateBattle>({
      query: (body) => ({
        url: Endpoints.BATTLES,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'Battles', id: 'LIST' }],
    }),

    addBattleOptimistic: build.mutation<void, Battle>({
      query: (body) => ({
        url: Endpoints.BATTLES,
        method: 'POST',
        body: { data: body },
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          battleApi.util.updateQueryData('getBattleList', { searchQuery: '', page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE }, (draftBattles: any) => {
            draftBattles.unshift({ id: Date.now(), attributes: { ...patch } });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    editBattle: build.mutation<void, { id: number; body: UpdateBattle }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.BATTLES}/${id}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: [
        { type: 'Battles', id: 'LIST' },
        { type: 'Collection', id: 'USER_PLAYLISTS' },
      ],
    }),

    deleteBattle: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.BATTLES}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Battles', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBattleQuery,
  useLazyGetBattlesUrlQuery,
  useGetBattleListQuery,
  useEditBattleMutation,
  useDeleteBattleMutation,
  useAddBattleMutation,
  useAddBattleOptimisticMutation,
} = battleApi;
