import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useGetUsersForSelectQuery } from 'app/services/users';
import { judgingSystemList } from 'config/judgingSystemList';
import { numberOfCompetitorsList } from 'config/numberOfCompetitorsList';
import { yesNoList } from 'config/yesNoList';
import { FormValidationErrors } from 'interfaces/Form';
import { EditEventCategorySettings } from 'interfaces/EventCategorySettings';
import { MultiSelectField, NumberField, SelectField } from 'components/FormikComponents';

const numberOfCompetitorsWithoutThirdPlace = 2;
const recommendedNumberOfJudges = [3, 5, 7, 9];

export type KnockoutConfigurationFormValues = Omit<EditEventCategorySettings, 'isThirdPlace'> & {
  isThirdPlace: string;
};

export const knockoutConfigurationFormValidationShape = {
  judgingSystem: Yup.string().required(FormValidationErrors.REQUIRED),
  numberOfCompetitors: Yup.string().required(FormValidationErrors.REQUIRED),
  numberOfRounds16thFinals: Yup.number().integer(FormValidationErrors.NUMBER_INTEGER).min(1, FormValidationErrors.MIN_VAL).required(FormValidationErrors.REQUIRED),
  numberOfRoundsOktafinals: Yup.number().integer(FormValidationErrors.NUMBER_INTEGER).min(1, FormValidationErrors.MIN_VAL).required(FormValidationErrors.REQUIRED),
  numberOfRoundsQuarterfinals: Yup.number().integer(FormValidationErrors.NUMBER_INTEGER).min(1, FormValidationErrors.MIN_VAL).required(FormValidationErrors.REQUIRED),
  numberOfRoundsSemifinals: Yup.number().integer(FormValidationErrors.NUMBER_INTEGER).min(1, FormValidationErrors.MIN_VAL).required(FormValidationErrors.REQUIRED),
  numberOfRoundsFinal3rdPlace: Yup.number().integer(FormValidationErrors.NUMBER_INTEGER).min(1, FormValidationErrors.MIN_VAL).required(FormValidationErrors.REQUIRED),
  numberOfRoundsFinal1stPlace: Yup.number().integer(FormValidationErrors.NUMBER_INTEGER).min(1, FormValidationErrors.MIN_VAL).required(FormValidationErrors.REQUIRED),
  isThirdPlace: Yup.string().required(FormValidationErrors.REQUIRED),
  judges: Yup.array().of(Yup.string()).min(1, FormValidationErrors.SELECT_JUDGE).required(FormValidationErrors.REQUIRED),
};

type KnockoutConfigurationFormProps = {
  readonly?: boolean;
  hiddenFields?: {
    judgingSystem?: boolean;
    numberOfCompetitors?: boolean;
    isThirdPlace?: boolean;
    numberOfRounds16thFinals?: boolean;
    numberOfRoundsOktafinals?: boolean;
    numberOfRoundsQuarterfinals?: boolean;
    numberOfRoundsSemifinals?: boolean;
    numberOfRoundsFinal3rdPlace?: boolean;
    numberOfRoundsFinal1stPlace?: boolean;
    judges?: boolean;
  };
};

export const KnockoutConfigurationForm: FC<KnockoutConfigurationFormProps> = ({ readonly = false, hiddenFields: hiddenFields }) => {
  const { data: judgesList = [] } = useGetUsersForSelectQuery();
  const { values, setFieldValue, setStatus } = useFormikContext<KnockoutConfigurationFormValues>();

  const { judges, numberOfCompetitors, isThirdPlace } = values;

  useEffect(() => {
    if (numberOfCompetitors === numberOfCompetitorsWithoutThirdPlace) {
      setFieldValue('isThirdPlace', 'false');
    }
  }, [numberOfCompetitors, setFieldValue]);

  useEffect(() => {
    const numberOfJudges = judges?.length ?? 0;

    if (numberOfJudges && !recommendedNumberOfJudges.includes(numberOfJudges)) {
      setStatus({
        warning: {
          judges: 'INFO: To avoid ties, the recommended number of judges is 3, 5, 7, 9',
        },
      });
    } else {
      setStatus({ warning: {} });
    }
  }, [judges, setStatus]);

  return (
    <>
      {hiddenFields?.judgingSystem || (
        <SelectField id='judgingSystem' name='judgingSystem' label='Judging system' placeholder='choose...' options={judgingSystemList} disabled={readonly} />
      )}
      {hiddenFields?.numberOfCompetitors || (
        <SelectField
          id='numberOfCompetitors'
          name='numberOfCompetitors'
          label='Number of competitors'
          placeholder='choose...'
          options={numberOfCompetitorsList}
          disabled={readonly}
        />
      )}
      {hiddenFields?.isThirdPlace || (
        <SelectField
          id='isThirdPlace'
          name='isThirdPlace'
          label='Battle for the 3rd place?'
          placeholder='choose...'
          options={yesNoList}
          disabled={readonly || numberOfCompetitors === numberOfCompetitorsWithoutThirdPlace}
        />
      )}

      {hiddenFields?.numberOfRounds16thFinals ||
        (numberOfCompetitors > 16 && (
          <NumberField
            id='numberOfRounds16thFinals'
            name='numberOfRounds16thFinals'
            label='Default number of rounds in 1/16 finals'
            min={1}
            step={1}
            disabled={readonly}
          />
        ))}
      {hiddenFields?.numberOfRoundsOktafinals ||
        (numberOfCompetitors > 8 && (
          <NumberField
            id='numberOfRoundsOktafinals'
            name='numberOfRoundsOktafinals'
            label='Default number of rounds in 1/8 finals'
            min={1}
            step={1}
            disabled={readonly}
          />
        ))}

      {hiddenFields?.numberOfRoundsQuarterfinals ||
        (numberOfCompetitors > 4 && (
          <NumberField
            id='numberOfRoundsQuarterfinals'
            name='numberOfRoundsQuarterfinals'
            label='Default number of rounds in 1/4 finals'
            min={1}
            step={1}
            disabled={readonly}
          />
        ))}
      {hiddenFields?.numberOfRoundsSemifinals ||
        (numberOfCompetitors > 2 && (
          <NumberField
            id='numberOfRoundsSemifinals'
            name='numberOfRoundsSemifinals'
            label='Default number of rounds in 1/2 finals'
            min={1}
            step={1}
            disabled={readonly}
          />
        ))}

      {hiddenFields?.numberOfRoundsFinal3rdPlace ||
        (isThirdPlace === 'true' && (
          <NumberField
            id='numberOfRoundsFinal3rdPlace'
            name='numberOfRoundsFinal3rdPlace'
            label='Default number of rounds in 3rd place battle'
            min={1}
            step={1}
            disabled={readonly}
          />
        ))}

      {hiddenFields?.numberOfRoundsFinal1stPlace || (
        <NumberField
          id='numberOfRoundsFinal1stPlace'
          name='numberOfRoundsFinal1stPlace'
          label='Default number of rounds in 1st place battle'
          min={1}
          step={1}
          disabled={readonly}
        />
      )}

      {hiddenFields?.judges || (
        <MultiSelectField
          id='judges'
          name='judges'
          label='Judges'
          placeholder='type to search...'
          allowClear
          showSearch
          options={judgesList}
          disabled={readonly}
        />
      )}
    </>
  );
};
