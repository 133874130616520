import styled, { css } from 'styled-components';
import { Size } from 'interfaces/Size';

export type ButtonProps = {
  color?: string;
  size?: Size;
};

export const Button = styled.button.attrs({ type: 'button' })<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  padding: 7px 15px;
  margin: 3px;
  color: inherit;
  background-color: ${({ theme, color }) => color || theme.gray200};
  font-size: 2.2rem;
  cursor: pointer;

  ${({ size }) =>
    size === 'm' &&
    css`
      padding: 10px;
      font-size: 1.8rem;
    `}

  ${({ size }) =>
    size === 's' &&
    css`
      padding: 6px 10px;
      font-size: 1.6rem;
    `}

  :active {
    filter: brightness(0.85);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const BtnGroupLabel = styled.p`
  margin: 0;
  font-size: 1.6rem;
  text-align: center;
`;

export const BtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
