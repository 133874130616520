import { FC } from 'react';
import { competitorSides } from 'config/competitorSides';
import { useAppContext } from 'providers/AppProvider';

type WipeOutProps = {
  message?: string;
};

export const WipeOut: FC<WipeOutProps> = ({ message = '' }) => {
  const { themeMode } = useAppContext();

  return (
    <>
      <span className={themeMode === 'redSide' ? competitorSides.RED : competitorSides.BLUE}>{`${message.toUpperCase()}!!!`}</span>
    </>
  );
};
