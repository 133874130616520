import { BarsOutlined, CalendarOutlined, SettingOutlined, TeamOutlined, ThunderboltOutlined, TrophyOutlined } from '@ant-design/icons';
import { Urls } from 'config/Urls';
import { useUser } from 'hooks';
import { UserRoles } from 'interfaces/UserRoles';
import { useMemo } from 'react';

const navItemsByRole = {
  [UserRoles.ADMIN]: [Urls.BATTLES, Urls.SINGLE_BATTLE, Urls.LIVE_EVENTS, Urls.COLLECTIONS, Urls.COMPETITORS, Urls.ADMINISTRATION],
  [UserRoles.JUDGE]: [Urls.BATTLES, Urls.SINGLE_BATTLE, Urls.LIVE_EVENTS, Urls.COLLECTIONS, Urls.COMPETITORS],
  [UserRoles.ITSPECIALIST]: [Urls.LIVE_EVENTS, Urls.COMPETITORS, Urls.ADMINISTRATION],
  [UserRoles.SPECTATOR]: [Urls.LIVE_EVENTS],
};

const navItems = {
  [Urls.BATTLES]: {
    key: Urls.BATTLES,
    label: 'Battles',
    icon: <TrophyOutlined />,
  },
  [Urls.SINGLE_BATTLE]: {
    key: Urls.SINGLE_BATTLE,
    label: 'Single battle',
    icon: <ThunderboltOutlined />,
  },
  [Urls.LIVE_EVENTS]: {
    key: Urls.LIVE_EVENTS,
    label: 'Live events',
    icon: <CalendarOutlined />,
  },
  [Urls.COLLECTIONS]: {
    key: Urls.COLLECTIONS,
    label: 'Collections',
    icon: <BarsOutlined />,
  },
  [Urls.COMPETITORS]: {
    key: Urls.COMPETITORS,
    label: 'Competitors & Crews',
    icon: <TeamOutlined />,
  },
  [Urls.ADMINISTRATION]: {
    key: Urls.ADMINISTRATION,
    label: 'Administration',
    icon: <SettingOutlined />,
  },
};

export const useDashboardNavigationItems = () => {
  const { user } = useUser();

  return useMemo(() => navItemsByRole[user?.role?.name ?? UserRoles.JUDGE].map((key) => navItems[key]), [user]);
};
