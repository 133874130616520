import { FC, ReactNode } from 'react';
import { Card as AntdCard, MenuProps } from 'antd';
import classNames from 'classnames';
import { EllipsisDropdown } from 'components';

type CardProps = {
  className?: string;
  dropdownMenu?: MenuProps;
  menuVisible?: boolean;
  children: ReactNode;
  onClick?: () => void;
};

export const Card: FC<CardProps> = ({ className = '', menuVisible = true, dropdownMenu = {}, children, onClick }) => {
  return (
    <AntdCard
      className={classNames(className, 'shadow', {
        'cursor-pointer': onClick,
      })}
      bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onClick={onClick}
    >
      {children}

      {menuVisible && <EllipsisDropdown className='card-ellipsis-dropdown' menu={dropdownMenu} />}
    </AntdCard>
  );
};
