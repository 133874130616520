import { BattleLiveVerdict } from 'interfaces/BattleLiveVerdict';
import { Result } from 'interfaces/Result';
import { VerdictResult } from 'interfaces/Verdict';

export const getVerdictResults = (verdictData: (BattleLiveVerdict | Result | null)[] = []): VerdictResult => {
  const result: VerdictResult = verdictData.reduce(
    (prev: VerdictResult, curr: BattleLiveVerdict | Result | null) => ({
      ...prev,
      ...(curr && { [curr?.winner?.side]: prev[curr?.winner?.side] + 1 }),
    }),
    { blue: 0, red: 0 },
  );

  return result;
};
