import { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'assets/styles/theme';
import { GlobalStyle } from 'assets/styles/GlobalStyle';

type ThemeAppProviderProps = {
  children: ReactNode;
};

const ThemeAppProvider: FC<ThemeAppProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default ThemeAppProvider;
