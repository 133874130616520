import { scoreBoardsConfig } from 'config/scoreBoard';
import { ScoreBoardTypes } from 'interfaces/ScoreBoard';
import { useJudgePanelTypeProvider } from 'pages/Battle/JudgePanelTypeProvider';
import { useResultProvider } from 'providers/ResultProvider';

export interface UseCurrentJudgePanel {
  currentJudgePanelConfig: ScoreBoardTypes;
  resultForCurrentJudgePanel: ScoreBoardTypes;
}

export const useCurrentJudgePanel = (): UseCurrentJudgePanel => {
  const { result } = useResultProvider();
  const { judgePanelType } = useJudgePanelTypeProvider();

  const currentJudgePanelConfig = scoreBoardsConfig[judgePanelType];
  const resultForCurrentJudgePanel = result[judgePanelType];

  return { currentJudgePanelConfig, resultForCurrentJudgePanel };
};
