import styled from 'styled-components';

export const AppLoaderStyles = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  animation: fadeout 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
  background: ${({ theme }) => theme.black100};

  .app-loader-in {
    height: 100%;
    width: 100%;

    img {
      width: 200px;
    }
  }

  .loading-app-in-out {
    visibility: hidden;
    animation: fadeinout 2s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  @keyframes fadeinout {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    30% {
      visibility: visible;
      opacity: 1;
    }
    60% {
      opacity: 1;
      visibility: visible;
    }
    90% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
`;
