import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Radio, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useGetBattleQuery } from 'app/services/battles';
import { useAddBattleCommentMutation, useUpdateBattleCommentMutation } from 'app/services/battlesComments';
import { FlexContainer } from 'components';
import SpinnerButton from 'components/Spinner/SpinnerButton/SpinnerButton';
import { UseModal, useUser } from 'hooks';
import { Result } from 'interfaces/Result';
import { useJudgePanelTypeProvider } from 'pages/Battle/JudgePanelTypeProvider';
import VerdictHeader from 'pages/Battle/Verdict/VerdictHeader';
import VerdictScores from 'pages/Battle/Verdict/VerdictScores';
import { VerdictSubmitStyles } from 'pages/Battle/Verdict/VerdictSubmit.styles';
import { useAppContext } from 'providers/AppProvider';
import { useRoundVerdictProvider } from 'providers/RoundVerdict';

const verdictTypes = [
  {
    label: 'Private verdict',
    value: false,
  },
  {
    label: 'Public verdict',
    value: true,
  },
];

interface VerdictFormProps {
  modal: UseModal;
}

export const VerdictSubmit: FC<VerdictFormProps> = ({ modal }) => {
  const { user } = useUser();
  const { id: battleId } = useParams();
  const { setThemeMode } = useAppContext();
  const [isPublic, setIsPublic] = useState(true);
  const { judgePanelType } = useJudgePanelTypeProvider();
  const { data: battleData } = useGetBattleQuery(battleId as string);
  const [addBattleComment, { isLoading: isAddLoading }] = useAddBattleCommentMutation();
  const [updateBattleComment, { isLoading: isUpdateLoading }] = useUpdateBattleCommentMutation();
  const { roundVerdict, verdictId, setVerdictId, setRoundVerdict, resetActiveButton, resetSelectedQueueBtn } = useRoundVerdictProvider();

  const submitResult = async () => {
    const battleCommentsData = {
      date: new Date().toISOString(),
      verdict: roundVerdict,
      battle: battleId as string,
      type: judgePanelType,
      public: isPublic,
      ['users_permissions_user']: user?.id,
    };

    try {
      if (verdictId) {
        await updateBattleComment({ id: verdictId, body: battleCommentsData });
        setVerdictId(null);
      } else {
        await addBattleComment({ battleId: battleId as string, body: battleCommentsData });
      }

      resetActiveButton();
      setRoundVerdict({});
      setThemeMode('redSide');
      resetSelectedQueueBtn();
      toast.success('Verdict successfully submitted');
      modal.close();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VerdictSubmitStyles>
      <FlexContainer className='mb-32' direction='column' justifyContent='center' alignItems='center'>
        <h3>SUBMIT YOUR VERDICT</h3>
        <p>{battleData?.Name}</p>
      </FlexContainer>

      <div className='verdict-content inset-shadow'>
        <VerdictHeader className='mb-32' />

        <FlexContainer className='round-verdicts mb-24' justifyContent={Object.entries(roundVerdict).length < 5 ? 'center' : 'flex-start'}>
          {Object.entries(roundVerdict).map(([round, result]) => (
            <VerdictScores key={round} result={result as Result} round={round} roundTitleVisible />
          ))}
        </FlexContainer>
      </div>

      <FlexContainer justifyContent='center' alignItems='center'>
        <Tooltip title='Your private verdicts are visible to you only. Public verdicts are visible to anyone accessing this battle.'>
          <QuestionCircleOutlined className='verdict-type-info text-gray mr-16' />
        </Tooltip>

        <Radio.Group value={isPublic} options={verdictTypes} onChange={(e) => setIsPublic(e.target.value)} />

        <SpinnerButton
          className='mt-a align-self-center ml-24'
          type='primary'
          label='Submit'
          loading={isAddLoading || isUpdateLoading}
          onClick={submitResult}
        />
      </FlexContainer>
    </VerdictSubmitStyles>
  );
};
