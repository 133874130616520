import { useGetBattleCommentsListQuery } from 'app/services/battlesComments';
import { useUser, useUserRoles } from 'hooks';
import { BattleComment } from 'interfaces/BattleComment';
import { VerdictVariant } from 'interfaces/Verdict';
import { getBattleWinner, getVerdictsSummarize, getWinner } from 'utils';

export const useWinner = (battleId = '', filteredBattleComments: BattleComment[] = []) => {
  const { user } = useUser();
  const { isAdmin } = useUserRoles();
  const { data: battleComments = [] } = useGetBattleCommentsListQuery({ isAdmin, battleId, userId: user?.id || 0 });

  const verdictsSummarize = getVerdictsSummarize(battleComments);

  const battleWinner = getBattleWinner(verdictsSummarize);

  const filteredVerdictsSummarize = getVerdictsSummarize(filteredBattleComments);

  const filteredBattleWinner = getBattleWinner(filteredVerdictsSummarize);

  const getVerdictWinner = (verdictId: number): VerdictVariant => {
    const verdict = battleComments.find(({ id }) => id === verdictId);
    return getWinner(Object.values(verdict?.verdict ?? {}));
  };

  return { battleWinner, verdictsSummarize, filteredBattleWinner, filteredVerdictsSummarize, getVerdictWinner };
};
