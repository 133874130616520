import styled from 'styled-components';
import { FlexContainer } from 'components';

export const InfoPageWrapperStyles = styled(FlexContainer)`
  .card-container {
    max-width: 450px;
  }

  .app-logo-img {
    display: block;
    width: 75px;
    height: 75px;
  }
`;
