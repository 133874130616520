export const battleLiveTreeNames: Record<string, string> = {
  ['1/16']: '1/16 final - pair 1',
  ['2/16']: '1/16 final - pair 2',
  ['3/16']: '1/16 final - pair 3',
  ['4/16']: '1/16 final - pair 4',
  ['5/16']: '1/16 final - pair 5',
  ['6/16']: '1/16 final - pair 6',
  ['7/16']: '1/16 final - pair 7',
  ['8/16']: '1/16 final - pair 8',
  ['9/16']: '1/16 final - pair 9',
  ['10/16']: '1/16 final - pair 10',
  ['11/16']: '1/16 final - pair 11',
  ['12/16']: '1/16 final - pair 12',
  ['13/16']: '1/16 final - pair 13',
  ['14/16']: '1/16 final - pair 14',
  ['15/16']: '1/16 final - pair 15',
  ['16/16']: '1/16 final - pair 16',
  ['1/8']: '1/8 final - pair 1',
  ['2/8']: '1/8 final - pair 2',
  ['3/8']: '1/8 final - pair 3',
  ['4/8']: '1/8 final - pair 4',
  ['5/8']: '1/8 final - pair 5',
  ['6/8']: '1/8 final - pair 6',
  ['7/8']: '1/8 final - pair 7',
  ['8/8']: '1/8 final - pair 8',
  ['1/4']: '1/4 final - pair 1',
  ['2/4']: '1/4 final - pair 2',
  ['3/4']: '1/4 final - pair 3',
  ['4/4']: '1/4 final - pair 4',
  ['1/2']: '1/2 final - pair 1',
  ['2/2']: '1/2 final - pair 2',
  ['0/1']: '1st place battle',
  ['1/1']: '3rd place battle',
};
