import { api } from 'app/api';
import { Endpoints } from 'config/Urls';
import { ResetPasswordValues } from 'interfaces/ResetPassword';
import { User } from 'interfaces/User';
import { UserCreditials } from 'interfaces/UserCreditials';
import { UserData } from 'interfaces/UserData';
import { UserLogin } from 'interfaces/UserLogin';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.mutation<UserData, UserLogin>({
      query: (credentials) => ({
        url: Endpoints.LOGIN_USER,
        method: 'POST',
        body: credentials,
      }),
    }),

    registerUser: build.mutation<UserData, UserCreditials>({
      query: (credentials) => ({
        url: Endpoints.REGISTER_USER,
        method: 'POST',
        body: credentials,
      }),
    }),

    forgotPassword: build.mutation<void, { email: string }>({
      query: (credentials) => ({
        url: Endpoints.FORGOT_PASSWORD,
        method: 'POST',
        body: credentials,
      }),
    }),

    resetPassword: build.mutation<void, ResetPasswordValues>({
      query: (credentials) => ({
        url: Endpoints.RESET_PASSWORD,
        method: 'POST',
        body: credentials,
      }),
    }),

    updateUser: build.mutation<void, { id: number; body: Partial<User> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.USERS}/${id}`,
        method: 'PUT',
        body,
      }),
    }),

    getUser: build.query<User, void>({
      query: () => `${Endpoints.ME}?populate[role][fields]=name`,
      providesTags: () => [{ type: 'User', id: 'USER' }],
    }),
  }),
});

export const {
  useGetUserQuery,
  useForgotPasswordMutation,
  useLazyGetUserQuery,
  useLoginUserMutation,
  useResetPasswordMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
} = userApi;
