import { useCurrentJudgePanel } from 'hooks/useCurrentJudgePanel';

export const usePrepareScore = () => {
  const { currentJudgePanelConfig, resultForCurrentJudgePanel } = useCurrentJudgePanel();

  return Object.entries(currentJudgePanelConfig).reduce(
    (p: { red: number; blue: number }, [n, v]) => ({
      red: Number(v) - resultForCurrentJudgePanel[n] + p.red,
      blue: Number(v) + resultForCurrentJudgePanel[n] + p.blue,
      // red: +Number(v - result[n as keyof typeof result] + p.red).toFixed(2),
      // blue: +Number(v + result[n as keyof typeof result] + p.blue).toFixed(2)
    }),
    { red: 0, blue: 0 },
  );
};
