import { useDeleteEventMutation } from 'app/services/events';
import { STATUSES_LIST } from 'config/statuses';
import { toast } from 'react-toastify';
import { useEventStatus } from 'utils/useEventStatus';
import { useConfirmModal } from './useConfirmModal';

export const useLiveEventActions = () => {
  const { openConfirmModal } = useConfirmModal();
  const [deleteEventApi] = useDeleteEventMutation();
  const { setStatus } = useEventStatus();

  const deleteEvent = (eventId: number, eventName = '') => {
    openConfirmModal({
      title: 'Delete event',
      content: (
        <div>
          You are going to permanently delete the event <strong>{eventName}</strong> including all its data (categories, verdicts etc.). This action cannot be
          undone. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          await deleteEventApi(eventId);
          toast.success('Event deleted');
        } catch (error) {
          console.error(error);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const closeEvent = (eventId: number, eventName = '') => {
    openConfirmModal({
      title: 'Close event',
      content: (
        <div>
          You are going to close the event <strong>{eventName}</strong>.<br />
          Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, close it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          setStatus(STATUSES_LIST.CLOSED, eventId);
        } catch (error) {
          console.error(error);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const reopenEvent = (eventId: number, eventName = '') => {
    openConfirmModal({
      title: 'Reopen event',
      content: (
        <div>
          You are going to reopen the event <strong>{eventName}</strong>.<br />
          Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, reopen it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          setStatus(STATUSES_LIST.ONGOING, eventId);
        } catch (error) {
          console.error(error);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  return { deleteEvent, closeEvent, reopenEvent };
};
