import { toast } from 'react-toastify';
import { useDeleteBattleMutation } from 'app/services/battles';
import { Urls } from 'config/Urls';
import { copyLink } from 'utils';
import { useConfirmModal } from './useConfirmModal';

export const useBattleActions = () => {
  const { openConfirmModal } = useConfirmModal();
  const [deleteBattleApi] = useDeleteBattleMutation();

  const copyBattleLink = (battleId: number) => {
    copyLink(battleId, Urls.BATTLES_JUDGE_PANEL);
  };

  const deleteBattle = (battleId: number, battleName = '') => {
    openConfirmModal({
      title: 'Delete battle',
      content: (
        <div>
          You are going to permanently delete the battle <strong>{battleName}</strong> including its verdicts and highlights. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          await deleteBattleApi(battleId);
          toast.success('Battle deleted');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return { copyBattleLink, deleteBattle };
};
