import { MouseEvent } from 'react';
import debounce from 'lodash/debounce';
import { useAppContext } from 'providers/AppProvider';

export type ClickHandlerEvent = MouseEvent<HTMLElement>;

/**
 * each subsequent one function arguments coresponds to x-times click handler, e.g.
 * useClickHandlers(singleClickHandler, doubleClickHandler, tripleClickHandler, ...)
 */

export const useClickHandlers = (...clickHandlers: Array<(event: ClickHandlerEvent) => void>) => {
  const { themeMode } = useAppContext();

  const callClickEventHandler = (event: ClickHandlerEvent) => {
    if (themeMode === 'neutral') {
      return;
    }

    const { detail } = event;

    if (detail <= 0) {
      return;
    }

    const clickHandler = clickHandlers[detail - 1];

    if (clickHandler) {
      clickHandler(event);
    }
  };

  const debounceClickHandler = debounce((event: ClickHandlerEvent) => {
    callClickEventHandler(event);
  }, 250);

  return (event: ClickHandlerEvent) => {
    debounceClickHandler(event);
  };
};
