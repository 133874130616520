import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

interface BackgroundStyleProps {
  backgroundStyle: string;
  isBackgroundOn: boolean;
  setBackground: Dispatch<SetStateAction<boolean>>;
}

const BackgroundStyle = createContext<BackgroundStyleProps>({} as BackgroundStyleProps);

export const useBackgroundStyle = (): BackgroundStyleProps => {
  const context = useContext(BackgroundStyle);

  if (context === undefined) {
    throw Error('useBackgroundProvider must be used within a BackgroundProvider');
  }

  return context;
};

interface BackgroundStyleProviderProps {
  children: ReactNode;
}

export const BackgroundProvider: FC<BackgroundStyleProviderProps> = ({ children }) => {
  const [isBackgroundOn, setBackground] = useState<boolean>(false);
  const backgroundStyle = isBackgroundOn ? 'bg' : '';

  return <BackgroundStyle.Provider value={{ backgroundStyle, isBackgroundOn, setBackground }}>{children}</BackgroundStyle.Provider>;
};
