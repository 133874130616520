import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { Input } from 'antd';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';

type TextareaField = FormFieldDefaultProps & {
  rows?: number;
  cols?: number;
  autoSize?:
    | boolean
    | {
        minRows?: number;
        maxRows?: number;
      };
};

export const TextareaField: FC<TextareaField> = ({
  id,
  className = '',
  name,
  label = '',
  placeholder = '',
  disabled = false,
  autoSize,
  cols = 3,
  rows = 3,
  onChange,
  onBlur,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <Input.TextArea
            id={id}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            autoSize={autoSize}
            cols={cols}
            rows={rows}
            placeholder={placeholder}
            disabled={disabled}
            status={meta.touched && meta.error ? 'error' : undefined}
          />
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
        </div>
      )}
    </Field>
  );
};
