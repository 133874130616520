import { useParams } from 'react-router-dom';
import { useGetBattleLiveQuery } from 'app/services/battlesLive';
import { STATUSES_LIST } from 'config/statuses';
import { BattleLiveRounds } from 'interfaces/BattleLiveRound';

export const useBattleLive = () => {
  const { battleId } = useParams();
  const {
    data,
    isLoading: isBattleLoading,
    isFetching: isBattleFetching,
  } = useGetBattleLiveQuery(battleId, {
    skip: !battleId,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  const battleLiveData = data?.attributes;
  const battleTreePosition = battleLiveData?.tree_position ?? '';

  const redCompetitor = {
    name: battleLiveData?.RedSide?.data?.[0]?.attributes?.Name ?? '',
    country: battleLiveData?.RedSide?.data?.[0]?.attributes?.Country ?? '',
  };

  const blueCompetitor = {
    name: battleLiveData?.BlueSide?.data?.[0]?.attributes?.Name ?? '',
    country: battleLiveData?.BlueSide?.data?.[0]?.attributes?.Country,
  };

  const battleStatus = battleLiveData?.status ?? STATUSES_LIST.AWAITING_START;
  const allBattleRoundsClosed = Object.values((battleLiveData?.rounds as BattleLiveRounds) || {}).every((round) => round.status === STATUSES_LIST.CLOSED);
  const battleClosed = battleLiveData?.status === STATUSES_LIST.CLOSED && allBattleRoundsClosed;
  const rounds: BattleLiveRounds = battleLiveData?.rounds || {};
  const numberOfRounds = rounds ? Object.keys(rounds || {})?.length : 0;
  const numberOfClosedRounds = Object.values(rounds).filter((round) => round?.status === STATUSES_LIST.CLOSED).length || 0;
  const winner = battleLiveData?.winner;

  return {
    allBattleRoundsClosed,
    battleClosed,
    battleLiveData,
    battleStatus,
    battleTreePosition,
    blueCompetitor,
    isBattleFetching,
    isBattleLoading,
    numberOfClosedRounds,
    numberOfRounds,
    redCompetitor,
    rounds,
    winner,
  };
};
