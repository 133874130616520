/* eslint-disable */
import { useEffect, useState } from 'react';

interface UseSpinnerProps {
  spinnerVisible: boolean;
}

const useSpinner = (isVisible: boolean, time = 750): UseSpinnerProps => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  useEffect(() => {
    let timeout: any;
    if (isVisible) {
      timeout = setTimeout(() => {
        setSpinnerVisible(true);
      }, +time);
    }

    return () => {
      setSpinnerVisible(false);
      clearTimeout(timeout);
    };
  }, [isVisible]);

  return { spinnerVisible };
};

export default useSpinner;
