import { FC } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FormValidationErrors } from 'interfaces/Form';
import { Button } from 'antd';
import { FlexContainer } from 'components';
import { TextField } from 'components/FormikComponents';
import SpinnerButton from 'components/Spinner/SpinnerButton/SpinnerButton';

type NewModalFormValues = { name: string };

const nameModalFormValidationSchema = Yup.object().shape({
  name: Yup.string().max(255, FormValidationErrors.MAX_LENGTH).required(FormValidationErrors.REQUIRED),
});

export type NameModalFormProps = {
  initialValues: NewModalFormValues;
  inputPlaceholder?: string;
  loading: boolean;
  onCancel?: () => void;
  onSubmit: (values: NewModalFormValues, actions?: FormikHelpers<NewModalFormValues>) => void;
};

const NameModalForm: FC<NameModalFormProps> = ({ initialValues = { name: '' }, inputPlaceholder = 'Name...', loading, onCancel, onSubmit }) => {
  return (
    <Formik initialValues={initialValues} validationSchema={nameModalFormValidationSchema} enableReinitialize={true} validateOnMount onSubmit={onSubmit}>
      {({ isValid }: FormikProps<NewModalFormValues>) => (
        <Form className='h-100'>
          <FlexContainer className='h-100' direction='column'>
            <TextField id='name' name='name' placeholder={inputPlaceholder} />

            <FlexContainer className='mt-8' justifyContent='flex-end'>
              <Button className='mr-8 text-uppercase' onClick={onCancel}>
                Cancel
              </Button>
              <SpinnerButton label='Save' loading={loading} disabled={!isValid} />
            </FlexContainer>
          </FlexContainer>
        </Form>
      )}
    </Formik>
  );
};

export default NameModalForm;
