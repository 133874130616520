import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

interface MenuCollapsedProps {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  toggleSiderCollapsed: () => void;
  headerCollapsed: boolean;
  setHeaderCollapsed: Dispatch<SetStateAction<boolean>>;
  toggleHeaderCollapsed: () => void;
}

const MenuCollapsed = createContext<MenuCollapsedProps>({} as MenuCollapsedProps);

export const useMenuCollapsedProvider = (): MenuCollapsedProps => {
  const context = useContext(MenuCollapsed);

  if (context === undefined) {
    throw Error('useMenuCollapsedProvider must be used within a MenuCollapsedProvider');
  }

  return context;
};

interface MenuCollapsedProviderProps {
  children: ReactNode;
}

export const MenuCollapsedProvider: FC<MenuCollapsedProviderProps> = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
  const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(false);

  const toggleHeaderCollapsed = () => {
    setHeaderCollapsed((p) => !p);
  };

  const toggleSiderCollapsed = () => {
    setSidebarCollapsed((p) => !p);
  };

  return (
    <MenuCollapsed.Provider value={{ sidebarCollapsed, setSidebarCollapsed, toggleSiderCollapsed, headerCollapsed, setHeaderCollapsed, toggleHeaderCollapsed }}>
      {children}
    </MenuCollapsed.Provider>
  );
};
