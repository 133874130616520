import { useDeleteCompetitorMutation, useDeleteCompetitorNoteCommentMutation, useDeleteCompetitorNoteMutation } from 'app/services/competitors';
import { Urls } from 'config/Urls';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirmModal } from './useConfirmModal';

export const useCompetitorActions = () => {
  const navigate = useNavigate();
  const { openConfirmModal } = useConfirmModal();
  const [deleteCompetitorApi] = useDeleteCompetitorMutation();
  const [deleteCompetitorNoteApi] = useDeleteCompetitorNoteMutation();
  const [deleteCompetitorNoteCommentApi] = useDeleteCompetitorNoteCommentMutation();

  const deleteCompetitor = (competitorId: number, competitorName = '') => {
    openConfirmModal({
      title: 'Delete competitor',
      content: (
        <div>
          You are going to permanently delete the competitor <strong>{competitorName}</strong>. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          await deleteCompetitorApi(competitorId);
          toast.success('Competitor deleted');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const editCompetitor = (competitorId: number) => {
    navigate(`${Urls.EDIT_COMPETITOR}/${competitorId}`);
  };

  const deleteCompetitorNote = (noteId: string, competitorId: string, noteDescription = '') => {
    openConfirmModal({
      title: 'Delete note',
      content: (
        <div>
          You are going to permanently delete the note: <strong>{noteDescription}</strong>. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          await deleteCompetitorNoteApi({ id: noteId, competitorId });
          toast.success('Note deleted');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const deleteCompetitorNoteComment = (noteCommentId: number, competitorId: string, noteCommentDescription = '') => {
    openConfirmModal({
      title: 'Delete note comment',
      content: (
        <div>
          You are going to permanently delete the note comment: <strong>{noteCommentDescription}</strong>. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          await deleteCompetitorNoteCommentApi({ id: noteCommentId, competitorId });
          toast.success('Note comment deleted');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return { deleteCompetitor, deleteCompetitorNote, deleteCompetitorNoteComment, editCompetitor };
};
