import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { BlueFlag, RedFlag } from 'assets/icons';
import { FlexContainer } from 'components';
import { Result } from 'interfaces/Result';
import { ScoreBoardTypes } from 'interfaces/ScoreBoard';
import { VerdictScoresStyles } from 'pages/Battle/Verdict/VerdictScores.styles';
import { capitalizeFirstLetter, getBoundedScore } from 'utils';
import { useUser } from 'hooks';
import { UserRoles } from 'interfaces/UserRoles';

interface VerdictScoresProps {
  children?: ReactNode;
  className?: string;
  result: Result;
  round: string;
  roundTitleVisible?: boolean;
}

const VerdictScores: FC<VerdictScoresProps> = ({ className, result, round, roundTitleVisible = false }) => {
  const { user } = useUser();

  const isSpectator = user?.role?.name === UserRoles.SPECTATOR;
  const isRedSideLeft = !isSpectator;

  const getVerdict = (side: 'red' | 'blue', result: ScoreBoardTypes | undefined) =>
    parseFloat(
      `${Object.values(result || {}).reduce((p: number, n: number) => {
        if (side === 'red') {
          return getBoundedScore(p - n);
        }
        return getBoundedScore(p + n);
      }, 50)}`,
    ).toFixed(2);

  return (
    <VerdictScoresStyles className={classNames(className)}>
      <FlexContainer className='mb-16' direction='column' justifyContent='center' alignItems='center'>
        {roundTitleVisible && <div className='mb-8 text-center font-bold'>Round {round}</div>}
        <FlexContainer className='w-100' justifyContent='space-around'>
          {isRedSideLeft ? (
            <>
              <FlexContainer alignItems='center'>
                <BlueFlag />
                <div className='ml-16'>{getVerdict('blue', result?.result)}%</div>
              </FlexContainer>
              vs.
              <FlexContainer alignItems='center'>
                <RedFlag />
                <div className='ml-16'>{getVerdict('red', result?.result)} %</div>
              </FlexContainer>
            </>
          ) : (
            <>
              <FlexContainer alignItems='center'>
                <RedFlag />
                <div className='ml-16'>{getVerdict('red', result?.result)} %</div>
              </FlexContainer>
              vs.
              <FlexContainer alignItems='center'>
                <BlueFlag />
                <div className='ml-16'>{getVerdict('blue', result?.result)}%</div>
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        className={classNames('p-8 verdict-details', {
          '-red': result?.winner.side === 'red',
          '-blue': result?.winner.side === 'blue',
        })}
        direction='column'
        alignItems='center'
      >
        {Object.entries(result?.result || {}).map(([title, value]: [title: string, value: number]) => (
          <FlexContainer key={title + round} className='w-100 mb-8' direction='column'>
            <FlexContainer className='font-bold' justifyContent='center'>
              {capitalizeFirstLetter(title.replace(/([A-Z])/g, ' $1'))}
            </FlexContainer>

            <FlexContainer className='scale font-14' justifyContent='center'>
              {value === 0 ? (
                <div>|</div>
              ) : (
                <FlexContainer className='score w-100'>
                  {isRedSideLeft ? (
                    <>
                      <div>
                        {value > 0 && (
                          <FlexContainer alignItems='center'>
                            <div className='win -blue' />
                            <div className='score ml-8'>+{value.toFixed(2) + ' %'}</div>
                          </FlexContainer>
                        )}
                      </div>
                      <div>
                        {value < 0 && (
                          <FlexContainer justifyContent='flex-end' alignItems='center'>
                            <div className='score mr-8'>+{parseFloat('' + -value).toFixed(2) + ' %'}</div>
                            <div className='win -red' />
                          </FlexContainer>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {value < 0 && (
                          <FlexContainer justifyContent='flex-end' alignItems='center'>
                            <div className='score mr-8'>+{parseFloat('' + -value).toFixed(2) + ' %'}</div>
                            <div className='win -red' />
                          </FlexContainer>
                        )}
                      </div>
                      <div>
                        {value > 0 && (
                          <FlexContainer alignItems='center'>
                            <div className='win -blue' />
                            <div className='score ml-8'>+{value.toFixed(2) + ' %'}</div>
                          </FlexContainer>
                        )}
                      </div>
                    </>
                  )}
                </FlexContainer>
              )}
            </FlexContainer>
          </FlexContainer>
        ))}
      </FlexContainer>
    </VerdictScoresStyles>
  );
};

export default VerdictScores;
