import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Urls } from 'config/Urls';
import { useUser } from 'hooks';
import { UserRoles } from 'interfaces/UserRoles';

interface BackToHomePageButtonProps {
  className?: string;
}

export const BackToHomePageButton: FC<BackToHomePageButtonProps> = ({ className = '' }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <Button
      className={className}
      type='link'
      icon={<ArrowLeftOutlined />}
      onClick={() => navigate([UserRoles.ITSPECIALIST, UserRoles.SPECTATOR].includes(user?.role?.name ?? '') ? Urls.LIVE_EVENTS : Urls.BATTLES)}
    >
      Back to home page
    </Button>
  );
};
