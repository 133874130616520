import styled from 'styled-components';
import { CompetitorSide } from 'interfaces/CompetitorSide';
import { competitorSides } from 'config/competitorSides';

export interface SliderRangeProps {
  leftColor?: CompetitorSide;
}

export const SliderRangeStyles = styled.div<SliderRangeProps>`
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background: linear-gradient(
    to right,
    ${({ theme, leftColor }) => (leftColor !== competitorSides.BLUE ? theme.redSide : theme.blueSide)} 0%,
    ${({ theme, leftColor }) => (leftColor !== competitorSides.BLUE ? theme.redSide : theme.blueSide)} 50%,
    ${({ theme, leftColor }) => (leftColor !== competitorSides.BLUE ? theme.blueSide : theme.redSide)} 50%,
    ${({ theme, leftColor }) => (leftColor !== competitorSides.BLUE ? theme.blueSide : theme.redSide)} 100%
  );
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 5px;

  label {
    position: absolute;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.white100};
    text-shadow: 1px 1px 1px ${({ theme }) => theme.black300};
    width: 100%;
    left: 0;

    &.top {
      top: -24px;
    }

    &.bottom {
      bottom: -24px;
    }
  }

  .slider-range {
    box-sizing: border-box;
    height: 10px;
    border: 1px solid ${({ theme }) => theme.black300};
    border-radius: 3px;
    margin: 12px 0;
    appearance: none;
    outline: none;
    accent-color: ${({ theme }) => theme.blueSide};
  }

  .slider-range::-webkit-slider-thumb {
    width: 35px;
    height: 35px;
    border: 1px solid ${({ theme }) => theme.black300};
    appearance: none;
    cursor: pointer;
    background: ${({ theme }) => theme.white100};
    border-radius: 50%;
    z-index: 1;
    position: relative;
  }

  .slider-range.minus::-webkit-slider-thumb {
    margin-left: -14px;
    transition: all 0.6s;
  }

  .slider-range.normal::-webkit-slider-thumb {
    margin-left: 0;
  }

  .slider-range.plus::-webkit-slider-thumb {
    margin-left: 14px;
    transition: all 0.6s;
  }

  .datalist {
    display: flex;
    margin-top: -10px;
    padding-top: 0;
    justify-content: space-between;
    z-index: 0;
  }

  .option {
    z-index: 0;
    font-weight: bold;
    padding: 0;
    font-size: 5px;
    color: ${({ theme }) => theme.black300};
  }

  .option:first-child,
  .option:last-child,
  .option.highlight {
    color: ${({ theme }) => theme.white100};
    font-size: 7px;
  }
`;
