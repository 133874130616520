import { toast } from 'react-toastify';

export const copyToClipboard = (value: string) => {
  const textarea = document.createElement('textarea');
  textarea.value = value;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy');
    toast.success('Copied to clipboard');
  } catch (error) {
    toast.error('Error while copying to clipboard');
    console.error('Error while copying to clipboard:', error);
  }
  document.body.removeChild(textarea);
};
