import { store } from 'app/store';
import AppLoader from 'components/AppLoader/AppLoader';
import { JudgePanelTypeProvider } from 'pages/Battle/JudgePanelTypeProvider';
import AppProvider from 'providers/AppProvider';
import { BackgroundProvider } from 'providers/BackgroundProvider';
import { MenuCollapsedProvider } from 'providers/MenuCollapsed';
import NotificationProvider from 'providers/NotificationProvider';
import { RedirectPathProvider } from 'providers/RedirectPathProvider';
import { ResultProviderProvider } from 'providers/ResultProvider';
import ThemeAppProvider from 'providers/ThemeAppProvider';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppLazy = lazy(() => Promise.all([import('./App'), new Promise((resolve) => setTimeout(resolve, 2000))]).then(([moduleExports]) => moduleExports));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeAppProvider>
          <AppProvider>
            <JudgePanelTypeProvider>
              <ResultProviderProvider>
                <BackgroundProvider>
                  <NotificationProvider>
                    <MenuCollapsedProvider>
                      <RedirectPathProvider>
                        <Suspense fallback={<AppLoader />}>
                          <AppLazy />
                        </Suspense>
                      </RedirectPathProvider>
                    </MenuCollapsedProvider>
                  </NotificationProvider>
                </BackgroundProvider>
              </ResultProviderProvider>
            </JudgePanelTypeProvider>
          </AppProvider>
        </ThemeAppProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
