import { FC } from 'react';
import appLogo from 'assets/icons/logo/short_white.svg';
import { AppLogoStyles } from './AppLogo.styles';

type AppLogoProps = {
  title?: string;
  text?: string;
};

export const AppLogo: FC<AppLogoProps> = ({ text = '', title = 'Welcome to Juttle.app' }) => {
  return (
    <AppLogoStyles className='mb-24' direction='column' alignItems='center'>
      <img className='app-logo-img' src={appLogo} alt='App logo' />
      <span className='mb-8 font-20 font-bold text-center'>{title}</span>
      <span className='font-14 text-center'>{text}</span>
    </AppLogoStyles>
  );
};
