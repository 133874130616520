import styled from 'styled-components';

export const ScoreBoardStyles = styled.div`
  position: relative;
  height: 100%;
  color: ${({ theme }) => theme.white100};

  .red-score {
    border: 4px solid ${({ theme }) => theme.red400};
    height: 100%;
    width: 100%;
    padding: 15px;
  }

  .blue-score {
    border: 4px solid ${({ theme }) => theme.blue400};
    height: 100%;
    width: 100%;
    padding: 15px;
  }

  .red-win,
  .blue-win,
  .header {
    z-index: 1;
  }

  .no-winner {
    color: ${({ theme }) => theme.white100};
  }

  .progress {
    position: absolute;
    bottom: 0;
    width: 100%;

    &.-red {
      background: ${({ theme }) => theme.red400};
    }

    &.-blue {
      background: ${({ theme }) => theme.blue400};
    }
  }
`;
