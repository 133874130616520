import { ACCESS_TOKEN, api } from 'app/api';
import { Endpoints } from 'config/Urls';
import { RoleData } from 'interfaces/RoleData';
import { SelectOption } from 'interfaces/SelectOption';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { User } from 'interfaces/User';
import { UserData } from 'interfaces/UserData';
import { UserRoles } from 'interfaces/UserRoles';
import { SortByOptions } from 'types/SortTypes';
import { uuidv4 } from 'utils/uuidv4';

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<User[], { isAdmin: boolean; searchQuery: string; sortBy: SortByOptions; sortOrder: 'asc' | 'desc' }>({
      query: ({ sortBy, sortOrder, searchQuery, isAdmin }) =>
        `${
          Endpoints.USERS
        }?filters[$or][0][username][$containsi]=${searchQuery}&filters[$or][1][email][$containsi]=${searchQuery}&populate[role][fields]=name&[sort]=${sortBy}:${sortOrder}
        ${isAdmin ? '' : `&filters?populate=role&filters[role][name][$eq]=${UserRoles.JUDGE}`}`,
      providesTags: () => [{ type: 'Users', id: 'LIST' }],
    }),

    getUsersForSelect: build.query<SelectOption[], void>({
      query: () => `${Endpoints.USERS}?populate=role&filters[role][name][$eq]=${UserRoles.JUDGE}`,
      providesTags: () => [{ type: 'Users', id: 'SELECT_LIST' }],
      transformResponse(response: User[]) {
        return response?.map((user) => ({
          value: `${user.id}`,
          label: `${user.username}`,
        }));
      },
    }),

    getUsersRolesForSelect: build.query<SelectOption[], void>({
      query: () => `${Endpoints.USERS_ROLES}?[sort]=name`,
      providesTags: () => [{ type: 'User', id: 'ROLES' }],
      transformResponse(response: { roles: RoleData[] }) {
        return response.roles
          .filter(({ name: roleName }) => Object.values(UserRoles).includes(roleName))
          .map(({ id, name: label }) => ({
            value: `${id}`,
            label,
          }));
      },
    }),

    updateUsers: build.mutation<
      void,
      {
        id: string | number;
        body: Partial<Omit<User, 'role'> & { role: string | number }>;
      }
    >({
      query: ({ id, body }) => ({
        url: `${Endpoints.USERS}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    createTmpUser: build.mutation<void, { name: string }>({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryFn: async ({ name }, _queryApi, _extraOptions, fetchWithBQ): Promise<any> => {
        try {
          const { data: userRoles } = await fetchWithBQ({ url: Endpoints.USERS_ROLES, method: 'GET' });
          const judgeRoleId = (
            userRoles as {
              roles: RoleData[];
            }
          )?.roles?.find((item) => item?.name === UserRoles.JUDGE)?.id;
          const username = `${name.replaceAll(' ', '-')}-${uuidv4('1000')}`;
          const password = uuidv4('00001000');

          const body = {
            username,
            email: `${username}@juttle.app`,
            password,
            role: judgeRoleId,
            confirmed: true,
            temporaryUser: true,
            // blocked: false, // blocked flag in post does not work
          };
          const { data } = await fetchWithBQ({ url: Endpoints.USERS, method: 'POST', body });
          await fetchWithBQ({
            url: `${Endpoints.USERS}/${(data as User).id}`,
            method: 'PUT',
            body: { blocked: false },
          });
          const loginBody = {
            identifier: username,
            password: password,
          };
          const { data: loginData } = await fetchWithBQ({
            url: Endpoints.LOGIN_USER,
            method: 'POST',
            body: loginBody,
            headers: { Authorization: '' },
          });
          localStorage.setItem(ACCESS_TOKEN, (loginData as UserData).jwt);
          window.location.href = '/';
        } catch (error) {
          return { error };
        }
      },
    }),

    updateUsersBlockedOptimistic: build.mutation<
      void,
      {
        id: string | number;
        body: Partial<Omit<User, 'role'> & { role: string | number }>;
      }
    >({
      query: ({ id, body }) => ({
        url: `${Endpoints.USERS}/${id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUsers', '', (draft) => {
            return [...draft].map<any>((e) => {
              if (+e.id === +id) {
                return { ...e, ...patch.body };
              }
              return e;
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    updateUsersBlockedPessimistic: build.mutation<
      void,
      {
        id: string | number;
        body: Partial<Omit<User, 'role'> & { role: string | number }>;
      }
    >({
      query: ({ id, body }) => ({
        url: `${Endpoints.USERS}/${id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updateUsers } = await queryFulfilled;

        dispatch(
          usersApi.util.updateQueryData('getUsers', '', (draft) => {
            return [...draft].map<any>((e) => {
              if (+e.id === +id) {
                return updateUsers;
              }
              return e;
            });
          }),
        );
      },
    }),

    updateUsersRoleOptimistic: build.mutation<
      void,
      {
        id: string | number;
        body: Partial<Omit<User, 'role'> & { role: string | number }>;
      }
    >({
      query: ({ id, body }) => ({
        url: `${Endpoints.USERS}/${id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUsers', '', (draft) => {
            return [...draft].map<any>((e) => {
              if (+e.role.id !== +(patch?.body?.role || 0) && +e.id === +id) {
                return { ...e, role: { ...e.role, id: patch.body.role } };
              }
              return e;
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    deleteUsers: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.USERS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    checkUser: build.query<boolean, string>({
      query: (username) => `${Endpoints.USERS}?filters[$and][0][username][$eq]=${username}`,
      transformResponse(response: { data: StrapiWrapper<User>[]; meta: StrapiMeta }) {
        return !!response[0];
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyCheckUserQuery,
  useGetUsersForSelectQuery,
  useGetUsersRolesForSelectQuery,
  useDeleteUsersMutation,
  useUpdateUsersMutation,
  useUpdateUsersRoleOptimisticMutation,
  useUpdateUsersBlockedOptimisticMutation,
  useUpdateUsersBlockedPessimisticMutation,
  useCreateTmpUserMutation,
} = usersApi;
