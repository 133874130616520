import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { Input } from 'antd';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';

type TextFieldProps = FormFieldDefaultProps & {
  type?: 'text' | 'email' | 'url';
};

export const TextField: FC<TextFieldProps> = ({
  id,
  className = '',
  type = 'text',
  name,
  label = '',
  placeholder = '',
  disabled = false,
  autoFocus,
  onChange,
  onBlur,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <Input
            id={id}
            type={type}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            status={meta.touched && meta.error ? 'error' : undefined}
            suffix={<span className='text-error'>{meta.touched && meta.error ? '!' : ''}</span>}
          />
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
        </div>
      )}
    </Field>
  );
};
