import { ScoreBoard } from 'interfaces/ScoreBoard';
import {
  SliderRangeClassicTypes,
  SliderRangeThreefoldTypes,
  SliderRangeTriviumTypes,
  SliderRangeTypeATypes,
  SliderRangeTypeBTypes,
} from 'interfaces/SliderRangeTypes';
import { useJudgePanelTypeProvider } from 'pages/Battle/JudgePanelTypeProvider';
import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react';
import { ReducerAction, resultsReducer } from 'reducers/resultsReducer';

export const resultsInitState: ScoreBoard = {
  trivium: {
    technique: 0,
    variety: 0,
    performance: 0,
    musicality: 0,
    creativity: 0,
    personality: 0,
  },
  threefold: {
    physicalQuality: 0,
    artisticQuality: 0,
    interpretiveQuality: 0,
  },
  classic: {
    winner: 0,
  },
  typeB: {
    physical: 0,
    interpretative: 0,
    artistic: 0,
  },
  typeA: {
    technique: 0,
    vocabulary: 0,
    originality: 0,
    execution: 0,
    musicality: 0,
  },
};

interface ResultProviderProps {
  result: ScoreBoard;
  setResult: Dispatch<
    ReducerAction<SliderRangeTriviumTypes | SliderRangeThreefoldTypes | SliderRangeClassicTypes | SliderRangeTypeATypes | SliderRangeTypeBTypes>
  >;
  resetResult: () => void;
}

type ChildrenProps = {
  children: ReactNode;
};

const ResultProvider = createContext({} as ResultProviderProps);

export const useResultProvider = (): ResultProviderProps => useContext(ResultProvider);

export const ResultProviderProvider: FC<ChildrenProps> = (props) => {
  const { children } = props;
  const { judgePanelType } = useJudgePanelTypeProvider();
  const [result, setResult] = useReducer(resultsReducer(judgePanelType), resultsInitState);

  const resetResult = () => {
    setResult({
      type: 'reset',
      payload: {
        value: 0,
      },
    });
  };

  return <ResultProvider.Provider value={{ result, setResult, resetResult }}>{children}</ResultProvider.Provider>;
};
