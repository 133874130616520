import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { openVersionLog } from 'features/auth/authSlice';
import packageJson from '../../../package.json';

interface AppVersionProps {
  className: string;
}

const AppVersion: FC<AppVersionProps> = ({ className = '' }) => {
  const dispatch = useDispatch();

  return (
    <Button className={className} type='link' onClick={() => dispatch(openVersionLog())}>
      {`v. ${packageJson.version}`}
    </Button>
  );
};

export default AppVersion;
