import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type FlexContainerProps = {
  alignItems?: CSSProperties['alignItems'];
  direction?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
  flex?: CSSProperties['flexGrow'];
  gap?: CSSProperties['gap'];
};

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;

  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `}

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
`;
