import { useCallback } from 'react';
import { usePrepareScore } from './usePrepareScore';

export const NoWinnerSide = 'no-winner';
export const NoWinnerScore = 'No winner';

export const useRoundWinner = () => {
  const roundScore = usePrepareScore();

  const getRoundWinner = useCallback(() => {
    const winner = {
      side: NoWinnerSide,
      score: NoWinnerScore,
    };

    if (roundScore.blue > roundScore.red) {
      winner.side = 'blue';
      winner.score = +Number(roundScore.blue - 50).toFixed(2) + ' %';
    }

    if (roundScore.blue < roundScore.red) {
      winner.side = 'red';
      winner.score = +Number(roundScore.red - 50).toFixed(2) + ' %';
    }

    return winner;
  }, [roundScore.blue, roundScore.red]);

  return { getRoundWinner };
};
