import styled, { css } from 'styled-components';

type Props = {
  viewMode: 'row' | 'column';
};

export const EventBreadcrumbsStyles = styled.div<Props>`
  .event-breadcrumb.ant-breadcrumb {
    ol {
      flex-direction: ${({ viewMode }) => viewMode};

      ${({ viewMode }) =>
        viewMode === 'row' &&
        css`
          align-items: center;
        `}

      li {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: ${({ theme }) => theme.white300};

        ${({ viewMode }) =>
          viewMode === 'row' &&
          css`
            display: flex;
            align-items: center;
          `}

        :last-child {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          color: ${({ theme }) => theme.white200};
        }
      }
    }
  }
`;
