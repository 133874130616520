import { useGetBattleQuery } from 'app/services/battles';
import { Verdict } from 'interfaces/Verdict';
import { useJudgePanelTypeProvider } from 'pages/Battle/JudgePanelTypeProvider';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface RoundVerdictProps {
  activeButton: number;
  verdictId: number | null;
  roundVerdict: Verdict;
  setRoundVerdict: Dispatch<SetStateAction<Verdict>>;
  setActiveButton: Dispatch<SetStateAction<number>>;
  setVerdictId: Dispatch<SetStateAction<number | null>>;
  checkIfReadyToSubmit: boolean;
  resetActiveButton: () => void;
  selectedQueueBtn: string | null;
  setSelectedQueueBtn: Dispatch<SetStateAction<string | null>>;
  resetSelectedQueueBtn: () => void;
}

const RoundVerdict = createContext<RoundVerdictProps>({} as RoundVerdictProps);

export const useRoundVerdictProvider = (): RoundVerdictProps => {
  const context = useContext(RoundVerdict);

  if (context === undefined) {
    throw Error('useRoundVerdictProvider must be used within a RoundVerdictProvider');
  }

  return context;
};

interface RoundVerdictProviderProps {
  children: ReactNode;
}

export const RoundVerdictProvider: FC<RoundVerdictProviderProps> = ({ children }) => {
  const [roundVerdict, setRoundVerdict] = useState<Verdict>({});
  const { judgePanelType } = useJudgePanelTypeProvider();
  const [activeButton, setActiveButton] = useState<number>(1);
  const [verdictId, setVerdictId] = useState<number | null>(null);
  const { id: battleId } = useParams();
  const { data: battleData } = useGetBattleQuery(battleId as string, { skip: !battleId });
  const [selectedQueueBtn, setSelectedQueueBtn] = useState<string | null>(null);

  useEffect(() => {
    const results: Verdict = {};

    if (battleData?.NumberOfRounds) {
      for (let i = 1; i <= +battleData.NumberOfRounds; i++) {
        results[i] = null;
      }
    }

    setRoundVerdict(results);
    // setRoundVerdict(battleData?.battle_comments?.data?.[0]?.attributes?.verdict ?? results);
    // setVerdictId(battleData?.battle_comments?.data?.[0]?.id ?? null);
  }, [battleData, judgePanelType]);

  const checkIfReadyToSubmit = !Object.values(roundVerdict).filter((v) => v === null).length;

  const resetActiveButton = () => {
    setActiveButton(0);
  };

  const resetSelectedQueueBtn = () => {
    setSelectedQueueBtn(null);
  };

  return (
    <RoundVerdict.Provider
      value={{
        roundVerdict,
        setRoundVerdict,
        activeButton,
        setActiveButton,
        resetActiveButton,
        verdictId,
        setVerdictId,
        checkIfReadyToSubmit,
        selectedQueueBtn,
        setSelectedQueueBtn,
        resetSelectedQueueBtn,
      }}
    >
      {children}
    </RoundVerdict.Provider>
  );
};
