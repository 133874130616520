import { MinusIcon, PlusIcon } from 'assets/icons';
import { theme } from 'assets/styles/theme';
import classNames from 'classnames';
import { BtnGroup, BtnGroupLabel, Button, FlexContainer, IconButton, TriviumPanelStyles, SliderRange, SliderRangeStyles } from 'components';
import { themeModes } from 'config/themeModes';
import { useButtonHandler, useClickHandlers } from 'hooks';
import { useCompetitorsQueue } from 'hooks/useCompetitorsQueue';
import { ThemeVariantTypes } from 'interfaces/ThemeVariantTypes';
import { useAppContext } from 'providers/AppProvider';
import { useNotificationProvider } from 'providers/NotificationProvider';
import { FC, useEffect } from 'react';
import { JudgePanel } from 'components/JudgePanel/JudgePanel';
import { competitorSides } from 'config/competitorSides';

type TriviumPanelProps = JudgePanel & {
  isSingleBattle?: boolean;
};

export const TriviumPanel: FC<TriviumPanelProps> = ({ roundCounter = null, videoSection = null, leftColor = competitorSides.RED }) => {
  const { themeMode, setThemeMode } = useAppContext();
  const { dispatchNotification } = useNotificationProvider();
  const { buttonHandler } = useButtonHandler();
  const queue = useCompetitorsQueue();
  const getBtnColor = (themeVariant = themeModes.neutral) => (themeMode === themeVariant ? theme[themeVariant] : '');

  const crashBtnHandler = useClickHandlers(
    () => {
      buttonHandler({
        buttonName: 'crash',
        action: 'subtract',
        clickDetail: 'single',
      });
      dispatchNotification('slip');
    },
    () => {
      buttonHandler({
        buttonName: 'crash',
        action: 'subtract',
        clickDetail: 'double',
      });
      dispatchNotification('crash');
    },
    () => {
      buttonHandler({
        buttonName: 'crash',
        action: 'subtract',
        clickDetail: 'triple',
      });
      dispatchNotification('wipe-out');
    },
  );

  const misbehaviorBtnHandler = useClickHandlers(
    () => {
      buttonHandler({
        buttonName: 'misbehavior',
        action: 'subtract',
        clickDetail: 'single',
      });
      dispatchNotification('mild');
    },
    () => {
      buttonHandler({
        buttonName: 'misbehavior',
        action: 'subtract',
        clickDetail: 'double',
      });
      dispatchNotification('moderate');
    },
    () => {
      buttonHandler({
        buttonName: 'misbehavior',
        action: 'subtract',
        clickDetail: 'triple',
      });
      dispatchNotification('severe');
    },
  );

  useEffect(() => {
    setThemeMode(themeModes[queue[0] + 'Side'] as ThemeVariantTypes);
  }, [queue, setThemeMode]);

  return (
    <>
      {videoSection}

      <TriviumPanelStyles
        id='judge-panel'
        leftColor={leftColor}
        className={classNames('trivium-panel', {
          'theme-blue': themeMode === 'blueSide',
          'theme-red': themeMode === 'redSide',
        })}
      >
        {roundCounter}

        <div className='slider-wrapper slider1'>
          <SliderRangeStyles leftColor={leftColor}>
            <SliderRange label='technique' maxRange={10} minRange={-10} fixedNumber={2} leftColor={leftColor} />
            <SliderRange label='variety' position='bottom' maxRange={6.666} minRange={-6.666} fixedNumber={2} leftColor={leftColor} />
          </SliderRangeStyles>
        </div>

        <Button
          className={leftColor === competitorSides.BLUE ? 'rightSideBtn' : 'leftSideBtn'}
          color={getBtnColor(themeModes.redSide)}
          onClick={() => setThemeMode(themeModes.redSide as ThemeVariantTypes)}
        >
          Red Side
        </Button>

        <div className='crashGroupBtn nested-grid-container'>
          <BtnGroup className='execution'>
            <BtnGroupLabel>Execution</BtnGroupLabel>
            <FlexContainer>
              <IconButton onClick={() => buttonHandler({ buttonName: 'execution', action: 'add' })}>
                <PlusIcon />
              </IconButton>

              <IconButton
                onClick={() =>
                  buttonHandler({
                    buttonName: 'execution',
                    action: 'subtract',
                  })
                }
              >
                <MinusIcon />
              </IconButton>
            </FlexContainer>
          </BtnGroup>

          <BtnGroup className='repeat'>
            <BtnGroupLabel>Repeat</BtnGroupLabel>
            <IconButton onClick={() => buttonHandler({ buttonName: 'repeat', action: 'subtract' })}>
              <MinusIcon />
            </IconButton>
          </BtnGroup>

          <BtnGroup className='crash'>
            <BtnGroupLabel>CRASH</BtnGroupLabel>
            <IconButton rounded onClick={crashBtnHandler}>
              <MinusIcon />
            </IconButton>
          </BtnGroup>

          <BtnGroup className='form'>
            <BtnGroupLabel>Form</BtnGroupLabel>
            <FlexContainer>
              <IconButton onClick={() => buttonHandler({ buttonName: 'form', action: 'add' })}>
                <PlusIcon />
              </IconButton>

              <IconButton onClick={() => buttonHandler({ buttonName: 'form', action: 'subtract' })}>
                <MinusIcon />
              </IconButton>
            </FlexContainer>
          </BtnGroup>

          <BtnGroup className='bite'>
            <BtnGroupLabel>Bite</BtnGroupLabel>
            <IconButton onClick={() => buttonHandler({ buttonName: 'bite', action: 'subtract' })}>
              <MinusIcon />
            </IconButton>
          </BtnGroup>
        </div>

        <Button
          className={leftColor === competitorSides.BLUE ? 'leftSideBtn' : 'rightSideBtn'}
          color={getBtnColor(themeModes.blueSide)}
          onClick={() => setThemeMode(themeModes.blueSide as ThemeVariantTypes)}
        >
          Blue Side
        </Button>

        <div className='slider-wrapper slider2'>
          <SliderRangeStyles leftColor={leftColor}>
            <SliderRange label='performance' maxRange={10} minRange={-10} fixedNumber={2} leftColor={leftColor} />
            <SliderRange label='musicality' position='bottom' maxRange={6.666} minRange={-6.666} fixedNumber={2} leftColor={leftColor} />
          </SliderRangeStyles>
        </div>

        <div className='misbehaviorGroupBtn nested-grid-container'>
          <BtnGroup className='misbehavior'>
            <BtnGroupLabel>Misbehavior</BtnGroupLabel>
            <IconButton rounded onClick={misbehaviorBtnHandler}>
              <MinusIcon />
            </IconButton>
          </BtnGroup>

          <BtnGroup className='confidence'>
            <BtnGroupLabel>Confidence</BtnGroupLabel>
            <FlexContainer>
              <IconButton onClick={() => buttonHandler({ buttonName: 'confidence', action: 'add' })}>
                <PlusIcon />
              </IconButton>

              <IconButton
                onClick={() =>
                  buttonHandler({
                    buttonName: 'confidence',
                    action: 'subtract',
                  })
                }
              >
                <MinusIcon />
              </IconButton>
            </FlexContainer>
          </BtnGroup>

          <BtnGroup className='spontaneity'>
            <BtnGroupLabel>Spontaneity</BtnGroupLabel>
            <FlexContainer>
              <IconButton onClick={() => buttonHandler({ buttonName: 'spontaneity', action: 'add' })}>
                <PlusIcon />
              </IconButton>

              <IconButton
                onClick={() =>
                  buttonHandler({
                    buttonName: 'spontaneity',
                    action: 'subtract',
                  })
                }
              >
                <MinusIcon />
              </IconButton>
            </FlexContainer>
          </BtnGroup>
        </div>

        <div className='slider-wrapper slider3'>
          <SliderRangeStyles leftColor={leftColor}>
            <SliderRange label='creativity' maxRange={10} minRange={-10} fixedNumber={2} leftColor={leftColor} />
            <SliderRange label='personality' position='bottom' maxRange={6.666} minRange={-6.666} fixedNumber={2} leftColor={leftColor} />
          </SliderRangeStyles>
        </div>
      </TriviumPanelStyles>
    </>
  );
};
