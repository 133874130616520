import { toast } from 'react-toastify';
import { Urls } from 'config/Urls';
import { copyLink } from 'utils';
import { UseModal, useConfirmModal, useUser } from 'hooks';
import { useDeleteCollectionMutation, useEditCollectionMutation } from 'app/services/collections';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { Collection } from 'interfaces/Collection';

export const useCollectionActions = () => {
  const { user } = useUser();
  const { openConfirmModal } = useConfirmModal();
  const [editCollectionApi] = useEditCollectionMutation();
  const [deleteCollectionApi] = useDeleteCollectionMutation();

  const copyCollectionLink = (collectionId: number) => {
    copyLink(collectionId, Urls.COLLECTIONS);
  };

  const deleteCollection = (collectionId: number, collectionName = '') => {
    openConfirmModal({
      title: 'Delete collection',
      content: (
        <div>
          You are going to permanently delete the collection <strong>{collectionName}</strong>. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          await deleteCollectionApi(collectionId);
          toast.success('Collection deleted');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const renameCollection = (modal: UseModal) => async (values: { name: string }) => {
    try {
      await editCollectionApi({
        id: modal.modalState.id,
        body: {
          name: values.name,
        },
      });
      toast.success('Collection renamed');
      modal.close();
    } catch (error) {
      console.error(error);
    }
  };

  const getCollectionEllipsisDropdownItems = (collectionData: StrapiWrapper<Collection>): ItemType[] => {
    return [
      {
        key: 'copy_collection_link',
        label: 'Copy link',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          copyCollectionLink(collectionData.id);
        },
      },
      ...((user?.email === collectionData.attributes.users_permissions_user.data.attributes.email
        ? [
            {
              key: 'rename_collection',
              label: 'Rename',
              onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                // TODO: update collection name here
              },
            },
            {
              key: 'delete_collection',
              label: 'Delete',
              onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                deleteCollection(collectionData.id, collectionData.attributes.name);
              },
            },
          ]
        : []) as ItemType[]),
    ];
  };

  return { copyCollectionLink, deleteCollection, renameCollection, getCollectionEllipsisDropdownItems };
};
