import { Battle } from './Battle';
import { Result } from './Result';
import { StrapiWrapper } from './StrapiWrapper';
import { TimeAttributes } from './TimeAttributes';
import { User } from './User';

export interface BattleComments {
  data: StrapiWrapper<BattleComment & TimeAttributes>[];
}

export interface BattleComment {
  id: string | number;
  content?: string;
  type: (typeof BattleCommentsTypes)[keyof typeof BattleCommentsTypes];
  verdict: { [key: string]: Result | null };
  date: string;
  battle: { data: StrapiWrapper<Battle> };
  public: boolean;
  users_permissions_user?: { data: StrapiWrapper<User> };
}

export interface BattleCommentCreate extends Omit<BattleComment, 'users_permissions_user' | 'id' | 'battle'> {
  battle: string | number;
  users_permissions_user?: string | number;
}

export type BattleCommentUpdate = Partial<BattleCommentCreate>;

export const BattleCommentsTypes = {
  TRIVIUM: 'trivium',
  THREEFOLD: 'threefold',
  CLASSIC: 'classic',
  TYPE_A: 'typeA',
  TYPE_B: 'typeB',
} as const;

export type BattleCommentsType = 'trivium' | 'threefold' | 'classic' | 'typeA' | 'typeB';

export const BattleCommentsNames = {
  [BattleCommentsTypes.TRIVIUM]: 'Trivium',
  [BattleCommentsTypes.THREEFOLD]: 'ThreeFold',
  [BattleCommentsTypes.CLASSIC]: 'Classic',
  [BattleCommentsTypes.TYPE_A]: 'Type A',
  [BattleCommentsTypes.TYPE_B]: 'Type B',
};

export const BattleCommentsViews = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const BattleCommentsViewNames = {
  [BattleCommentsViews.PUBLIC]: 'Public',
  [BattleCommentsViews.PRIVATE]: 'Private',
};
