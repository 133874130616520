/* eslint-disable no-case-declarations */
import { BattleCommentsTypes } from 'interfaces/BattleComment';
import { ScoreBoard, ScoreBoardTypes } from 'interfaces/ScoreBoard';
import { ThemeVariantTypes } from 'interfaces/ThemeVariantTypes';
import { resultsInitState } from 'providers/ResultProvider';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export interface ReducerAction<SliderRangeTypes> {
  payload: {
    team?: ThemeVariantTypes;
    value: number;
    type?: SliderRangeTypes;
    types?: SliderRangeTypes[];
    boardResult?: ScoreBoardTypes;
  };
  type: string;
}

export const resultsReducer =
  <SliderRangeTypes,>(judgePanelType: typeof BattleCommentsTypes[keyof typeof BattleCommentsTypes], defaultPayloadType = 'technique') =>
  (state: ScoreBoard, { payload, type }: ReducerAction<SliderRangeTypes>) => {
    const operation = payload.team === 'blueSide';
    const payloadType = payload.type || defaultPayloadType;
    // TODO @ek types
    const stateForJudgingPanel = state[judgePanelType];

    switch (type) {
      case 'addMany':
        const addArr = payload.types || [];
        const addRes = addArr.reduce(
          (p: { [key: string]: number }, n: SliderRangeTypes) => ({
            ...p,
            [`${n}`]: operation ? stateForJudgingPanel[n] + payload.value : stateForJudgingPanel[n] - payload.value,
          }),
          {},
        );
        return {
          ...state,
          [judgePanelType]: { ...stateForJudgingPanel, ...addRes },
        };

      case 'subtractMany':
        const subArr = payload.types || [];
        const subRes = subArr.reduce(
          (p: { [key: string]: number }, n: SliderRangeTypes) => ({
            ...p,
            [`${n}`]: operation ? stateForJudgingPanel[n] - payload.value : stateForJudgingPanel[n] + payload.value,
          }),
          {},
        );
        return {
          ...state,
          [judgePanelType]: { ...stateForJudgingPanel, ...subRes },
        };

      case 'set' + capitalizeFirstLetter(payloadType as string):
        return {
          ...state,
          [judgePanelType]: { ...stateForJudgingPanel, [`${payloadType}`]: payload.value },
        };

      case 'add' + capitalizeFirstLetter(payloadType as string):
        return {
          ...state,
          [judgePanelType]: {
            ...stateForJudgingPanel,
            [`${payloadType}`]: operation ? stateForJudgingPanel[payloadType] + payload.value : stateForJudgingPanel[payloadType] - payload.value,
          },
        };

      case 'subtract' + capitalizeFirstLetter(payloadType as string):
        return {
          ...state,
          [judgePanelType]: {
            ...stateForJudgingPanel,
            [`${payloadType}`]: operation ? stateForJudgingPanel[payloadType] - payload.value : stateForJudgingPanel[payloadType] + payload.value,
          },
        };

      case 'reset':
        return resultsInitState;

      case 'setBoard':
        return {
          ...state,
          [judgePanelType]: { ...stateForJudgingPanel, ...payload.boardResult },
        };

      default:
        return state;
    }
  };
