import { CSSProperties, FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

type TooltipWithHideProps = {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  hidden?: boolean;
  title?: ReactNode;
  placement?: TooltipPlacement;
};

export const TooltipWithHide: FC<TooltipWithHideProps> = ({ style = {}, className = '', children, hidden = false, title = '', placement = 'bottom' }) => {
  return (
    <Tooltip
      placement={placement}
      overlayStyle={style}
      overlayClassName={classNames(className, {
        hidden: hidden,
      })}
      title={title}
    >
      {children}
    </Tooltip>
  );
};
