import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { BlueFlag, RedFlag } from 'assets/icons';
import {UNKNOWN} from 'config/Consts';
import { getUsernameFirstLetter } from 'utils';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FlexContainer, TextWithTooltip } from 'components';

type AvatarSide = 'red' | 'blue' | 'yellow';

const avatarIcon: Record<AvatarSide, ReactNode> = {
  red: <RedFlag />,
  blue: <BlueFlag />,
  yellow: <UserOutlined />,
};

type AvatarWithDescriptionProps = {
  className?: string;
  side: AvatarSide;
  src?: string;
  description?: string;
  onClick?: () => void;
};

export const AvatarWithDescription: FC<AvatarWithDescriptionProps> = ({ className = '', description, side, src = '', onClick }) => {
  const descriptionToDisplay = description ?? UNKNOWN;

  return (
    <FlexContainer
      alignItems='center'
      className={classNames(className, {
        'cursor-pointer': onClick,
      })}
      onClick={onClick}
    >
      <Avatar
        className={classNames(`border border-${side}`)}
        {...(src && { src })}
        shape='square'
        size='large'
        {...(side !== 'yellow' && { icon: avatarIcon[side] })}
      >
        {side === 'yellow' && getUsernameFirstLetter(descriptionToDisplay)}
      </Avatar>

      <TextWithTooltip
        className={classNames('h-24px ml-8 text-ellipsis text-wrap clamp clamp-1', {
          'text-gray': !description,
        })}
        text={descriptionToDisplay}
      />
    </FlexContainer>
  );
};
