import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { WipeOut, WipeOutStyles } from 'components';
import 'react-toastify/dist/ReactToastify.css';

type NotificationContextType = {
  notification: string;
  dispatchNotification: (message: string, delay?: number) => void;
};

const NotificationContext = createContext({} as NotificationContextType);

type NotificationProviderProps = {
  children: ReactNode;
};

const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const [notification, setNotification] = useState('');

  const dispatchNotification = useCallback((message: string, delay = 800) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, delay);
  }, []);

  return (
    <NotificationContext.Provider value={{ notification, dispatchNotification }}>
      <ToastContainer
        position='bottom-right'
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // icon={false}
        closeButton={false}
        pauseOnFocusLoss={false}
        pauseOnHover
        theme='colored'
      />
      {notification && (
        <WipeOutStyles>
          <WipeOut message={notification} />
        </WipeOutStyles>
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

export const useNotificationProvider = () => {
  const notificationCtx = useContext(NotificationContext);

  if (notificationCtx === undefined) {
    throw Error('useNotificationProvider must be used within a NotificationProvider');
  }

  return notificationCtx;
};
