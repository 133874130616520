import { api } from 'app/api';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'config/Consts';
import { Endpoints } from 'config/Urls';
import { EventCategoryNames } from 'interfaces/EventCategory';
import { CreateEventElimination, EventElimination } from 'interfaces/EventElimination';
import { CreateEventEliminationSettings, EditEventEliminationSettings, EventEliminationSettings } from 'interfaces/EventEliminationSettings';
import { CreateEventEliminationVerdict, EventEliminationVerdict } from 'interfaces/EventEliminationVerdict';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { PaginationParams } from 'providers/PaginationProvider';
import { SortByOptions } from 'types/SortTypes';

const ELIMINATION_POPULATE_PARAM =
  'populate[competitors]=elimination&populate[event][populate][elimination][eliminationSetting][eliminationVerdict][competitors][populate]=judges&populate[event][populate][elimination][eliminationSetting][populate]=judges&populate[event][populate][elimination][eliminationVerdict][populate]=competitors&populate[event][populate][elimination][eliminationVerdict][populate][competitors]=judges&populate[eliminationSetting][populate]=qualifiedCompetitors&populate[eliminationSetting][populate]=judges&populate[event][populate]=logo';

const ELIMINATION_SETTINGS_POPULATE_PARAM =
  'populate[event][populate][elimination][eliminationSetting][populate]=event&populate[eliminationSetting][populate]=elimination&populate[judges]=1&populate[competitors]=1&populate[qualifiedCompetitors][populate]=qualifiedEliminations';

const ELIMINATION_VERDICT_POPULATE_PARAM =
  'populate[event][populate][elimination][eliminationVerdicts][populate]=event&populate[eliminationVerdicts][populate]=elimination&populate[judge]=1&populate[competitor]=1&populate[elimination]=1';

export const EliminationApi = api.injectEndpoints({
  endpoints: (build) => ({
    addEliminationSettings: build.mutation<
      {
        data: StrapiWrapper<EventEliminationSettings>;
      },
      CreateEventEliminationSettings
    >({
      query: (body) => ({
        url: `${Endpoints.ELIMINATION_SETTINGS}?${ELIMINATION_SETTINGS_POPULATE_PARAM}`,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { elimination }) => [{ type: 'Elimination', id: elimination }],
    }),

    addEliminationVerdict: build.mutation<
      {
        data: StrapiWrapper<EventEliminationVerdict>;
      },
      CreateEventEliminationVerdict
    >({
      query: (body) => ({
        url: `${Endpoints.ELIMINATION_VERDICTS}?${ELIMINATION_VERDICT_POPULATE_PARAM}`,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { elimination }) => [{ type: 'Elimination', id: elimination }],
    }),

    getEliminationVerdictsList: build.query<StrapiWrapper<EventEliminationVerdict>[], { judgeId?: string; eliminationId?: string }>({
      query: ({ judgeId, eliminationId }) => {
        let queryString = `${Endpoints.ELIMINATION_VERDICTS}?${ELIMINATION_VERDICT_POPULATE_PARAM}&pagination[pageSize]=1000`;

        if (judgeId) {
          queryString += `&filters[judge][id]=${judgeId}`;
        }

        if (eliminationId) {
          queryString += `&filters[elimination][id]=${eliminationId}`;
        }

        return queryString;
      },
      transformResponse(response: { data: StrapiWrapper<EventEliminationVerdict>[] }) {
        return response?.data;
      },
    }),

    checkEliminationClosed: build.query<string, { eliminationId?: string }>({
      query: ({ eliminationId }) => {
        return `${Endpoints.ELIMINATIONS}/${eliminationId}?${ELIMINATION_POPULATE_PARAM}`;
      },
      transformResponse(response: { data: StrapiWrapper<EventElimination> }) {
        return response?.data.attributes.status;
      },
    }),

    editEliminationSettings: build.mutation<void, { id: number; body: Partial<EditEventEliminationSettings> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.ELIMINATION_SETTINGS}/${id}?${ELIMINATION_SETTINGS_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { body: { elimination } }) => [{ type: 'Elimination', id: elimination }],
    }),

    editEliminationVerdict: build.mutation<void, { id: number; body: Partial<CreateEventEliminationVerdict> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.ELIMINATION_VERDICTS}/${id}?${ELIMINATION_VERDICT_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { body: { elimination } }) => [{ type: 'Elimination', id: elimination }],
    }),

    deleteEliminationVerdict: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `${Endpoints.ELIMINATION_VERDICTS}/${id}?${ELIMINATION_VERDICT_POPULATE_PARAM}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => [{ type: 'Elimination' }],
    }),

    getEventEliminationList: build.query<
      { eliminationList: StrapiWrapper<EventElimination & { type?: EventCategoryNames }>[]; meta: StrapiMeta },
      PaginationParams & { isAdmin: boolean; eventId?: string; userId?: number; sortBy: SortByOptions; sortOrder: 'asc' | 'desc' }
    >({
      query: ({ isAdmin, eventId = '', userId, searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, sortBy, sortOrder }) =>
        isAdmin
          ? `${Endpoints.ELIMINATIONS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[event][id]=${eventId}&sort=${sortBy}:${sortOrder}?${ELIMINATION_SETTINGS_POPULATE_PARAM}`
          : `${Endpoints.ELIMINATIONS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[event][id]=${eventId}&filters[eliminationSetting][judges][id]=${userId}`,
      providesTags: () => [{ type: 'Eliminations', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<EventElimination>[]; meta: StrapiMeta }) {
        const mappedEliminationList = response?.data.map((category) => ({
          ...category,
          attributes: { ...category.attributes, type: EventCategoryNames.ELIMINATIONS },
        }));

        return { eliminationList: mappedEliminationList, meta: response?.meta };
      },
    }),

    getEventElimination: build.query<StrapiWrapper<EventElimination>, { judgeId?: string; categoryId?: string; showVerdicts?: boolean }>({
      query: ({ categoryId, showVerdicts }) => {
        let queryString = `${Endpoints.ELIMINATIONS}/${categoryId}?${ELIMINATION_POPULATE_PARAM}`;

        if (showVerdicts) {
          queryString += `&populate[eliminationVerdicts][populate]=judge,competitor,elimination`;
        }

        return queryString;
      },
      providesTags: (result, error, { categoryId }) => [{ type: 'Elimination', categoryId }],
      transformResponse(response: { data: StrapiWrapper<EventElimination> }, meta, { showVerdicts, judgeId }) {
        if (response?.data?.attributes?.eliminationVerdicts?.data?.length && showVerdicts && judgeId) {
          response.data.attributes.eliminationVerdicts.data = response.data.attributes.eliminationVerdicts.data.filter(
            (verdict) => verdict.attributes.judge.data.id === +judgeId,
          );
        }

        return response?.data;
      },
    }),

    addElimination: build.mutation<void, CreateEventElimination>({
      query: (body) => ({
        url: `${Endpoints.ELIMINATIONS}?${ELIMINATION_POPULATE_PARAM}`,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'Eliminations', id: 'LIST' }],
    }),

    deleteEventElimination: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.ELIMINATIONS}/${id}?${ELIMINATION_POPULATE_PARAM}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Eliminations', id: 'LIST' }],
    }),

    editEventElimination: build.mutation<void, { id: number; body: Partial<CreateEventElimination> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.ELIMINATIONS}/${id}?${ELIMINATION_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Elimination', id },
        { type: 'Eliminations', id: 'LIST' },
        { type: 'EliminationCompetitors', id: 'LIST' },
      ],
    }),
  }),
});
export const {
  useAddEliminationSettingsMutation,
  useAddEliminationVerdictMutation,
  useEditEliminationSettingsMutation,
  useEditEliminationVerdictMutation,
  useDeleteEliminationVerdictMutation,
  useGetEliminationVerdictsListQuery,
  useGetEventEliminationListQuery,
  useGetEventEliminationQuery,
  useLazyCheckEliminationClosedQuery,
  useAddEliminationMutation,
  useDeleteEventEliminationMutation,
  useEditEventEliminationMutation,
} = EliminationApi;
