import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { getDefaultCounterText } from 'utils';
import imgPlaceholder from 'assets/icons/img-placeholder.svg';
import { Image } from 'antd';
import { ImageWithCounterStyles } from './ImageWithCounter.styles';

type ImageWithCounterProps = {
  className?: string;
  counterLabel?: string;
  counterValue?: number;
  counterContent?: ReactNode;
  imgUrl: string;
  onClick?: () => void;
};

export const ImageWithCounter: FC<ImageWithCounterProps> = ({
  className = '',
  counterLabel = '',
  counterValue = 0,
  counterContent = null,
  imgUrl = imgPlaceholder,
  onClick,
}) => {
  return (
    <ImageWithCounterStyles
      className={classNames(className, {
        'cursor-pointer': onClick,
      })}
      onClick={onClick}
    >
      <Image
        className={classNames('img-rounded img-cover', className, {
          'cursor-pointer': onClick,
        })}
        preview={false}
        src={imgUrl || imgPlaceholder}
      />
      {counterContent ? (
        <div
          className={classNames('font-14 font-bold img-counter', className, {
            'cursor-pointer': onClick,
          })}
        >
          {counterContent}
        </div>
      ) : (
        <>
          {counterValue > 0 && (
            <div
              className={classNames('font-14 font-bold img-counter', className, {
                'cursor-pointer': onClick,
              })}
            >
              {getDefaultCounterText(counterValue, counterLabel)}
            </div>
          )}
        </>
      )}
    </ImageWithCounterStyles>
  );
};
