import { CompetitorSide } from 'interfaces/CompetitorSide';
import { usePrepareScore } from './usePrepareScore';

export const useCompetitorScore = (side: CompetitorSide) => {
  const resultToDisplay = usePrepareScore();

  // const score = +Number(resultToDisplay[side]) < 0 ? '0.00' : +Number(resultToDisplay[side]) > 100 ? '100.00' : +Number(resultToDisplay[side]).toFixed(3);
  const resultScore = +Number(resultToDisplay[side]);
  return parseFloat('' + (resultScore > 0 && resultScore < 100 ? resultScore : resultScore <= 0 ? 0 : 100)).toFixed(2);
};
