import { DefaultTheme } from 'styled-components';

export const defaultColors = {
  redSide: '#881d25',
  blueSide: '#2658A3',
  neutral: '#FFFFFF1A',
  // figma color palette - start
  black100: '#2A282D',
  black200: '#29262C',
  black300: '#201D23',
  black400: '#201d23cc',
  black500: '#000000',

  gray100: '#8C8C8C',
  gray200: '#FFFFFF1A',
  gray300: '#424044',
  gray400: '#363439',

  white100: '#FFFFFF',
  white200: '#FAFAFA',
  white300: '#E8E8E8',

  red100: '#EB441D',
  red200: '#F5222D',
  red300: '#F5222D80',
  red400: '#881d25',

  orange100: '#cb632c',

  green100: '#5B8C00',
  green200: '#254000',
  green300: '#93ff00',

  blue100: '#1890FF',
  blue200: '#1890FF80',
  blue300: '#2658A3',
  blue400: '#1a548e',

  yellow100: '#FFF566',
  // figma color palette - end
};

export const theme: DefaultTheme = {
  ...defaultColors,
};
