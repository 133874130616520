import { api } from 'app/api';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'config/Consts';
import { Endpoints } from 'config/Urls';
import { BattlesListItem } from 'interfaces/BattlesListItem';
import { Collection, CreateCollection, EditCollection } from 'interfaces/Collection';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { PaginationParams } from 'providers/PaginationProvider';
import { SortByOptions } from 'types/SortTypes';

export const collectionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserCollections: build.query<{ collection: StrapiWrapper<Collection>[]; meta: StrapiMeta }, PaginationParams & { userId?: number }>({
      query: ({ searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, userId }) =>
        `${Endpoints.COLLECTIONS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=users_permissions_user&filters[users_permissions_user][$and][0][id]=${userId}&filters[name][$containsi]=${searchQuery}&populate=battles`,
      providesTags: [{ type: 'Collection', id: 'USER_PLAYLISTS' }],
      transformResponse(response: { data: StrapiWrapper<Collection>[]; meta: StrapiMeta }) {
        return { collection: response?.data, meta: response?.meta };
      },
    }),

    getCollection: build.query<{ collection: StrapiWrapper<Collection>; meta: StrapiMeta }, string | undefined>({
      query: (id) => `${Endpoints.COLLECTIONS}/${id}?populate=users_permissions_user`,
      providesTags: (result, error, id) => [{ type: 'Collection', id }],
      transformResponse(response: { data: StrapiWrapper<Collection>; meta: StrapiMeta }) {
        return { collection: response?.data, meta: response?.meta };
      },
    }),

    getCollectionBattles: build.query<{ collectionBattles: BattlesListItem[]; meta: StrapiMeta }, PaginationParams & { id?: number }>({
      query: ({ searchQuery = '', page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, id }) =>
        `${Endpoints.BATTLES}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[Name][$containsi]=${searchQuery}&populate[RedSide][fields]=id,Name,Type&populate[BlueSide][fields]=id,Name,Type&populate[collections][filters][id]=${id}&filters[collections][$and][0][id]=${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Collection', id }],
      transformResponse(response: { data: BattlesListItem[]; meta: StrapiMeta }) {
        return { collectionBattles: response?.data, meta: response?.meta };
      },
    }),

    getAllCollections: build.query<
      { collection: StrapiWrapper<Collection>[]; meta: StrapiMeta },
      PaginationParams & { sortBy: SortByOptions; sortOrder: 'asc' | 'desc' }
    >({
      query: ({ searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, sortBy, sortOrder }) =>
        `${Endpoints.COLLECTIONS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=users_permissions_user&filters[name][$containsi]=${searchQuery}&filters[public]=true&populate=battles&sort=${sortBy}:${sortOrder}`,
      providesTags: [{ type: 'Collection', id: 'ALL_PLAYLISTS' }],
      transformResponse(response: { data: StrapiWrapper<Collection>[]; meta: StrapiMeta }) {
        return { collection: response?.data, meta: response?.meta };
      },
    }),

    addCollection: build.mutation<{ data: StrapiWrapper<Collection> }, CreateCollection>({
      query: (body) => ({
        url: Endpoints.COLLECTIONS,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'Collection', id: 'ALL_PLAYLISTS' }],
    }),

    editCollection: build.mutation<void, { id: number; body: EditCollection }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.COLLECTIONS}/${id}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Collection', id: 'ALL_PLAYLISTS' },
        { type: 'Collection', id },
      ],
    }),

    deleteCollection: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.COLLECTIONS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Collection', id: 'ALL_PLAYLISTS' }],
    }),
  }),
});

export const {
  useGetUserCollectionsQuery,
  useGetCollectionQuery,
  useGetCollectionBattlesQuery,
  useGetAllCollectionsQuery,
  useAddCollectionMutation,
  useEditCollectionMutation,
  useDeleteCollectionMutation,
} = collectionApi;
