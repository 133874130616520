import styled from 'styled-components';

export const ValidationErrorMessage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: right;
  color: ${({ theme }) => theme.red100};
`;
