import { Empty } from 'antd';
import classNames from 'classnames';
import { FlexContainer } from 'components';
import { LOADING, NO_DATA } from 'config/Consts';
import { FC, ReactNode } from 'react';

type NoDataProps = {
  description?: ReactNode;
  image?: ReactNode;
  isLoading?: boolean;
  className?: string;
};

export const NoData: FC<NoDataProps> = ({ description = NO_DATA, image = Empty.PRESENTED_IMAGE_SIMPLE, isLoading = false, className }) => {
  return (
    <FlexContainer justifyContent='center' alignItems='center' className={classNames('h-100', className)}>
      <Empty image={image} description={<div className='font-20 text-center'>{isLoading ? LOADING : description}</div>} />
    </FlexContainer>
  );
};
