import { CSSProperties, FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { UseModal } from 'hooks';
import { Modal as AntdModal } from 'antd';
import { ButtonType } from 'antd/lib/button/button';
import styled from 'styled-components';
import { defaultColors } from 'assets/styles/theme';
import Button from 'antd/es/button';
import { CloseOutlined } from '@ant-design/icons';
import SpinnerButton from 'components/Spinner/SpinnerButton/SpinnerButton';

type ModalProps = CommonProps & ConditionalProps;

type CommonProps = {
  children: ReactNode;
  modal: UseModal;
  className?: string;
  title?: ReactNode;
  closeIcon?: ReactNode;
  style?: CSSProperties;
  width?: string | number;
  okText?: string;
  cancelText?: string;
  okType?: ButtonType;
  footer?: ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};

// TODO: fix this prop to be conditional (e.g. if byPortal is true, then do not allow to use maskClosable and closable)
type ConditionalProps =
  | {
      byPortal?: true; // ? Created for backward compatibility reasons.
      okDisabled?: boolean;
      okLoading?: boolean;
      maskClosable?: never;
      closable?: never;
    }
  | {
      byPortal?: false;
      okDisabled?: never;
      okLoading?: never;
      maskClosable?: boolean;
      closable?: boolean;
    };

// Values in these styled components were based mostly on <Modal/> component from antd library.
const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 33vw;
  min-height: 20vh;
  background: ${defaultColors.black300};
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 24px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const ModalHeader = styled.div`
  display: flex;
  color: #fafafa;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  word-wrap: break-word;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  gap: 1rem;
`;

const ModalFooter = styled.div`
  text-align: right;
  background: transparent;
  border-top: 1px solid transparent;
  border-radius: 0 0 2px 2px;
`;

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  gap: 1rem;
`;

const CloseIcon = styled.span`
  cursor: pointer;
`;

/**
 * Modal component that can be used to display a modal dialog.
 * @param {ModalProps} props - The props for the Modal component.
 * @returns {JSX.Element} The rendered Modal component.
 */
export const Modal = (props: ModalProps) => {
  // * For each new <Modal/>, use byPortal.
  // * Modals that were created before this prop was a thing are not using this prop, and there is no known reasons (besides the fact that they tend to be buggy due to z-index related things) to refactor them.
  // ? Portal as a solution is used to prevent modals children from being displayed behind modal, due to fact that AntdModal uses z-index-based styling to display modals and their content...

  const {
    children,
    modal,
    className = '',
    title = '',
    closeIcon,
    footer,
    width,
    cancelText = 'CANCEL',
    okText = 'OK',
    okType = 'primary',
    onCancel,
    onClose,
    onOk,
    style,
  }: CommonProps = props;

  if ('byPortal' in props) {
    const { byPortal, okDisabled, okLoading }: ConditionalProps = props;

    const handleClose = () => {
      onClose?.();
      modal?.close();
    };
    const handleCancel = () => {
      onCancel?.();
      modal?.close();
    };
    const handleOk = () => {
      onOk?.();
      modal?.close();
    };

    if (byPortal && modal.isOpen)
      return (
        <>
          {createPortal(
            <ModalBackground>
              <ModalContainer className={className}>
                <ModalHeader>
                  {title}
                  <CloseIcon onClick={handleClose}>{closeIcon || <CloseOutlined />}</CloseIcon>
                </ModalHeader>
                <ModalMain>{children}</ModalMain>

                <ModalFooter>
                  {footer || (
                    <ButtonContainer>
                      <Button onClick={handleCancel} type='default'>
                        {cancelText}
                      </Button>
                      <SpinnerButton label={okText} loading={okLoading} disabled={okDisabled} onClick={handleOk} type={okType} />
                    </ButtonContainer>
                  )}
                </ModalFooter>
              </ModalContainer>
            </ModalBackground>,
            document.body,
          )}
        </>
      );
  }

  const { closable = true, maskClosable = false }: ConditionalProps = props;

  return (
    <AntdModal
      style={{ ...style }}
      className={className}
      centered
      title={title}
      closeIcon={closeIcon}
      closable={closable}
      open={modal?.isOpen}
      footer={footer}
      width={width}
      okType={okType}
      okText={okText}
      cancelText={cancelText}
      maskClosable={maskClosable}
      destroyOnClose
      onOk={() => {
        onOk?.();
        modal?.close();
      }}
      onCancel={() => {
        onCancel?.();
        modal?.close();
      }}
      afterClose={() => {
        onClose?.();
      }}
    >
      {children}
    </AntdModal>
  );
};
