import { UserOutlined } from '@ant-design/icons';
import { Drawer, Tooltip } from 'antd';
import { FlexContainer } from 'components';
import UserProfile from 'components/UserProfile/UserProfile';
import { useModal } from 'hooks';
import { useMenuCollapsedProvider } from 'providers/MenuCollapsed';
import { FC } from 'react';
import { SiderFooterStyles } from './SiderFooterStyles';

const SiderFooter: FC = () => {
  const { isOpen, open, close } = useModal();
  const { sidebarCollapsed } = useMenuCollapsedProvider();

  return (
    <SiderFooterStyles className='mt-a'>
      <Drawer title='My account' placement='right' destroyOnClose open={isOpen} onClose={close}>
        <UserProfile />
      </Drawer>

      <FlexContainer justifyContent={sidebarCollapsed ? 'center' : 'flex-end'} alignItems='center'>
        <Tooltip title='My account'>
          <UserOutlined className='cursor-pointer' onClick={open} />
        </Tooltip>
      </FlexContainer>
    </SiderFooterStyles>
  );
};

export default SiderFooter;
