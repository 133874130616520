import { useWindowWidth } from '@react-hook/window-size';
import { theme } from 'assets/styles/theme';
import { useCurrentJudgePanel } from 'hooks/useCurrentJudgePanel';
import {
  SliderRangeClassicTypes,
  SliderRangeThreefoldTypes,
  SliderRangeTriviumTypes,
  SliderRangeTypeATypes,
  SliderRangeTypeBTypes,
} from 'interfaces/SliderRangeTypes';
import { useAppContext } from 'providers/AppProvider';
import { useMenuCollapsedProvider } from 'providers/MenuCollapsed';
import { useResultProvider } from 'providers/ResultProvider';
import { ChangeEvent, FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { capitalizeFirstLetter } from 'utils';
import { CompetitorSide } from 'interfaces/CompetitorSide';
import { competitorSides } from 'config/competitorSides';

interface SliderRangeProps {
  label: SliderRangeTriviumTypes | SliderRangeThreefoldTypes | SliderRangeClassicTypes | SliderRangeTypeATypes | SliderRangeTypeBTypes;
  fixedNumber?: number;
  initValue?: number;
  minRange?: number;
  maxRange?: number;
  sliderStep?: number | 'any';
  position?: 'top' | 'bottom';
  leftColor?: CompetitorSide;
}

const INIT_VALUE = 0;
const FIXED_NUMBER = 0;
const SLIDER_STEP = 'any';
const MIN_RANGE = 0;
const MAX_RANGE = 100;
const SCALE_NUMBER = 41;

const parseNumber = (val: number, fixedNumber: number) => +Number(val).toFixed(fixedNumber);

export const SliderRange: FC<SliderRangeProps> = (props) => {
  const {
    label,
    fixedNumber = FIXED_NUMBER,
    initValue = INIT_VALUE,
    minRange = MIN_RANGE,
    maxRange = MAX_RANGE,
    sliderStep = SLIDER_STEP,
    position = 'top',
    leftColor = competitorSides.RED,
  } = props;
  const rightColor: CompetitorSide = leftColor === competitorSides.RED ? competitorSides.BLUE : competitorSides.RED;

  const ref = useRef<HTMLInputElement | null>(null);
  const { themeMode } = useAppContext();
  const { setResult } = useResultProvider();
  const [, setRangeReady] = useState(false);
  const { sidebarCollapsed } = useMenuCollapsedProvider();
  const [, setState] = useState(0);
  const width = useWindowWidth();
  const { currentJudgePanelConfig, resultForCurrentJudgePanel } = useCurrentJudgePanel();
  const sliderLabelValue = resultForCurrentJudgePanel[label];

  const setRange = useCallback(
    (val: number) => {
      if (themeMode === 'neutral') {
        return;
      }

      setResult({
        type: 'set' + capitalizeFirstLetter(label),
        payload: { type: label, team: themeMode, value: parseNumber(+val, fixedNumber) },
      });
    },
    [fixedNumber, label, setResult, themeMode],
  );

  const getThumbPosition = () => {
    if (ref?.current) {
      return ((sliderLabelValue - minRange) / (maxRange - minRange)) * ref.current.getBoundingClientRect()?.width;
    }
  };

  useLayoutEffect(() => {
    setRangeReady(true);
  }, []);

  useEffect(() => {
    const sliderConfigValue = currentJudgePanelConfig[label];

    if (sliderLabelValue > sliderConfigValue) {
      setRange(sliderConfigValue);
    }

    if (sliderLabelValue < -sliderConfigValue) {
      setRange(-sliderConfigValue);
    }

    // if (sliderLabelValue > currentJudgePanelConfig) {
    //   setRange(currentJudgePanelConfig);
    // }

    // if (sliderLabelValue < -currentJudgePanelConfig) {
    //   setRange(-currentJudgePanelConfig);
    // }
  }, [currentJudgePanelConfig, label, sliderLabelValue, setRange]);

  const sliderBackground = `linear-gradient(to ${leftColor === competitorSides.RED ? 'right' : 'left'}, 
    ${theme.blueSide} 0%, 
    ${theme.blueSide} ${getThumbPosition()}px, 
    ${theme.redSide} ${getThumbPosition()}px, 
    ${theme.redSide} 100%)`;

  const classes = `slider-range ${sliderLabelValue <= minRange ? 'minus' : ''} ${sliderLabelValue >= maxRange ? 'plus' : ''} ${
    sliderLabelValue === initValue ? 'normal' : ''
  }`;

  useEffect(() => {
    setTimeout(() => {
      setState(Math.random());
    }, 200);
  }, [sidebarCollapsed, width]);

  return (
    <>
      <label className={position} htmlFor={`id_${label}`}>
        {capitalizeFirstLetter(label.replace(/([A-Z])/g, ' $1'))}
      </label>

      <input
        ref={ref}
        style={{ background: sliderBackground, direction: leftColor === competitorSides.RED ? 'ltr' : 'rtl' }}
        className={classes}
        type='range'
        min={minRange}
        max={maxRange}
        value={sliderLabelValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setRange(+e.target.value)}
        id={`id_${label}`}
        step={sliderStep}
        list={`scale_${label}`}
      />

      <div id={`scale_${label}`} className='datalist'>
        {[...Array(SCALE_NUMBER)].map((_, i) => (
          <div key={i} className={`option ${i % 5 === 0 ? 'highlight' : ''}`}>
            |
          </div>
        ))}
      </div>
    </>
  );
};
