import styled from 'styled-components';

export const ImageWithCounterStyles = styled.div`
  position: relative;
  width: 100%;

  .ant-image {
    width: 100%;

    img {
      height: 150px;
    }
  }

  .img-counter {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    border-radius: 4px;
    background: ${({ theme }) => theme.black300 + '90'};
    text-align: center;
    overflow-wrap: break-word;
  }
`;
