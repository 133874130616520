import classNames from 'classnames';
import { FC } from 'react';

interface AppMainLogoProps {
  className?: string;
}

export const AppMainLogo: FC<AppMainLogoProps> = ({ className }) => {
  return <img className={classNames('h-40px', className)} alt='logo' src={'/images/logo/main_white.svg'} />;
};
