import { FC } from 'react';
import { useCompetitorScore } from 'hooks';
import { CompetitorSide } from 'interfaces/CompetitorSide';
import { ScoreBoardStyles } from 'pages/Battle/VideoSection/ScoreBoard.styles';
import { FlexContainer } from 'components';
import ScoreCompetitor from './ScoreCompetitor';

interface ScoreBoardProps {
  className?: string;
  side: CompetitorSide;
}

export const ScoreBoard: FC<ScoreBoardProps> = ({ className, side }) => {
  const score = useCompetitorScore(side);

  return (
    <ScoreBoardStyles className={`${className}`}>
      <div className={`progress -${side}`} style={{ height: score + '%' }} />

      <FlexContainer justifyContent='space-between' alignItems='center' direction='column' className={`score-board ${side}-score ${className}`}>
        <div className='header'>
          <ScoreCompetitor side={side} />
        </div>
        <div className={`${side}-win mb-32 font-24 font-bold`}>{score} %</div>
      </FlexContainer>
    </ScoreBoardStyles>
  );
};
