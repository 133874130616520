import { RootState } from 'app/store';
import { setActiveViewVariant } from 'features/navigation/navigationSlice';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export enum ViewVariants {
  CARDS = 'CARDS',
  LIST = 'LIST',
}

export type ViewVariantsTabs = `${ViewVariants}`;

export type ViewNames = 'battles' | 'singleBattles' | 'competitors' | 'competitorBattles' | 'collections' | 'singleCollection' | 'eliminationToolCompetitors';

type UseViewVariantsTabsProps = {
  viewName: ViewNames;
  CardsComponent?: ReactNode;
  ListComponent?: ReactNode;
};

export const useViewVariantsTabs = (config: UseViewVariantsTabsProps) => {
  const dispatch = useDispatch();
  const navigation = useSelector((state: RootState) => state.navigation);
  const { viewName, CardsComponent = null, ListComponent = null } = config;

  const tabsItems = [
    { label: 'Cards view', key: ViewVariants.CARDS, children: CardsComponent },
    { label: 'List view', key: ViewVariants.LIST, children: ListComponent },
  ];

  return {
    tabsItems,
    viewMode: navigation[viewName],
    setViewMode: (value: ViewVariantsTabs) => dispatch(setActiveViewVariant({ viewName, viewVariant: value })),
  };
};
