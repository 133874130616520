import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { Input } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';

type PasswordFieldProps = FormFieldDefaultProps;

export const PasswordField: FC<PasswordFieldProps> = ({ id, className = '', name, label = '', placeholder = '', disabled = false, onChange, onBlur }) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <Input.Password
            id={id}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            placeholder={placeholder}
            disabled={disabled}
            status={meta.touched && meta.error ? 'error' : undefined}
            iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
          />
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
        </div>
      )}
    </Field>
  );
};
