import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRetry } from './requestInterceptor';
import { RootState } from './store';

export const ACCESS_TOKEN = 'accessToken';

export const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token || localStorage.getItem(ACCESS_TOKEN);
    if (token && headers.get('Authorization') !== '') {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  refetchOnFocus: false,
  reducerPath: 'api',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithRetry,
  tagTypes: [
    'Competitor',
    'CompetitorFull',
    'Competitors',
    'User',
    'Users',
    'Battle',
    'BattleLive',
    'Battles',
    'BattleComments',
    'SingleBattle',
    'SingleBattles',
    'SingleBattleComments',
    'Highlights',
    'Collection',
    'Events',
    'EliminationTool',
    'Elimination',
    'Eliminations',
    'EliminationCompetitors',
    'Event',
    'Categories',
    'Category',
    'BattleNotes',
    'BattleNoteComments',
    'Raport',
  ],
  endpoints: () => ({}),
});
