import { ThemeVariantTypes } from 'interfaces/ThemeVariantTypes';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

type AppContextType = {
  themeMode: ThemeVariantTypes;
  setThemeMode: Dispatch<SetStateAction<ThemeVariantTypes>>;
};

const AppContext = createContext({} as AppContextType);

type AppProviderProps = {
  children: ReactNode;
};

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<ThemeVariantTypes>('neutral');
  const appProviderValue = { themeMode, setThemeMode };

  return <AppContext.Provider value={appProviderValue}>{children}</AppContext.Provider>;
};

export default AppProvider;

export const useAppContext = () => {
  const appCtx = useContext(AppContext);

  if (appCtx === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return appCtx;
};
