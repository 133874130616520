import { ChangeEvent, FC, ReactNode } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import { Field, FieldProps } from 'formik';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { SelectOption } from 'interfaces/SelectOption';

type MultiSelectFieldProps = FormFieldDefaultProps & {
  allowClear?: boolean;
  notFoundContent?: ReactNode;
  options: SelectOption[];
  showSearch?: boolean;
};

export const MultiSelectField: FC<MultiSelectFieldProps> = ({
  id,
  className = '',
  name,
  label = '',
  placeholder = '',
  disabled = false,
  options = [],
  showSearch = false,
  allowClear = false,
  notFoundContent,
  onChange,
  onBlur,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div id={`select-container-${name}`} className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <Select
            id={id}
            className='w-100'
            {...field}
            onChange={(value) => {
              const event = {
                target: {
                  name,
                  value,
                },
              } as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
              field.onChange(event);
              onChange?.(event);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            mode='multiple'
            allowClear={allowClear}
            showSearch={showSearch}
            {...(showSearch && { filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) })}
            placeholder={placeholder}
            options={options}
            disabled={disabled}
            notFoundContent={notFoundContent}
            suffixIcon={meta.touched && meta.error ? <div className='text-error'>!</div> : undefined}
            status={meta.touched && meta.error ? 'error' : undefined}
            getPopupContainer={() => document.getElementById(`select-container-${name}`) as HTMLElement}
          />
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
          {meta.touched && form?.status?.warning?.[name] && (
            <ValidationErrorMessage className='text-warning'>{form.status.warning[name]}</ValidationErrorMessage>
          )}
        </div>
      )}
    </Field>
  );
};
