/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { ACCESS_TOKEN } from 'app/api';
import { useAppDispatch, useAppSelector } from 'app/store';
import { logoutUser } from 'features/auth/authSlice';
import { parseJwtToken } from 'utils';

export const useUser = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const token = localStorage.getItem(ACCESS_TOKEN) ?? '';
  const userLoggedIn = !!user?.role || !!token;
  const isTokenExpired = dayjs().isAfter(dayjs.unix(parseJwtToken(token)?.exp ?? 0));

  const logout = useCallback(() => {
    dispatch(logoutUser());
  }, []);

  return { isTokenExpired, user, userLoggedIn, logout };
};
