import { Urls } from 'config/Urls';
import { EventCategoryTypes } from 'interfaces/EventCategory';
import { useLocation, useParams } from 'react-router-dom';

export const useTreeView = () => {
  const { pathname } = useLocation();
  const { categoryId, eventId } = useParams();
  let isTreeView = false;

  if (pathname.includes(`${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${EventCategoryTypes.KNOCKOUT}/${categoryId}`)) {
    if (!pathname.includes(Urls.EVENT_CATEGORY_CONFIGURATION)) {
      isTreeView = true;
    }
  }

  return {
    isTreeView,
  };
};
