import { Breadcrumb, Tag } from 'antd';
import { useGetEventQuery } from 'app/services/events';
import { FlexContainer } from 'components/FlexContainer';
import { STATUSES, STATUSES_LIST } from 'config/statuses';
import { Urls } from 'config/Urls';
import { FC, ReactNode } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EventBreadcrumbsStyles } from './EventBreadcrumbs.styles';
import { useGetEventEliminationQuery } from 'app/services/elimination';

type EventBreadcrumbsProps = {
  statusSlot?: ReactNode;
  viewMode?: 'row' | 'column';
  showStatusTag?: boolean;
};

export const EventBreadcrumbsElimination: FC<EventBreadcrumbsProps> = ({ statusSlot = null, viewMode = 'column', showStatusTag = true }) => {
  const { pathname } = useLocation();
  const { categoryId, eventId } = useParams();
  const { data: eventData, isLoading: isEventLoading } = useGetEventQuery(eventId, { skip: !eventId, refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const { data: eventEliminationData, isLoading: isEventEliminationLoading } = useGetEventEliminationQuery(
    { categoryId },
    {
      skip: !categoryId,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const pathSnippets = pathname.split('/').filter((i) => i);

  const breadcrumbNameMap: Record<string, string> = {
    [`${Urls.LIVE_EVENTS}/${eventId}`]: eventData?.attributes?.name ?? '',
    [`${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.ELIMINATIONS}/${categoryId}`]: eventEliminationData?.attributes?.name ?? '',
  };

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    if (Object.keys(breadcrumbNameMap).includes(url)) {
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      );
    } else {
      return null;
    }
  });

  const getStatus = () => {
    switch (pathname) {
      case `${Urls.LIVE_EVENTS}/${eventId}`:
        return eventData?.attributes?.status;
      case `${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.ELIMINATIONS}/${categoryId}`:
      case `${Urls.LIVE_EVENTS}/${eventId}/${Urls.EVENT_CATEGORY}/${Urls.ELIMINATIONS}/${categoryId}/configuration`:
        return eventEliminationData?.attributes?.status;
      default:
        return STATUSES_LIST.AWAITING_START;
    }
  };

  return (
    <>
      {(!isEventLoading || !isEventEliminationLoading) && (
        <EventBreadcrumbsStyles viewMode={viewMode} className='mb-16'>
          {showStatusTag && (
            <FlexContainer className='mb-24' alignItems='center'>
              <Tag className='text-uppercase'>{STATUSES[getStatus() ?? STATUSES_LIST.AWAITING_START]}</Tag>
              {statusSlot}
            </FlexContainer>
          )}
          <Breadcrumb className='event-breadcrumb' separator='>'>
            {breadcrumbItems}
          </Breadcrumb>
        </EventBreadcrumbsStyles>
      )}
    </>
  );
};
