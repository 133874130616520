import { toast } from 'react-toastify';
import { useDeleteEventCategoryMutation, useEditEventCategoryMutation } from 'app/services/categories';
import { useConfirmModal } from 'hooks';
import { STATUSES_LIST, STATUSES_TYPES } from 'config/statuses';
import { EventCategoryNames } from 'interfaces/EventCategory';
import { useDeleteEventEliminationMutation, useEditEventEliminationMutation } from 'app/services/elimination';

export const useEventCategoryActions = () => {
  const { openConfirmModal } = useConfirmModal();
  const [deleteEventCategoryApi] = useDeleteEventCategoryMutation();
  const [deleteEventEliminationApi] = useDeleteEventEliminationMutation();
  const [editEventCategoryApi] = useEditEventCategoryMutation();
  const [editEventEliminationApi] = useEditEventEliminationMutation();

  const deleteEventCategory = (categoryId: number, categoryName = '', categoryType: EventCategoryNames | undefined, categoryStatus: STATUSES_TYPES) => {
    openConfirmModal({
      title: 'Delete category',
      content: (
        <div>
          You are going to permanently delete the category <strong>{categoryName}</strong> including all its data (tree, verdicts etc.). This action cannot be
          undone. Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, delete it',
      cancelText: 'No, take me back',
      async onOk() {
        if (categoryStatus === STATUSES_LIST.ONGOING) {
          toast.error('You cannot delete an ongoing category');
          return;
        }
        try {
          if (categoryType === EventCategoryNames.KNOCKOUT) {
            await deleteEventCategoryApi(categoryId);
          } else {
            await deleteEventEliminationApi(categoryId);
          }
          toast.success('Category deleted');
        } catch (error) {
          console.error(error);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const closeCategory = (categoryId: number, categoryName = '', categoryType?: EventCategoryNames | undefined) => {
    openConfirmModal({
      title: 'Close category',
      content: (
        <div>
          You are going to close the category <strong>{categoryName}</strong>.<br />
          Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, close it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          if (categoryType === EventCategoryNames.KNOCKOUT) {
            await editEventCategoryApi({ id: categoryId, body: { status: STATUSES_LIST.CLOSED } });
          } else {
            editEventEliminationApi({ id: categoryId, body: { status: STATUSES_LIST.CLOSED } });
          }
          toast.success('Category closed');
        } catch (error) {
          console.error(error);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const reopenCategory = (categoryId: number, categoryName = '', categoryType?: EventCategoryNames | undefined) => {
    openConfirmModal({
      title: 'Reopen category',
      content: (
        <div>
          You are going to reopen the category <strong>{categoryName}</strong>.<br />
          Are you sure to proceed?
        </div>
      ),
      okText: 'Yes, reopen it',
      cancelText: 'No, take me back',
      async onOk() {
        try {
          if (categoryType === EventCategoryNames.KNOCKOUT) {
            await editEventCategoryApi({ id: categoryId, body: { status: STATUSES_LIST.ONGOING } });
          } else {
            await editEventEliminationApi({ id: categoryId, body: { status: STATUSES_LIST.ONGOING } });
          }
          toast.success('Category reopened');
        } catch (error) {
          console.error(error);
        }
      },
      onCancel() {
        return;
      },
    });
  };

  return { deleteEventCategory, closeCategory, reopenCategory };
};
