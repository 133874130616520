import { createSlice } from '@reduxjs/toolkit';
import { ACCESS_TOKEN } from 'app/api';
import { userApi } from 'app/services/user';
import type { RootState } from 'app/store';
import { User } from 'interfaces/User';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isVersionLogOpen: false,
} as { user: null | User; token: string | null; isAuthenticated: boolean, isVersionLogOpen: boolean };

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: () => {
      localStorage.removeItem(ACCESS_TOKEN);
      return initialState;
    },
    openVersionLog: (state) => {
      return { ...state, isVersionLogOpen: true };
    },
    closeVersionLog: (state) => {
      return { ...state, isVersionLogOpen: false };
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.loginUser.matchPending, (state, action) => {
        // console.log('pending', action);
      })
      .addMatcher(userApi.endpoints.loginUser.matchFulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.jwt;
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.setItem(ACCESS_TOKEN, action.payload.jwt);
      })
      .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
        state.user = action.payload;
        state.token = localStorage.getItem(ACCESS_TOKEN);
      })
      .addMatcher(userApi.endpoints.loginUser.matchRejected, (state, action) => {
        // console.log('rejected', action);
      });
  },
});

export const { logoutUser, openVersionLog,  closeVersionLog } = slice.actions;
export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsVersionLogOpen = (state: RootState) => state.auth.isVersionLogOpen;
