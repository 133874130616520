import { useParams } from 'react-router-dom';
import { useGetEventCategoryQuery } from 'app/services/categories';
import { EventCategorySettings } from 'interfaces/EventCategorySettings';

interface UseCategorySettings {
  settings: EventCategorySettings;
  categorySettingsExists: boolean;
}

export const useCategorySettings = (): UseCategorySettings => {
  const { categoryId, raportId } = useParams();

  const { data: categoryData } = useGetEventCategoryQuery(categoryId as string, {
    skip: !categoryId || !!raportId,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  return {
    settings:
      categoryData?.attributes?.category_settings?.data?.attributes ||
      categoryData?.attributes?.event?.data.attributes.category_settings?.data?.attributes ||
      ({} as EventCategorySettings),
    categorySettingsExists: !!categoryData?.attributes?.category_settings?.data?.attributes,
  };
};
