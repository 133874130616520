import { Endpoints } from 'config/Urls';
import { BattleLive, BattleLiveResponse, CreateBattleLive, UpdateBattleLive } from 'interfaces/BattleLive';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { TimeAttributes } from 'interfaces/TimeAttributes';
import { transformBattleLiveResponse } from 'utils';
import { api } from '../api';

const BATTLE_LIVE_POPULATE_PARAM = 'populate[category]=1&populate[BlueSide]=1&populate[RedSide]=1&populate[rounds][populate][verdicts][populate]=judge';

export const battleLiveApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBattleLive: build.query<StrapiWrapper<TimeAttributes & BattleLive>, string | number | undefined>({
      query: (id) => `${Endpoints.BATTLES_LIVE}/${id}?${BATTLE_LIVE_POPULATE_PARAM}`,
      providesTags: (result, error, id) => [{ type: 'BattleLive', id }],
      transformResponse(response: { data: StrapiWrapper<BattleLiveResponse>; meta: StrapiMeta }) {
        return transformBattleLiveResponse(response?.data);
      },
    }),

    getBattleLiveList: build.query<StrapiWrapper<BattleLiveResponse>[], string>({
      query: (id) =>
        `${Endpoints.BATTLES_LIVE}?populate[RedSide]=1&populate[BlueSide]=1&filters[category][id]=${id}&populate[rounds][populate][verdicts][populate]=judge&pagination[pageSize]=9999`,
      providesTags: () => [{ type: 'BattleLive', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<BattleLiveResponse>[]; meta: StrapiMeta }) {
        return response?.data;
      },
    }),

    addBattleLive: build.mutation<StrapiWrapper<BattleLive>, { eventId: string; body: Partial<CreateBattleLive> }>({
      query: ({ eventId, body }) => ({
        url: `${Endpoints.BATTLES_LIVE}?eventId=${eventId}&${BATTLE_LIVE_POPULATE_PARAM}`,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'BattleLive', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<BattleLive> }) {
        return response?.data;
      },
    }),

    editBattleLive: build.mutation<StrapiWrapper<BattleLive>, { id: string; eventId: string; body: UpdateBattleLive }>({
      query: ({ id, eventId, body }) => ({
        url: `${Endpoints.BATTLES_LIVE}/${id}?eventId=${eventId}&${BATTLE_LIVE_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'BattleLive', id: 'LIST' },
        { type: 'BattleLive', id },
      ],
    }),
  }),
});

export const { useGetBattleLiveQuery, useGetBattleLiveListQuery, useAddBattleLiveMutation, useEditBattleLiveMutation } = battleLiveApi;
