import { BattleComment } from 'interfaces/BattleComment';
import { TimeAttributes } from 'interfaces/TimeAttributes';
import { VerdictResult } from 'interfaces/Verdict';
import { getWinner } from 'utils';

export const getVerdictsSummarize = (battleComments: (BattleComment & TimeAttributes)[] = []) =>
  battleComments
    .filter(({ publishedAt }) => !!publishedAt)
    .reduce((prev: string[], curr: BattleComment) => [...prev, getWinner(Object.values(curr?.verdict ?? {}))], [])
    .reduce((prev: VerdictResult, curr: string) => ({ ...prev, [curr]: prev[curr] + 1 }), { blue: 0, red: 0, tie: 0 }) ?? {};
