import { FC } from 'react';
import { FlexContainer } from '../FlexContainer';
import { AppLoaderStyles } from './AppLoader.styles';

const AppLoader: FC = () => (
  <AppLoaderStyles>
    <FlexContainer className='app-loader-in loading-app-in-out' alignItems='center' justifyContent='center' direction='column'>
      <img className='cursor-pointer' alt='logo' src={'/images/logo/short_white.svg'} />
      <div>Loading Juttle.app...</div>
    </FlexContainer>
  </AppLoaderStyles>
);

export default AppLoader;
