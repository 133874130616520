// TODO remove if not needed
export const STATUSES = {
  AWAITING_START: 'Awaiting start',
  ONGOING: 'Ongoing',
  CLOSED: 'Closed',
} as const;

export enum STATUSES_LIST {
  AWAITING_START = 'AWAITING_START',
  ONGOING = 'ONGOING',
  CLOSED = 'CLOSED',
}

// TODO remove if not needed
export type STATUSES_TYPES = `${STATUSES_LIST}`;
