import { EventCategorySettings } from './EventCategorySettings';
import { LiveEvent } from './LiveEvent';
import { StrapiWrapper } from './StrapiWrapper';
import { TimeAttributes } from './TimeAttributes';
import { BattleLive } from './BattleLive';
import { STATUSES_TYPES } from 'config/statuses';

export interface EventCategory extends TimeAttributes {
  name: string;
  status: STATUSES_TYPES;
  event: { data: StrapiWrapper<LiveEvent> };
  battle_lives?: { data: StrapiWrapper<BattleLive>[] };
  category_settings?: { data: StrapiWrapper<EventCategorySettings> };
}

export interface CreateEventCategory {
  name: string;
  status?: string;
  event: string;
  battle_lives?: string[];
  category_settings?: string;
}

export interface EditEventCategory {
  name: string;
  status: string;
  battle_lives?: string[];
  category_settings?: string;
}

export enum EventCategoryTypes {
  KNOCKOUT = 'knockout',
  ELIMINATIONS = 'eliminations',
}

export type EventCategoryType = `${EventCategoryTypes}`;

export enum EventCategoryNames {
  KNOCKOUT = 'Knockout',
  ELIMINATIONS = 'Eliminations',
}
