import styled from 'styled-components';

export const VerdictHeaderStyles = styled.div`
  .competitors {
    svg {
      width: 60px;
      height: 60px;

      @media screen and (max-width: 992px) {
        width: 48px;
        height: 48px;
      }
    }

    .competitor {
      padding: 20px;
      border: 2px solid;
      flex: 1;
      max-width: 33.33%;

      &.-red {
        border-color: ${({ theme }) => theme.red300};
      }

      &.-blue {
        border-color: ${({ theme }) => theme.blue100};
      }

      &.-transparent {
        border-color: transparent;
      }
    }
  }
`;
