import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'config/Consts';
import { Endpoints } from 'config/Urls';
import { countries } from 'config/countries';
import { BBoyingTypes } from 'interfaces/BBoyingTypes';
import { BattlesListItem } from 'interfaces/BattlesListItem';
import { Competitor } from 'interfaces/Competitor';
import { CompetitorListItem } from 'interfaces/CompetitorListItem';
import { CompetitorNote, CreateCompetitorNoteComment } from 'interfaces/CompetitorNote';
import { EventElimination } from 'interfaces/EventElimination';
import { SelectOption } from 'interfaces/SelectOption';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { PaginationParams } from 'providers/PaginationProvider';
import { SortByOptions } from 'types/SortTypes';
import { api } from '../api';

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompetitor: build.query<Competitor & { id: number | string }, string | number>({
      query: (id) => `${Endpoints.COMPETITORS}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Competitor', id }],
      transformResponse(response: { data: StrapiWrapper<Competitor> }) {
        const { id, attributes } = response.data;
        return {
          id,
          ...attributes,
        };
      },
    }),

    getCompetitorFullData: build.query<CompetitorListItem, string>({
      query: (id) =>
        `${Endpoints.COMPETITORS}/${id}?populate[competitor_notes][sort]=createdAt:desc&populate[competitor_notes][populate][users_permissions_user][fields]=id,email,username&populate[competitor_notes][populate][competitor_note_comments][populate][[users_permissions_user][fields]=id,email,username`,
      providesTags: (result, error, id) => [{ type: 'CompetitorFull', id }],
      transformResponse(response: { data: StrapiWrapper<Competitor> }) {
        const { id, attributes } = response.data;
        return {
          id,
          ...attributes,
          battles: (attributes?.battles?.data ?? []).map(({ id, attributes }) => ({ id, ...attributes })),
          competitorNotes: (attributes?.competitor_notes?.data ?? []).map(({ id, attributes }) => {
            return {
              id,
              ...attributes,
              author: {
                ...attributes?.users_permissions_user?.data?.attributes,
                id: attributes?.users_permissions_user?.data?.id,
              },
            };
          }),
        };
      },
    }),

    getCompetitorBattleList: build.query<
      { battlesList: BattlesListItem[]; meta: StrapiMeta },
      { id: string; pagination: PaginationParams & { userId?: number } }
    >({
      query: ({ id, pagination: { searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, userId } }) =>
        `${Endpoints.BATTLES}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[RedSide][fields]=id,type,name&populate[BlueSide][fields]=id,type,name&populate[battle_comments][filters][$or][0][public]=true&populate[battle_comments][filters][$or][1][users_permissions_user]=${userId}&filters[$or][0][BlueSide][id][$eq]=${id}&filters[$or][1][RedSide][id][$eq]=${id}&filters[$and][2][Name][$containsi]=${searchQuery}&[sort]=createdAt:desc`,
      transformResponse(response: { data: BattlesListItem[]; meta: StrapiMeta }) {
        return { battlesList: response?.data, meta: response?.meta };
      },
    }),

    getCompetitorsList: build.query<
      { competitorsList: CompetitorListItem[]; meta: StrapiMeta },
      PaginationParams & { sortBy: SortByOptions; sortOrder: 'asc' | 'desc'; crewMode?: boolean }
    >({
      query: ({ searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, sortBy, sortOrder, crewMode }) =>
        crewMode
          ? `${Endpoints.COMPETITORS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[$or][0][Type][$eq]=Crew&filters[$and][1][Name][$containsi]=${searchQuery}&[sort]=${sortBy}:${sortOrder}`
          : `${Endpoints.COMPETITORS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[$or][0][Name][$containsi]=${searchQuery}&filters[$or][1][Country][$containsi]=${searchQuery}&filters[$or][2][Crew][$containsi]=${searchQuery}&filters[$or][3][Type][$containsi]=${searchQuery}&[sort]=${sortBy}:${sortOrder}`,
      providesTags: () => [{ type: 'Competitors', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<Competitor>[]; meta: StrapiMeta }) {
        return {
          competitorsList: response?.data.map(({ attributes, id }) => ({ id, ...attributes, battles: [], ['competitor_notes']: [] })),
          meta: response?.meta,
        };
      },
    }),

    checkCompetitor: build.query<boolean, Competitor>({
      query: ({ Name }) => `${Endpoints.COMPETITORS}?filters[Name][$eq]=${Name}&pagination[withCount]=false&pagination[limit]=1`,
      transformResponse(response: { data: StrapiWrapper<Competitor>[]; meta: StrapiMeta }) {
        return !!response.data.length;
      },
    }),

    checkCompetitorByName: build.query<boolean, string>({
      query: (name) => `${Endpoints.COMPETITORS}?filters[$and][0][name][$eq]=${name}&pagination[withCount]=false&pagination[limit]=1`,
      transformResponse(response: { data: StrapiWrapper<Competitor>[]; meta: StrapiMeta }) {
        return !!response.data.length;
      },
    }),

    getCompetitorsListForSelect: build.query<(SelectOption & { type?: BBoyingTypes })[], string>({
      query: () =>
        `${Endpoints.COMPETITORS}?pagination[page]=1&pagination[pageSize]=9999&fields[0]=id&fields[1]=name&fields[2]=country&fields[3]=type&sort=Name`,
      providesTags: () => [{ type: 'Competitors', id: 'SELECT_LIST' }],
      transformResponse(response: { data: StrapiWrapper<Competitor>[] }) {
        return response?.data?.map((c) => ({
          value: `${c.id}`,
          label: `${c.attributes.Name}, ${c.attributes.Type}${c.attributes.Country ? ` (${countries[c.attributes.Country]})` : ''}`,
          type: c.attributes.Type,
          disabled: false,
        }));
      },
    }),

    getCompetitorsListForElimination: build.query<
      {
        competitorsList: CompetitorListItem[];
        meta: StrapiMeta;
      },
      PaginationParams & { categoryId?: string }
    >({
      query: ({ searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, categoryId }) =>
        `${Endpoints.ELIMINATIONS}/${categoryId}?populate[competitors]=elimination`,
      providesTags: () => [{ type: 'EliminationCompetitors', id: 'LIST' }],
      transformResponse: (response: { data: StrapiWrapper<EventElimination>; meta: StrapiMeta }) => {
        return {
          competitorsList:
            response.data.attributes.competitors?.data.map(({ attributes, id }) => ({
              id,
              ...attributes,
              battles: [],
              eliminations: [],
              ['competitor_notes']: [],
            })) ?? [],
          meta: response?.meta,
        };
      },
    }),

    addCompetitor: build.mutation<{ data: StrapiWrapper<Competitor> }, Competitor>({
      query: (body) => ({
        url: Endpoints.COMPETITORS,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [
        { type: 'Competitors', id: 'LIST' },
        { type: 'Competitors', id: 'SELECT_LIST' },
      ],
    }),

    addCompetitorNote: build.mutation<void, { id: string | number; body: CompetitorNote }>({
      query: ({ body }) => ({
        url: Endpoints.COMPETITOR_NOTES,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CompetitorFull', id }],
    }),

    editCompetitorNote: build.mutation<void, { id: string | number; competitorId: string | number; body: CompetitorNote }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.COMPETITOR_NOTES}/${id}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { competitorId }) => [{ type: 'CompetitorFull', id: competitorId }],
    }),

    deleteCompetitorNote: build.mutation<void, { id: string | number; competitorId: string | number }>({
      query: ({ id }) => ({
        url: `${Endpoints.COMPETITOR_NOTES}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { competitorId }) => [{ type: 'CompetitorFull', id: competitorId }],
    }),

    editCompetitor: build.mutation<void, { id: number | string; body: Competitor }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.COMPETITORS}/${id}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'Competitors', id: 'LIST' }],
    }),

    deleteCompetitor: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.COMPETITORS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Competitors', id: 'LIST' }],
    }),

    addCompetitorNoteComment: build.mutation<void, { id: string | number; body: CreateCompetitorNoteComment }>({
      query: ({ body }) => ({
        url: Endpoints.COMPETITOR_NOTE_COMMENTS,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CompetitorFull', id }],
    }),

    deleteCompetitorNoteComment: build.mutation<void, { id: string | number; competitorId: string | number }>({
      query: ({ id }) => ({
        url: `${Endpoints.COMPETITOR_NOTE_COMMENTS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { competitorId }) => [{ type: 'CompetitorFull', id: competitorId }],
    }),
  }),
});

export const {
  useGetCompetitorQuery,
  useGetCompetitorFullDataQuery,
  useGetCompetitorBattleListQuery,
  useGetCompetitorsListQuery,
  useGetCompetitorsListForSelectQuery,
  useGetCompetitorsListForEliminationQuery,
  useAddCompetitorMutation,
  useDeleteCompetitorMutation,
  useEditCompetitorMutation,
  useAddCompetitorNoteMutation,
  useAddCompetitorNoteCommentMutation,
  useEditCompetitorNoteMutation,
  useDeleteCompetitorNoteMutation,
  useDeleteCompetitorNoteCommentMutation,
  useLazyCheckCompetitorQuery,
  useLazyCheckCompetitorByNameQuery,
} = usersApi;
