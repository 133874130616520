import styled, { css } from 'styled-components';
import type { UploadProps } from 'antd/es/upload/interface';

interface ImageFieldStylesProps {
  type?: UploadProps['listType'];
}

export const ImageFieldStyles = styled.div<ImageFieldStylesProps>`
  .change-img-button {
    display: flex;
    align-items: center;
  }

  ${({ type }) =>
    type &&
    type === 'picture' &&
    css`
      .upload-container {
        display: flex;
        width: 100%;
        height: 150px;
        border: 2px dotted ${({ theme }) => theme.gray300};

        .ant-upload-select-picture {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .ant-upload {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    `}
`;
