import { useState } from 'react';

export interface UseModal {
  isOpen: boolean;
  open: (state?: { [key: string]: any }) => void;
  close: () => void;
  modalState: { [key: string]: any };
  setModalState: (state: { [key: string]: any }) => void;
}

export const useModal = (isModalOpen = false): UseModal => {
  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [modalState, setModalSt] = useState({});

  const open = (state?: { [key: string]: any }) => {
    state && setModalSt((p) => ({ ...p, ...state }));
    setIsOpen(true);
  };

  const close = () => {
    setModalSt({});
    setIsOpen(false);
  };

  const setModalState = (state: { [key: string]: any }) => {
    setModalSt((p) => ({ ...p, ...state }));
  };

  return { isOpen, open, close, modalState, setModalState };
};
