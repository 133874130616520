import styled from 'styled-components';
import { FlexContainer } from 'components';

export const ErrorPageStyles = styled(FlexContainer)`
  line-height: normal;

  h1 {
    font-size: 200px;
  }
`;
