import { FC } from 'react';
import { BackToHomePageButton } from 'components';
import { ErrorPageStyles } from './ErrorPage.styles';

const errorMessages = {
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Page not found',
  500: 'Internal server error',
} as const;

type ErrorPageProps = { code: keyof typeof errorMessages };

const ErrorPage: FC<ErrorPageProps> = ({ code }) => (
  <ErrorPageStyles className='h-100vp' direction='column' justifyContent='center' alignItems='center'>
    <h1 className='m-0 gradient-text'>{code}</h1>
    <p className='font-24 text-gray'>{errorMessages[code]}</p>

    <BackToHomePageButton />
  </ErrorPageStyles>
);

export default ErrorPage;
