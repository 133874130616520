import { ChangeEvent, FC } from 'react';
import { Field, FieldProps } from 'formik';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { dateFromats } from 'config/dateFormats';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';
import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker } from './DatePicker';

type DatePickerFieldProps = FormFieldDefaultProps & {
  allowClear?: boolean;
  format?: string;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  showTime?: boolean;
};

export const DatePickerField: FC<DatePickerFieldProps> = ({
  id,
  className = '',
  name,
  label = '',
  placeholder = '',
  allowClear,
  showTime,
  picker = 'date',
  format = dateFromats.YYYYMMDD,
  disabled = false,
  onChange,
  onBlur,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <DatePicker
            id={id}
            className='w-100'
            {...field}
            format={format}
            value={field.value ? dayjs(field.value, format) : null}
            onChange={(value) => {
              const formattedDateString = value?.format(format);
              const event = {
                target: { name, value: formattedDateString ?? '' },
              } as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
              field.onChange(event);
              onChange?.(event);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            picker={picker}
            showTime={showTime}
            allowClear={allowClear}
            placeholder={placeholder}
            disabled={disabled}
            suffixIcon={meta.touched && meta.error ? <span className='text-error'>!</span> : <CalendarOutlined />}
            status={meta.touched && meta.error ? 'error' : undefined}
          />
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
        </div>
      )}
    </Field>
  );
};
