import { FC } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { HeaderCollapseButtonStyles } from 'components/HeaderCollapseButton/HeaderCollapseButton.styles';
import { useMenuCollapsedProvider } from 'providers/MenuCollapsed';

const HeaderCollapseButton: FC = () => {
  const { toggleHeaderCollapsed, headerCollapsed } = useMenuCollapsedProvider();

  return (
    <HeaderCollapseButtonStyles
      className={classNames({
        collapsed: headerCollapsed,
      })}
      onClick={toggleHeaderCollapsed}
    >
      <div className='header-collapse-icon'>
        <DoubleRightOutlined />
      </div>
    </HeaderCollapseButtonStyles>
  );
};

export default HeaderCollapseButton;
