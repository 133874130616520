import { api } from 'app/api';
import { Endpoints } from 'config/Urls';
import { BattleLiveResponse } from 'interfaces/BattleLive';
import { EventCategory } from 'interfaces/EventCategory';
import { EventCategorySettings } from 'interfaces/EventCategorySettings';
import { LiveEvent } from 'interfaces/LiveEvent';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';

export interface Raport {
  battleLiveList: StrapiWrapper<BattleLiveResponse>[] | undefined;
  categorySettings: EventCategorySettings;
  categoryData: StrapiWrapper<EventCategory> | undefined;
  eventData: StrapiWrapper<LiveEvent> | undefined;
}

export const raportsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRaport: build.query<StrapiWrapper<Raport>, string | undefined>({
      query: (raportId) => `${Endpoints.RAPORTS}/${raportId}`,
      providesTags: (result, error, raportId) => [{ type: 'Raport', id: raportId }],
      transformResponse(response: { data: StrapiWrapper<Raport> }) {
        return response.data;
      },
    }),
    addRaport: build.mutation<StrapiWrapper<Raport>, { body: Raport }>({
      query: ({ body }) => ({
        url: `${Endpoints.RAPORTS}`,
        method: 'POST',
        body: { data: body },
      }),
      transformResponse(response: { data: StrapiWrapper<Raport> }) {
        return response.data;
      },
    }),
  }),
});

export const { useGetRaportQuery, useAddRaportMutation } = raportsApi;
