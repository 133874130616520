import { Endpoints } from 'config/Urls';
import { BattleComment, BattleCommentCreate, BattleCommentUpdate } from 'interfaces/BattleComment';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { TimeAttributes } from 'interfaces/TimeAttributes';
import { api } from '../api';

export const battleCommentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBattleCommentsList: build.query<(BattleComment & TimeAttributes)[], { isAdmin?: boolean; battleId: string; userId: number }>({
      query: ({ battleId, userId, isAdmin = false }) =>
        isAdmin
          ? `${Endpoints.BATTLE_COMMENTS}?publicationState=preview&populate=users_permissions_user&filters[battle][id][$eq]=${battleId}&sort=createdAt:desc`
          : `${Endpoints.BATTLE_COMMENTS}?&populate=users_permissions_user&filters[battle][id][$eq]=${battleId}&filters[$or][0][public]=true&filters[$or][1][public]=false&filters[$or][1][users_permissions_user]=${userId}&sort=createdAt:desc`,
      providesTags: () => [{ type: 'BattleComments', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<BattleComment>[] }) {
        return response?.data.map((e) => ({ ...e.attributes, id: e.id }));
      },
    }),

    getAllBattleComments: build.query<StrapiWrapper<BattleComment & TimeAttributes>[], string>({
      query: () => `${Endpoints.BATTLE_COMMENTS}?publicationState=preview&populate=battle`,
      providesTags: () => [{ type: 'BattleComments', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<BattleComment>[] }) {
        return response?.data;
      },
    }),

    updateBattleComment: build.mutation<void, { id: number; body: BattleCommentUpdate & TimeAttributes }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.BATTLE_COMMENTS}/${id}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: [
        { type: 'Battles', id: 'LIST' },
        { type: 'BattleComments', id: 'LIST' },
      ],
    }),

    deleteBattleComment: build.mutation<void, string>({
      query: (id) => ({
        url: `${Endpoints.BATTLE_COMMENTS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'BattleComments', id: 'LIST' }],
    }),

    addBattleComment: build.mutation<void, { battleId: string; body: BattleCommentCreate }>({
      query: ({ body }) => ({
        url: Endpoints.BATTLE_COMMENTS,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [
        { type: 'Battles', id: 'LIST' },
        { type: 'BattleComments', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetAllBattleCommentsQuery,
  useGetBattleCommentsListQuery,
  useAddBattleCommentMutation,
  useUpdateBattleCommentMutation,
  useDeleteBattleCommentMutation,
} = battleCommentApi;
