import { ChangeEvent, FocusEvent, ReactNode } from 'react';

export const CustomFormValidationErrorType = {
  HIGHLIGHT_COUNT_FAILED_TYPE: 'HighlightsLength',
};

export const FormValidationErrors = {
  REQUIRED: 'Field is required',
  INVALID_EMAIL: 'Invalid email format',
  HIGHLIGHTS_COUNT_FAILED: 'Amount of highlights does not match to the rounds',
  INVALID_YOUTUBE_URL: 'Invalid youtube video url',
  VIDEO_EXISTS: 'Video already exists',
  COMPETITOR_EXISTS: 'Competitor already exists',
  PASSWORDS_MATCH: 'Passwords do not match',
  NUMBER_INTEGER: 'Value must be an integer',
  MIN_LENGTH: 'Value should be at least ${min} characters long',
  MAX_VAL: 'Maximum value should be lower than or equal to ${max}',
  MIN_VAL: 'Minimum value should be greater than or equal to ${min}',
  MAX_LENGTH: 'Value must be at most ${max} characters',
  NUMBER_TYPE: 'Field should contain only numbers',
  SELECT_JUDGE: 'Please select at least ${min} judge',
  USER_EXISTS: 'User with this username already exists',
  WHITESPACES: 'Value should not contain leading or trailing whitespaces',
  SPECIAL_CHAR_REQUIRED: 'Password must contain at least one special character.',
  LOWERCASE_CHAR_REQUIRED: 'Password must contain at least one lowercase letter.',
  UPPERCASE_CHAR_REQUIRED: 'Password must contain at least one uppercase letter.',
};

export type FormFieldDefaultProps = {
  id?: string;
  name: string;
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | FocusEvent<HTMLElement, Element>) => void;
};
