import { BattleLiveVerdict } from 'interfaces/BattleLiveVerdict';
import { Result } from 'interfaces/Result';
import { VerdictVariant } from 'interfaces/Verdict';
import { getBattleWinner, getVerdictResults } from 'utils';

export const getWinner = (verdictData: (BattleLiveVerdict | Result | null)[] = []): VerdictVariant => {
  const result = getVerdictResults(verdictData);

  return getBattleWinner(result);
};
