import { BattleLiveRounds } from 'interfaces/BattleLiveRound';
import { BattleLiveVerdict } from 'interfaces/BattleLiveVerdict';
import { getVerdictResults } from './getVerdictResults';
import { getWinner } from './getWinner';

interface BattleLiveRoundResult {
  winner: string;
  blue: number;
  red: number;
  reveal: boolean;
  isRevealActive: boolean;
}

export const getRoundsResult = (rounds: BattleLiveRounds, numberOfJudges: number) => {
  const roundsResult: Record<string, BattleLiveRoundResult> = Object.keys(rounds).reduce((prev, curr) => {
    const roundVerdicts = (rounds?.[curr]?.verdicts ?? {}) as { [key: string]: BattleLiveVerdict };
    const roundVerdictsValues = Object.values(roundVerdicts);
    const sideStatistics = getVerdictResults(roundVerdictsValues);
    const roundWinner = getWinner(roundVerdictsValues);
    const isRevealActive = numberOfJudges === roundVerdictsValues.length;

    return {
      ...prev,
      [curr]: {
        winner: isRevealActive ? roundWinner : null,
        ...sideStatistics,
        isRevealActive,
        reveal: rounds?.[curr]?.reveal,
      },
    };
  }, {});

  return roundsResult;
};
