import { STATUSES_LIST } from 'config/statuses';
import { useBattleLive, useUser } from 'hooks';

export const useBattleLiveRound = () => {
  const { user } = useUser();
  const { rounds } = useBattleLive();

  const ongoingOrClosedRounds = Object.entries(rounds).filter(([, roundData]) => [STATUSES_LIST.ONGOING, STATUSES_LIST.CLOSED].includes(roundData.status));
  const round = ongoingOrClosedRounds?.[ongoingOrClosedRounds.length - 1]?.[0] ?? '1';
  const roundStatus = rounds?.[round]?.status as STATUSES_LIST;
  const roundId = rounds?.[round]?.id;
  const judgeVerdictExists = !!rounds?.[round]?.verdicts?.[`${user?.id}`]?.winner;

  return { judgeVerdictExists, round, roundStatus, roundId };
};
