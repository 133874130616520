import { UserRoles } from 'interfaces/UserRoles';
import { useUser } from './useUser';

export const useUserRoles = () => {
  const { user } = useUser();
  const isAdmin = user?.role?.name === UserRoles.ADMIN;
  const isJudge = user?.role?.name === UserRoles.JUDGE;
  const isITSpecialist = user?.role?.name === UserRoles.ITSPECIALIST;
  const isSpectator = user?.role?.name === UserRoles.SPECTATOR;

  return { isAdmin, isJudge, isITSpecialist, isSpectator };
};
