import { Tooltip } from 'antd';
import classNames from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';

type TextWithTooltipProps = {
  className?: string;
  style?: CSSProperties;
  text?: string | ReactNode;
};

export const TextWithTooltip: FC<TextWithTooltipProps> = ({ className = '', style = {}, text = '' }) => {
  return (
    <div className={classNames('text-wrap', className)} style={style}>
      <Tooltip title={text} overlayClassName='white-space'>
        {text}
      </Tooltip>
    </div>
  );
};
