import { ChangeEvent, FC } from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { InputNumber } from 'antd';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';

type NumberFieldProps = FormFieldDefaultProps & {
  min?: number;
  max?: number;
  step?: number | string;
  stringMode?: boolean;
};

export const NumberField: FC<NumberFieldProps> = ({
  id,
  className = '',
  name,
  label = '',
  placeholder = '',
  disabled = false,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  stringMode = false,
  onChange,
  onBlur,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={classNames('position-relative pb-24 mv-8', className)}>
          {label && (
            <Label className='mb-16' htmlFor={id}>
              {label}
            </Label>
          )}

          <InputNumber
            id={id}
            className='w-100'
            {...field}
            onChange={(value) => {
              const event = {
                target: { name, value: value ?? '' },
              } as ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
              field.onChange(event);
              onChange?.(event);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            min={min}
            max={max}
            step={step}
            stringMode={stringMode}
            placeholder={placeholder}
            disabled={disabled}
            status={meta.touched && meta.error ? 'error' : undefined}
          />
          {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}
        </div>
      )}
    </Field>
  );
};
