import { useGetBattleQuery } from 'app/services/battles';
import { BlueFlag, RedFlag } from 'assets/icons';
import classNames from 'classnames';
import { FlexContainer } from 'components/FlexContainer';
import { VerdictHeaderStyles } from 'pages/Battle/Verdict/VerdictHeader.styles';
import { useRoundVerdictProvider } from 'providers/RoundVerdict';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {UNKNOWN} from "../../../config/Consts";

interface VerdictHeaderProps {
  redScore?: number;
  blueScore?: number;
  className?: string;
}

const VerdictHeader: FC<VerdictHeaderProps> = ({ redScore, blueScore, className }) => {
  const { id: battleId } = useParams();
  const { data: battleData } = useGetBattleQuery(battleId as string);
  const { roundVerdict } = useRoundVerdictProvider();

  const redTotalScore = redScore || Object.values(roundVerdict).filter((v) => v?.winner.side === 'red').length;
  const blueTotalScore = blueScore || Object.values(roundVerdict).filter((v) => v?.winner.side === 'blue').length;

  const competitorName = battleData?.RedSide?.data[0] ? battleData?.RedSide?.data[0]?.attributes.Type + ' ' +battleData?.RedSide?.data[0]?.attributes.Name : UNKNOWN;

  return (
    <VerdictHeaderStyles className={classNames(className)}>
      <FlexContainer justifyContent='center' className='competitors' alignItems='center'>
        <FlexContainer justifyContent='center' className={`competitor ${redTotalScore > blueTotalScore ? '-red' : '-transparent'}`}>
          <div className='pr-24'>
            <RedFlag />
          </div>

          <FlexContainer justifyContent='center' direction='column' alignItems='center'>
            <div className={classNames(`text-gray: ${!battleData?.RedSide?.data[0]}`)}>
              {competitorName}
            </div>
            <div className='font-38 font-bold'>{redTotalScore}</div>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer justifyContent='center' alignItems='center' className='p-32 font-24'>
          vs.
        </FlexContainer>

        <FlexContainer justifyContent='center' className={`competitor ${blueTotalScore > redTotalScore ? '-blue' : '-transparent'}`}>
          <FlexContainer justifyContent='center' direction='column' alignItems='center'>
            <div>
              {battleData?.BlueSide?.data[0]?.attributes.Type} {battleData?.BlueSide?.data[0]?.attributes.Name}
            </div>
            <div className='font-38 font-bold'>{blueTotalScore}</div>
          </FlexContainer>

          <div className='pl-24'>
            <BlueFlag />
          </div>
        </FlexContainer>
      </FlexContainer>
    </VerdictHeaderStyles>
  );
};

export default VerdictHeader;
