import { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { spinnersConfig } from 'config/spinners';
import useSpinner from 'hooks/useSpinner';
import { Button } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { SpinnerButtonStyles } from 'components/Spinner/SpinnerButton/SpinnerButton.styles';

interface SpinnerButtonProps extends BaseButtonProps {
  disabled?: boolean;
  loading: boolean;
  time?: string | number;
  label: string;
  sendingText?: string;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  spinnerSide?: 'left' | 'right';
  htmlType?: 'submit' | 'button';
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}

const SpinnerButton: FC<SpinnerButtonProps> = (props) => {
  const {
    loading,
    label,
    type = 'primary',
    time = spinnersConfig.onButtonTimeout,
    className,
    style,
    sendingText,
    disabled = false,
    onClick,
    spinnerSide = 'right',
    htmlType = 'submit',
  } = props;
  const { spinnerVisible } = useSpinner(loading, +time);

  return (
    <Button htmlType={htmlType} style={style} className={classNames('text-uppercase', className)} type={type} disabled={loading || disabled} onClick={onClick}>
      <SpinnerButtonStyles>
        <span style={{ width: '100%' }}>{spinnerVisible ? sendingText || label : label}</span>
        {spinnerVisible && !disabled && spinnersConfig.onButton && <div className={`spinner spinner-${spinnerSide}`} />}
      </SpinnerButtonStyles>
    </Button>
  );
};

export default SpinnerButton;
