/* eslint-disable react-hooks/exhaustive-deps */
import { Layout } from 'antd';
import classNames from 'classnames';
import { FlexContainer } from 'components';
import HeaderCollapseButton from 'components/HeaderCollapseButton/HeaderCollapseButton';
import SiderCollapseButton from 'components/SiderCollapseButton/SiderCollapseButton';
import SiderFooter from 'components/SiderFooter/SiderFooter';
import { useMenuCollapsedProvider } from 'providers/MenuCollapsed';
import { FC, ReactNode, useEffect } from 'react';
import { ViewWrapperStyles } from './ViewWrapper.styles';
import { useParams } from 'react-router-dom';
import { useTreeView } from 'hooks/useTreeView';
import { useUserRoles } from 'hooks';

type ViewWrapperProps = {
  sider?: ReactNode;
  header?: ReactNode;
  content?: ReactNode;
  collapseIcon?: boolean;
  contentClassName?: string;
};

export const ViewWrapper: FC<ViewWrapperProps> = ({ sider, header, content, contentClassName, collapseIcon = false }) => {
  const { battleId } = useParams();
  const { isJudge } = useUserRoles();

  const { headerCollapsed, sidebarCollapsed, setHeaderCollapsed } = useMenuCollapsedProvider();
  const { isTreeView } = useTreeView();

  // const { backgroundStyle } = useBackgroundStyle(); // temporary disabled

  const isJudgeInBattleView = battleId && isJudge;

  useEffect(() => {
    if (!collapseIcon) {
      setHeaderCollapsed(collapseIcon);
    }
  }, []);

  return (
    <ViewWrapperStyles>
      <Layout>
        {sider && (
          <Layout.Sider className='shadow' width={294} collapsedWidth={76} collapsible collapsed={sidebarCollapsed} trigger={null}>
            <FlexContainer
              className={classNames('h-100', {
                'p-16 pt-24': sidebarCollapsed,
                'p-24': !sidebarCollapsed,
              })}
              direction='column'
            >
              {sider}
              <SiderFooter />
            </FlexContainer>
            <SiderCollapseButton />
          </Layout.Sider>
        )}

        <Layout>
          {header && (
            <Layout.Header
              className={classNames('app-header', {
                '-hidden': headerCollapsed,
              })}
            >
              {header}
              {collapseIcon && <HeaderCollapseButton />}
            </Layout.Header>
          )}

          <Layout.Content className={classNames('ph-24 pb-16 scroll ', contentClassName, { 'pt-32': !isJudgeInBattleView, 'tree-view': isTreeView })}>
            {content}
          </Layout.Content>
        </Layout>
      </Layout>
    </ViewWrapperStyles>
  );
};
