import { FC } from 'react';
import { Button, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useField } from 'formik';
import { FlexContainer, Label } from 'components';
import { ValidationErrorMessage } from 'components/FormikComponents';
import { FormFieldDefaultProps } from 'interfaces/Form';
import { ImageFieldStyles } from './ImageFieldStyles';
import { acceptedImageFileExtensions, acceptedImageFileExtensionsToDisplay } from 'config/acceptedImageFileExtensions';

const ImageFieldUploadButton = () => (
  <FlexContainer direction='column' alignItems='center'>
    <PlusOutlined />
    <div className='mt-8'>Upload</div>
    <p className='font-12 mt-8'> Accepted file formats are:</p>
    <p className='font-12'> {acceptedImageFileExtensionsToDisplay.join(', ')}</p>
  </FlexContainer>
);

type ImageFieldProps = FormFieldDefaultProps & {
  type?: UploadProps['listType'];
  changeImgBtnLabel?: string;
  deleteImgBtnLabel?: string;
};

export const ImageField: FC<ImageFieldProps> = ({
  className = '',
  disabled = false,
  id,
  label = '',
  name,
  type = 'picture-card',
  changeImgBtnLabel = 'Change photo',
  deleteImgBtnLabel = 'Delete photo',
}) => {
  const [{ value }, meta, { setValue, setTouched }] = useField(name);

  const handleChange: UploadProps['onChange'] = (uploadFileData: UploadChangeParam<UploadFile>) => {
    setTouched(true);

    const file = uploadFileData.file;

    if (file === null || !acceptedImageFileExtensions.includes(file.type || '')) {
      setValue(null);
      return;
    }

    setValue(file);
  };

  return (
    <ImageFieldStyles className={classNames('position-relative pb-24 mv-8', className)} type={type}>
      {label && (
        <Label className='mb-16' htmlFor={id}>
          {label}
        </Label>
      )}

      <Upload
        id={id}
        className='upload-container'
        name={name}
        listType={type}
        showUploadList={false}
        accept={acceptedImageFileExtensions.join(', ')}
        disabled={disabled}
        beforeUpload={() => false}
        onChange={handleChange}
      >
        {value ? (
          <img className='w-100 h-100' style={{ objectFit: 'contain' }} src={typeof value === 'string' ? value : URL.createObjectURL(value)} alt='' />
        ) : (
          <ImageFieldUploadButton />
        )}
      </Upload>

      {meta.touched && meta.error && <ValidationErrorMessage>{meta.error}</ValidationErrorMessage>}

      {!!value && (
        <FlexContainer justifyContent='space-between' alignItems='center'>
          <Label className='change-img-button h-40px ph-20 cursor-pointer font-16 font-bold text-center text-yellow text-uppercase' htmlFor={id}>
            {changeImgBtnLabel}
          </Label>

          <Button
            className='text-uppercase text-yellow'
            type='link'
            onClick={() => {
              setValue(null);
            }}
          >
            {deleteImgBtnLabel}
          </Button>
        </FlexContainer>
      )}
    </ImageFieldStyles>
  );
};
