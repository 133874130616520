import { api } from 'app/api';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'config/Consts';
import { Endpoints } from 'config/Urls';
import { CreateEventCategory, EditEventCategory, EventCategory, EventCategoryNames } from 'interfaces/EventCategory';
import { EditEventCategorySettings, EventCategorySettings } from 'interfaces/EventCategorySettings';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { PaginationParams } from 'providers/PaginationProvider';
import { SortByOptions } from 'types/SortTypes';

const CATEGORY_POPULATE_PARAM = 'populate[event][populate][category_settings][populate]=judges&populate[category_settings][populate]=judges';
const CATEGORY_SETTINGS_POPULATE_PARAM = 'populate[category][populate]=event&populate[event]=1&populate[judges]=1';

export const categoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    addCategorySettings: build.mutation<{ data: StrapiWrapper<EventCategorySettings> }, EditEventCategorySettings>({
      query: (body) => ({
        url: `${Endpoints.CATEGORY_SETTINGS}?${CATEGORY_SETTINGS_POPULATE_PARAM}`,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { category }) => [{ type: 'Category', id: category }],
    }),

    editCategorySettings: build.mutation<void, { id: number; body: Partial<EditEventCategorySettings> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.CATEGORY_SETTINGS}/${id}?${CATEGORY_SETTINGS_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { body: { category } }) => [{ type: 'Category', id: category }],
    }),

    getEventCategoriesList: build.query<
      { categoriesList: StrapiWrapper<EventCategory & { type?: EventCategoryNames }>[]; meta: StrapiMeta },
      PaginationParams & { isAdmin: boolean; eventId?: string; userId?: number; sortBy: SortByOptions; sortOrder: "asc" | "desc" }
    >({
      query: ({ isAdmin, eventId = '', userId, searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE, sortBy, sortOrder }) =>
        isAdmin
          ? `${Endpoints.CATEGORIES}?pagination[page]=${page}&populate[battle_lives]=1&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[event][id]=${eventId}&sort=${sortBy}:${sortOrder}`
          : `${Endpoints.CATEGORIES}?pagination[page]=${page}&populate[battle_lives]=1&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[event][id]=${eventId}&filters[$or][0][category_settings][judges][id]=${userId}&filters[$or][1][$and][0][category_settings][judges][id][$null]=true&filters[$or][1][$and][1][event][category_settings][judges][id]=${userId}&sort=${sortBy}:${sortOrder}`,
      providesTags: () => [{ type: 'Categories', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<EventCategory>[]; meta: StrapiMeta }) {
        const mappedCategoriesList = response?.data.map((category) => ({
          ...category,
          attributes: { ...category.attributes, type: EventCategoryNames.KNOCKOUT },
        }));

        return { categoriesList: mappedCategoriesList, meta: response?.meta };
      },
    }),

    getEventCategory: build.query<StrapiWrapper<EventCategory>, string | undefined>({
      query: (id) => `${Endpoints.CATEGORIES}/${id}?${CATEGORY_POPULATE_PARAM}`,
      providesTags: (result, error, id) => [{ type: 'Category', id }],
      transformResponse(response: { data: StrapiWrapper<EventCategory> }) {
        return response?.data;
      },
    }),

    addCategory: build.mutation<{ data: StrapiWrapper<EventCategory> }, CreateEventCategory>({
      query: (body) => ({
        url: `${Endpoints.CATEGORIES}?${CATEGORY_POPULATE_PARAM}`,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),

    deleteEventCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.CATEGORIES}/${id}?${CATEGORY_POPULATE_PARAM}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),

    editEventCategory: build.mutation<void, { id: number; body: Partial<EditEventCategory> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.CATEGORIES}/${id}?${CATEGORY_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Category', id },
        { type: 'Categories', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useAddCategorySettingsMutation,
  useEditCategorySettingsMutation,
  useGetEventCategoriesListQuery,
  useGetEventCategoryQuery,
  useAddCategoryMutation,
  useEditEventCategoryMutation,
  useDeleteEventCategoryMutation,
} = categoriesApi;
