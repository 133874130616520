import { BattleComment } from 'interfaces/BattleComment';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { TimeAttributes } from 'interfaces/TimeAttributes';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const countBattleVerdicts = (battleComments: StrapiWrapper<BattleComment & TimeAttributes>[] = []) => {
  const verdicts = battleComments.reduce(
    (prev, { attributes }) => ({
      ...prev,
      // ...(attributes.public ? { public: ++prev.public } : { private: ++prev.private }),
      ...(attributes.publishedAt && (attributes.public ? { public: ++prev.public } : { private: ++prev.private })),
      ...(!attributes.publishedAt && { deleted: ++prev.deleted }),
    }),
    { public: 0, private: 0, deleted: 0 },
  );

  return { ...verdicts, all: battleComments.length };
};

export const getDefaultCounterText = (value: number, label: string) => {
  if (value <= 0) {
    return '-';
  }

  const capitalizedLabel = capitalizeFirstLetter(label);

  switch (value) {
    case 1:
      return `1 ${capitalizedLabel}`;
    default:
      return `${value} ${capitalizedLabel}s`;
  }
};

export const getBattleCounterText = (battleComments: StrapiWrapper<BattleComment & TimeAttributes>[] = []) => {
  const verdictStats = countBattleVerdicts(battleComments);
  const verdictsExists = Object.values(verdictStats).some((verdictCount) => !!verdictCount);

  return verdictsExists ? `${getDefaultCounterText(verdictStats.all, 'verdict')} / ${verdictStats.public} Public` : null;
};
