import { createContext, Dispatch, FC, SetStateAction, ReactNode, useContext, useState } from 'react';
import { BattleCommentsType, BattleCommentsTypes } from 'interfaces/BattleComment';
import {competitorSides} from 'config/competitorSides';
import { CompetitorSide } from 'interfaces/CompetitorSide';

interface JudgePanelTypeProps {
  judgePanelType: BattleCommentsType;
  setJudgePanelType: Dispatch<SetStateAction<BattleCommentsType>>;
  leftColor: CompetitorSide;
  setLeftColor: Dispatch<SetStateAction<CompetitorSide>>;
}

const JudgePanelType = createContext<JudgePanelTypeProps>({} as JudgePanelTypeProps);

export const useJudgePanelTypeProvider = (): JudgePanelTypeProps => {
  const context = useContext(JudgePanelType);

  if (context === undefined) {
    throw Error('useJudgePanelTypeProvider must be used within a JudgePanelTypeProvider');
  }

  return context;
};

interface JudgePanelTypeProviderProps {
  children: ReactNode;
}

export const JudgePanelTypeProvider: FC<JudgePanelTypeProviderProps> = ({ children }) => {
  const [judgePanelType, setJudgePanelType] = useState<BattleCommentsType>(BattleCommentsTypes.TRIVIUM as BattleCommentsType);
  const [leftColor, setLeftColor] = useState(competitorSides.RED as CompetitorSide)

  return <JudgePanelType.Provider value={{ judgePanelType, setJudgePanelType, leftColor, setLeftColor }}>{children}</JudgePanelType.Provider>;
};
