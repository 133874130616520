import styled from 'styled-components';

export const ViewWrapperStyles = styled.div`
  .ant-layout-sider {
    min-height: 100vh;
    position: relative;
  }

  .ant-layout-header {
    padding: 0;
  }

  .tree-view {
    padding-top: 5px !important;
  }

  .app-header {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    height: 54px;
    padding: 8px 24px 8px 24px;
    margin-right: 0;

    > * {
      flex: 1;
    }

    &.-hidden {
      margin-top: -54px;
    }
  }
`;
