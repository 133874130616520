import { BattleLiveRoundResult } from 'hooks';

export const getBattleReveal = (roundsResult: Record<string, BattleLiveRoundResult>) => {
  const battleReveal = Object.values(roundsResult).reduce(
    (prev, curr) => ({
      ...prev,
      ...(curr?.winner && curr.reveal && { [curr?.winner]: prev[curr?.winner] + 1 }),
    }),
    { red: 0, blue: 0, tie: 0 },
  );

  return battleReveal;
};
