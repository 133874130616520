import { FC } from 'react';
import { UseModal } from 'hooks';
import { Modal } from 'components';
import NameModalForm, { NameModalFormProps } from './NameModalForm';

type NameModalProps = Omit<NameModalFormProps, 'initialValues'> & {
  modal: UseModal;
  modalTitle?: string;
};

export const NameModal: FC<NameModalProps> = ({ inputPlaceholder, loading, modal, modalTitle = '', onCancel, onSubmit }) => {
  const { modalState } = modal;

  return (
    <Modal modal={modal} title={modalTitle} footer={null}>
      <NameModalForm
        initialValues={{ name: modalState.name ?? '' }}
        inputPlaceholder={inputPlaceholder}
        loading={loading}
        onCancel={() => {
          onCancel?.();
          modal.close();
        }}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
