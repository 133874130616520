import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Urls } from 'config/Urls';

interface RedirectPathProps {
  redirectPath: string;
  setRedirectPath: () => void;
}

const RedirectPath = createContext<RedirectPathProps>({} as RedirectPathProps);

export const useRedirectPathProvider = (): RedirectPathProps => {
  const context = useContext(RedirectPath);

  if (context === undefined) {
    throw Error('useRedirectPathProvider must be used within a RedirectPathProvider');
  }

  return context;
};

interface RedirectPathProviderProps {
  children: ReactNode;
}

export const RedirectPathProvider: FC<RedirectPathProviderProps> = ({ children }) => {
  const location = useLocation();
  const [redirectPath, setRedirect] = useState<string>(Urls.BATTLES);

  const setRedirectPath = () => {
    setRedirect(location.pathname);
  };

  return <RedirectPath.Provider value={{ redirectPath, setRedirectPath }}>{children}</RedirectPath.Provider>;
};
