import styled from 'styled-components';

export const WipeOutStyles = styled.div`
  text-shadow: 4px 4px ${({ theme }) => theme.white100};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0.8;
  pointer-events: none;

  span {
    animation: horizontal-shaking 1s;

    &.red {
      color: ${({ theme }) => theme.red100};
    }

    &.blue {
      color: ${({ theme }) => theme.blue300};
    }
  }

  @keyframes horizontal-shaking {
    0% {
      transform: skewX(-25deg);
    }
    5% {
      transform: skewX(25deg);
    }
    10% {
      transform: skewX(-25deg);
    }
    15% {
      transform: skewX(25deg);
    }
    20% {
      transform: skewX(0deg);
    }
    99% {
      opacity: 0;
    }
    100% {
      transform: scale(3);
    }
  }
`;
