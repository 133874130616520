import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

export const useConfirmModal = () => {
  const openConfirmModal = (config?: ModalFuncProps) => {
    Modal.confirm({
      icon: null,
      title: 'Confirmation',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      content: 'Are you sure?',
      ...config,
    });
  };

  return { openConfirmModal };
};
