import AppVersion from 'components/AppVersion/AppVersion';
import { FlexContainer } from 'components/FlexContainer';
import { FC } from 'react';
import { useUser } from 'hooks/useUser';
import { PoweroffOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const UserProfile: FC = () => {
  const { user, logout } = useUser();

  return (
    <FlexContainer direction='column' alignItems='space-between' className='h-100'>
      <div className='mt-80 h-100'>
        <div className='mb-8 font-20 font-bold'>{user?.username}</div>
        <div className='mb-8 font-14'>{user?.email}</div>
        <div className='mb-40 font-14'>{user?.role.name}</div>
      </div>

      <FlexContainer justifyContent='space-between' alignItems='center'>
        <Button className='p-0' type='link' icon={<PoweroffOutlined />} onClick={logout}>
          Log out
        </Button>

        <AppVersion className='font-13' />
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserProfile;
