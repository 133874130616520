import { ColumnType } from 'antd/lib/table';
import get from 'lodash/get';
import { useRedirectPathProvider } from 'providers/RedirectPathProvider';
import { useNavigate } from 'react-router-dom';

type UseCellNavigationProps = {
  url: string;
  urlSuffix?: string;
  idKey?: string;
};

export const useCellNavigation = <T extends { id: number | string }>(config: UseCellNavigationProps): ColumnType<T> => {
  const navigate = useNavigate();
  const { url, urlSuffix, idKey = 'id' } = config;
  const { setRedirectPath } = useRedirectPathProvider();

  return {
    showSorterTooltip: false,
    className: 'cursor-pointer',
    onCell: (record: T) => ({
      onClick: () => {
        setRedirectPath();
        navigate(`${url}/${get(record, idKey)}${urlSuffix ?? ''}`);
      },
    }),
  };
};
