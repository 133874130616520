import styled from 'styled-components';

export const SiderCollapseButtonStyles = styled.div`
  position: absolute;
  right: -16px;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  flex: 0;
  transition: all 0.5s;

  &.collapsed {
    right: -22px;

    .sider-collapse-icon {
      transform: rotate(180deg);
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.gray300};
    border-radius: 10000px;
    width: 32px;
    height: 32px;

    svg {
      transform: rotate(180deg);
    }
  }

  .sider-collapse-icon {
    transition: all 0.5s;
    cursor: pointer;
  }
`;
