import { api } from 'app/api';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'config/Consts';
import { Endpoints } from 'config/Urls';
import { CreateLiveEvent, EditLiveEvent, LiveEvent } from 'interfaces/LiveEvent';
import { StrapiMeta } from 'interfaces/StrapiMeta';
import { StrapiWrapper } from 'interfaces/StrapiWrapper';
import { PaginationParams } from 'providers/PaginationProvider';
import { SortByOptions, SortingOrder } from 'types/SortTypes';

const EVENT_POPULATE_PARAM =
  'populate[category_settings][populate][0]=judges' +
  '&populate[battle_lives][populate][BlueSide][fields]=Name' +
  '&populate[battle_lives][populate][RedSide][fields]=Name' +
  '&populate=logo';

const EVENT_FULL_POPULATE_PARAM =
  'populate[category_settings][populate][0]=judges' +
  '&populate[categories][populate][category_settings][populate][0]=judges' +
  '&populate[battle_lives][populate][BlueSide][fields]=Name' +
  '&populate[battle_lives][populate][RedSide][fields]=Name' +
  '&populate[battle_lives][populate][rounds][populate][verdicts][fields]=verdict' +
  '&populate[battle_lives][populate][rounds][populate][verdicts][populate][judge][fields]=*';

export const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEventsList: build.query<
      { eventsList: StrapiWrapper<LiveEvent>[]; meta: StrapiMeta },
      PaginationParams & { isAdmin?: boolean; isITSpecialist?: boolean; userId: number; sortBy: SortByOptions; sortOrder: SortingOrder }
    >({
      query: ({
        isAdmin = false,
        isITSpecialist = false,
        userId,
        searchQuery,
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        sortBy = 'name',
        sortOrder = 'ascending',
      }) =>
        isAdmin
          ? `${Endpoints.EVENTS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&sort=${sortBy}:${sortOrder}`
          : isITSpecialist
          ? `${Endpoints.EVENTS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[users_permissions_user][id]=${userId}&sort=${sortBy}:${sortOrder}`
          : `${Endpoints.EVENTS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[$or][0][category_settings][judges][id]=${userId}&filters[$or][1][categories][category_settings][judges][id]=${userId}&sort=${sortBy}:${sortOrder}`,
      providesTags: () => [{ type: 'Events', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<LiveEvent>[]; meta: StrapiMeta }) {
        return { eventsList: response?.data, meta: response?.meta };
      },
    }),

    getAllEventsList: build.query<{ eventsList: StrapiWrapper<LiveEvent>[]; meta: StrapiMeta }, PaginationParams>({
      query: ({ searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE }) =>
        `${Endpoints.EVENTS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&sort=name:asc`,
      providesTags: () => [{ type: 'Events', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<LiveEvent>[]; meta: StrapiMeta }) {
        return { eventsList: response?.data, meta: response?.meta };
      },
    }),

    getJudgeEventsList: build.query<{ eventsList: StrapiWrapper<LiveEvent>[]; meta: StrapiMeta }, PaginationParams & { userId: number }>({
      query: ({ userId, searchQuery, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE }) =>
        `${Endpoints.EVENTS}?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${searchQuery}&filters[$or][0][category_settings][judges][id]=${userId}&filters[$or][1][categories][category_settings][judges][id]=${userId}&sort=name:asc`,
      providesTags: () => [{ type: 'Events', id: 'LIST' }],
      transformResponse(response: { data: StrapiWrapper<LiveEvent>[]; meta: StrapiMeta }) {
        return { eventsList: response?.data, meta: response?.meta };
      },
    }),

    getEvent: build.query<StrapiWrapper<LiveEvent>, string | undefined>({
      query: (id) => `${Endpoints.EVENTS}/${id}?${EVENT_POPULATE_PARAM}`,
      providesTags: (result, error, id) => [{ type: 'Event', id }],
      transformResponse: (response: { data: StrapiWrapper<LiveEvent> }) => response?.data,
    }),

    getEventOnly: build.query<StrapiWrapper<LiveEvent>, string | undefined>({
      query: (id) => `${Endpoints.EVENTS}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Event', id }],
      transformResponse: (response: { data: StrapiWrapper<LiveEvent> }) => response?.data,
    }),

    getFullEvent: build.query<StrapiWrapper<LiveEvent>, string | undefined>({
      query: (id) => `${Endpoints.EVENTS}/${id}?${EVENT_FULL_POPULATE_PARAM}`,
      providesTags: (result, error, id) => [{ type: 'Event', id }],
      transformResponse: (response: { data: StrapiWrapper<LiveEvent> }) => response?.data,
    }),

    addEvent: build.mutation<{ data: StrapiWrapper<LiveEvent> }, Omit<CreateLiveEvent, 'logo'>>({
      query: (body) => ({
        url: Endpoints.EVENTS,
        method: 'POST',
        body: { data: body },
      }),
      invalidatesTags: [{ type: 'Events', id: 'LIST' }],
    }),

    deleteEvent: build.mutation<void, number>({
      query: (id) => ({
        url: `${Endpoints.EVENTS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Events', id: 'LIST' }],
    }),

    editEvent: build.mutation<void, { id: number; body: Partial<EditLiveEvent> }>({
      query: ({ id, body }) => ({
        url: `${Endpoints.EVENTS}/${id}?${EVENT_POPULATE_PARAM}`,
        method: 'PUT',
        body: { data: body },
      }),
      invalidatesTags: (TagTypes, ResultType, QueryArg) => [
        { type: 'Events', id: 'LIST' },
        { type: 'Event', id: `${QueryArg.id}` },
      ],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetEventOnlyQuery,
  useGetFullEventQuery,
  useGetEventsListQuery,
  useGetAllEventsListQuery,
  useGetJudgeEventsListQuery,
  useAddEventMutation,
  useDeleteEventMutation,
  useEditEventMutation,
} = eventsApi;
