import styled from 'styled-components';

export const VerdictSubmitStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 90vw;
  min-width: 60vw;

  .round-verdicts {
    flex-wrap: wrap;

    .-red {
      border: 2px solid ${({theme}) => theme.red100};
      background-color: ${({theme}) => theme.red400};
    }

    .-blue {
      border: 2px solid ${({theme}) => theme.blue100};
      background-color: ${({theme}) => theme.blue400};
    }
  }

  .verdict-type-info {
    line-height: 24px;
  }

  .verdict-content {
    height: 50vh;
    overflow: auto;
`;
